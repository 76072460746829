import {Avatar, Card, Input, Space} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {clearUser, getUser} from "../../../app/features/user/userSlice";
import RemoveCookie from "../../../Common/ApiCall/CookieMethods";
import UpdatePassword from "./UpdatePassword";
import {useState} from "react";
import {useTranslation} from "react-i18next";

const {Meta} = Card

const AccountDetails = () => {
  const user = useSelector(getUser)
  const [passModal, setPassModal] = useState(false)
  const userDetails = user.user;
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const logout = () => {
    dispatch(clearUser(3))
    RemoveCookie()
  }

  return(
      <div className={"card mt-5"}>
        <UpdatePassword
            active={passModal}
            onClose={() => setPassModal(false)}
        />
        <div className={"card-content"}>
          <Space>
            <Card
                style={{
                  border: "none"
                }}
            >
              <Meta
                  avatar={
                    <Avatar
                        shape={'circle'}
                        src={userDetails.profile_pic_url}
                        size={80}
                    />
                  }
              />
            </Card>
            <Space
              direction={'vertical'}
            >
              <div className={"title is-size-4 is-capitalized"}>
                {t('dashboard.email')}
              </div>
              <Space>
                <Input
                    value={userDetails.email}
                    disabled
                    size={'large'}
                />
                <button
                  className={"button is-info is-outlined uppercase"}
                  onClick={() => setPassModal(true)}
                >
                  Change Password
                </button>
                <button
                  className={"button is-info is-outlined"}
                  onClick={() => logout()}
                >
                  Log Out
                </button>
              </Space>
            </Space>
          </Space>
        </div>
      </div>
  )
}

export default AccountDetails
