import {message} from "antd";
import {CampaignDetails} from "../CreateCampaignV2";
import CAMPAIGN_TYPES from "../../../../../Common/Constants/CAMPAIGN_TYPES";

import balloonPopImage from '../../../../../Common/Images/games/webp/balloon_pop.webp'
import candyCrushImage from '../../../../../Common/Images/games/webp/candy_crush.webp'
import FindNameImage from "../../../../../Common/Images/games/webp/findname.webp";
import racingImage from '../../../../../Common/Images/games/webp/racing.webp'
import DiceImage from "../../../../../Common/Images/games/webp/dice.webp";
import TicTacToeImage from '../../../../../Common/Images/games/webp/tic_tac_toe.webp'
import wheelImage from '../../../../../Common/Images/games/webp/wheel.webp'
import SlotMachineImage from '../../../../../Common/Images/games/webp/rollie.webp'
import NinjaImage from '../../../../../Common/Images/games/webp/ninja.webp'
import SantaImage from '../../../../../Common/Images/games/santa_game.png'
import TriviaImage from '../../../../../Common/Images/games/webp/quiz.webp'

import CreateCampaignV2Constants from "../CreateCampaignV2Constants";
import {HelpGuideConstants} from "../../../../../Common/Constants/HelpGuideLinks";
import {useDispatch, useSelector} from "react-redux";
import {getUser, showHelpVideo} from "../../../../../app/features/user/userSlice";

export const campaignTypesArr: CampaignTypeItem[] = [
  {
    key: CAMPAIGN_TYPES.SLOT_MACHINE_TYPE,
    title: "Slot Machine",
    hide: false,
    soon: false,
    image: SlotMachineImage,
    setup: HelpGuideConstants.CAMPAIGN_SETUP_VIDEOS.SLOT_MACHINE,
    free: true
  },
  {
    key: CAMPAIGN_TYPES.CANDY_CRUSH_TYPE,
    title: "Candy Crush",
    hide: false,
    soon: false,
    image: candyCrushImage,
    setup: HelpGuideConstants.CAMPAIGN_SETUP_VIDEOS.CANDYCRUSH,
    free: true
  },
  {
    key: CAMPAIGN_TYPES.TICTACTOE_TYPE,
    title: "Tic Tac Toe",
    hide: false,
    soon: false,
    image: TicTacToeImage,
    setup: HelpGuideConstants.CAMPAIGN_SETUP_VIDEOS.TIC_TAC_TOE,
    free: true
  },
  {
    key: CAMPAIGN_TYPES.SPIN_WHEEL_TYPE,
    title: "Spin the Wheel",
    hide: false,
    soon: false,
    description: "Get your customer engaged with the Good old Spin the Wheel, Fully Customizable!",
    image: wheelImage,
    setup: HelpGuideConstants.CAMPAIGN_SETUP_VIDEOS.SPIN_WHEEL,
    free: false
  },
  {
    key: CAMPAIGN_TYPES.FINDNAME_TYPE,
    title: "Find Name",
    hide: false,
    soon: false,
    image: FindNameImage,
    setup: HelpGuideConstants.CAMPAIGN_SETUP_VIDEOS.FINDNAME,
    free: false
  },
  {
    key: CAMPAIGN_TYPES.SEVEN_UPDOWN_TYPE,
    title: "Dice Game",
    hide: false,
    soon: false,
    image: DiceImage,
    setup: HelpGuideConstants.CAMPAIGN_SETUP_VIDEOS.DICE,
    free: false
  },
  {
    key: CAMPAIGN_TYPES.QUIZ_TYPE,
    title: "Trivia",
    hide: false,
    soon: false,
    image: TriviaImage,
    setup: HelpGuideConstants.CAMPAIGN_SETUP_VIDEOS.QUIZ,
    free: false
  },
  {
    key: CAMPAIGN_TYPES.POLL_TYPE,
    title: "Poll",
    hide: true,
    soon: false,
    free: false
  },
  {
    key: CAMPAIGN_TYPES.BALLOON_POP_TYPE,
    title: "Balloon Pop",
    hide: false,
    soon: false,
    image: balloonPopImage,
    setup: HelpGuideConstants.CAMPAIGN_SETUP_VIDEOS.BALLOON_POP,
    free: false
  },
  {
    key: CAMPAIGN_TYPES.SCRATCH_TYPE,
    title: "Scratch Card",
    hide: true,
    soon: false,
    free: false
  },
  {
    key: CAMPAIGN_TYPES.OGRE_TYPE,
    title: "Catch Falling Objects",
    hide: false,
    soon: false,
    image: SantaImage,
    setup: HelpGuideConstants.CAMPAIGN_SETUP_VIDEOS.CATCH_OBJECTS,
    free: false
  },
  {
    key: CAMPAIGN_TYPES.FRUIT_NINJA_TYPE,
    title: "Fruit Ninja",
    hide: false,
    soon: false,
    image: NinjaImage,
    setup: HelpGuideConstants.CAMPAIGN_SETUP_VIDEOS.FRUIT_NINJA,
    free: false
  },
  {
    key: CAMPAIGN_TYPES.PUZZLE_TYPE,
    title: "Puzzle",
    hide: true,
    soon: false,
    free: false
  },
  {
    key: "words",
    title: "Find Words",
    hide: true,
    soon: false,
    free: false
  },
  {
    key: CAMPAIGN_TYPES.RACING_TYPE,
    title: "Car Race",
    hide: true,
    soon: false,
    image: racingImage,
    free: false
  },
  {
    key: CAMPAIGN_TYPES.LAUNCH_TYPE,
    title: "Product Launch",
    hide: true,
    soon: false,
    free: false
  },
]

type CampaignTypeItem = {
  key: string,
  title: string,
  hide: boolean,
  soon?: boolean,
  description?: string,
  image?: string,
  setup?: string,
  free?: boolean
}

type CampaignTypeGameScreenProps = {
  details: CampaignDetails | null,
  selected: Function,
  back: Function
}

const CampaignTypeGameScreen = (
  {
    selected,
    details,
    back
  }: CampaignTypeGameScreenProps
) => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)

  const FREE_PLAN = user?.user?.product?.plan_name?.toLowerCase() === "free"

  const clickedComp = (det: CampaignTypeItem) => {
    if(det.soon) {
      message.warn("Coming Soon...")
      return
    }
    if(FREE_PLAN && !det.free) {
        message.warn("Upgrade Plan to access this feature")
        return
    }
    selected(det.key)
  }

  const startDemo = () => {
  }

  const openSetup = (link: string | undefined) => {
    if(!link) return
    dispatch(showHelpVideo(link))
  }

  return(
    <div className={""}>
      {/* <div>
        <div
          className={"w-fit mb-2 flex justify-center items-center gap-1 cursor-pointer text-black bg-[#EEF0FF] transition duration-150 rounded-md font-bold py-2 px-3 hover:text-darkmain"}
          onClick={() => back()}
        >
          <LeftOutlined className="text-xs"/>
          <span className="uppercase text-sm">Back</span>
        </div>
      </div> */}
      <div
        className={"py-3 flex justify-center items-center text-center"}
      >
          <span
            className={"flex-none text-3xl text-white select-none font-bold w-fit inline"}
          >
            {CreateCampaignV2Constants.first_screen.title}
          </span>
      </div>
      <div
        className={"grid p-4 rounded-md bg-main grid-cols-12 gap-3"}
      >
        {campaignTypesArr.map((c) => {
          if(c?.hide) return null
          return (
            <div
              key={c.title}
              className={"col-span-6 items-stretch md:col-span-3"}
            >
              <div
                className={`bg-[#fff] rounded-md font-bold cursor-pointer min-h-full transition duration-150 
                hover:bg-[#bfc1f3] hover:text-darkmain ${c.key === details?.campaign_type ? "" : ""}
                ${FREE_PLAN?(c.free?"":" opacity-50"):""}
                `}
                title={"Select this campaign - " + c.title}
                id={`campaign-type-${c.key}`}
              >
                <div
                  className={"card-content px-0 py-0 min-h-full space-y-2 text-center"}
                >
                  <div
                    className={"px-2 pt-2"}
                    onClick={() => clickedComp(c)}
                  >
                    <div
                      className={"w-fit mx-auto min-h-[50px] pt-2"}
                    >
                      {
                        c.image && (
                          <img
                            className={'max-h-[50px]'}
                            alt={c.title}
                            src={c.image}
                          />
                        )
                      }
                    </div>
                    <div
                      className={"text-xl text-[#2A2535] uppercase"}
                    >
                      {c.title}
                    </div>
                  </div>
                  <div
                    className={"flex justify-center items-center text-sm text-[#2A2535] pb-3"}
                  >
                    <div
                      className={"cursor-pointer text-main underline hidden"}
                    >
                      Playable demo
                    </div>
                    <div
                      className={`cursor-pointer text-main uppercase underline px-2 py-1 rounded-md hover:text-white hover:bg-main ${c.setup ? "" : "hidden"}`}
                      onClick={() => openSetup(c.setup)}
                    >
                      how to setup
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CampaignTypeGameScreen