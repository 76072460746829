import {Button, Form, Input, message, Select, Typography} from "antd"
import {useEffect, useState} from "react"
import {BadNotif, SuccessNotif} from "../../../../Common/Utils/SendNotification"
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent"
import WigzoIntegration from "../../../../Common/Models/WigzoIntegration"
import ConnectWigzo from "../../../../Common/ApiCall/IbServer/Integrations/ConnectWigzo"

type WigzoContentProps = {
  details: WigzoIntegration | null,
} & CommonContentProps

const WigzoContent = (
    {
      close,
      details,
      updateUserFunction
    }: WigzoContentProps
) => {
  const [load, setLoad] = useState(false)
  const [wigzoContentForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
  }, [details])

  const integrateWigzo = async (values: any) => {
    loading()
    const res = await ConnectWigzo(values.token, values.org_token)
    if(res.success) {
      message.success("Wigzo is connected now. You can start collecting data.")
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
  }

  return(
      <>
        <Form
            hideRequiredMark
            onFinish={integrateWigzo}
            form={wigzoContentForm}
            initialValues={ details ?? {}}
        >
          <Form.Item
              noStyle
          >
            Token
            <Form.Item
                name={"token"}
                rules={[{
                  required: true
                }]}
            >
              <Input />
            </Form.Item>
          </Form.Item>
          <Form.Item
              noStyle
          >
            Org Token
            <Form.Item
                name={"org_token"}
                rules={[{
                  required: true
                }]}
            >
              <Input
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
              shouldUpdate
          >
            {() => {
              return (
                  <Button
                      disabled={load}
                      loading={load}
                      type={'primary'}
                      htmlType={'submit'}
                  >
                    {isConnected ? "Update" : "Activate"}
                  </Button>
              )
            }}
          </Form.Item>
        </Form>
      </>
  )
}

export default WigzoContent