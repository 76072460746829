import React, {useEffect, useState} from "react";
import {FilterOption} from "../PeopleTablesData/filterOptions";
import AddStringFilter from "./AddStringFilter";
import {message} from "antd";

type AddFiltersProps = {
  options: FilterOption[],
  addFilters: Function,
  removeFilter: Function
}

const AddFilters = (
    {
        options,
        addFilters,
        removeFilter
    }: AddFiltersProps
) => {
  const [filterOption, setFilterOption] = useState<FilterOption | null>(null)

  const backToAllFilters = () => setFilterOption(null)

  const setFilter = (
      subFilter: {key: string | number, title: string},
      filter_value: any[],
      type: FilterOption["type"]
  ) => {
    if(filterOption) {
        switch (type) {
            case "number": addFilters({
                title: `${filterOption.title} ${subFilter.title} to ${filter_value}`,
                key: filterOption.key,
                value: filter_value,
                subFilter: subFilter.key,
                isClosable: true
            });console.log("adw");break
            case "string": addFilters({
                title: `${filterOption.title} ${subFilter.title} ${filter_value}`,
                key: filterOption.key,
                value: filter_value,
                subFilter: subFilter.key,
                isClosable: true
            });break
            case "datetime": addFilters({
                title: `${filterOption.title} ${subFilter.title} ${filter_value[0].format("DD/MM/YYYY HH:mm::ss")} to ${filter_value[1].format("DD/MM/YYYY HH:mm::ss")}`,
                key: filterOption.key,
                value: filter_value,
                subFilter: subFilter.key,
                isClosable: true
            });break
          case "select": {
            if(filter_value.length < 1) {
              message.warn("Select a Value to Continue");
              return;
            }
            addFilters({
              title: `${filterOption.title} ${subFilter.title} ${filter_value.join(",")}`,
              key: filterOption.key,
              value: filter_value,
              subFilter: subFilter.key,
              isClosable: true
            });
            break;
          }
          case "boolean": addFilters({
            title: `${filterOption.title} ${subFilter.title} ${filter_value}`,
            key: filterOption.key,
            value: filter_value,
            subFilter: subFilter.key,
            isClosable: true
          })
        }
    }
  }

  useEffect(() => {

    return(
        setFilterOption(null)
    )
  }, [])

  return(
      <div
          className="dropdown-content"
          style={{
              margin: "-1rem"
            }}
      >
        {
          filterOption ? (
              <div className={"m-3"}>
                <AddStringFilter
                  f={filterOption}
                  backToAll={backToAllFilters}
                  setFilter={setFilter}
                />
              </div>
          ) :  options.map((op, i) => (
                <>
                  <div
                      className="dropdown-item cursor-pointer"
                      key={op.title}
                      onClick={() => setFilterOption(op)}
                  >
                    {op.title}
                  </div>
                  {
                    i < options.length -1 && <hr className="dropdown-divider" />
                  }
                </>
            ))}
      </div>
  )
}

export default AddFilters