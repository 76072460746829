import {Modal} from "antd";
import {Typography} from "antd";
import {useSelector} from "react-redux";
import {getUser} from "../../../app/features/user/userSlice";

const {Paragraph} = Typography

type OtherPlatformsModalProps = {
  visible: boolean,
  close: Function
}

const OtherPlatformsModal = (
    {
        visible,
        close
    }: OtherPlatformsModalProps
) => {
  const user = useSelector(getUser)

  return(
      <Modal
        visible={visible}
        onCancel={() => close()}
        title={"Connect your Platform with IB"}
        width={900}
        centered
      >
        Copy and pase below code in head tag.
        <Paragraph
          copyable
        >
          {`<script src="https://dev.influencerbit.com/scripts/${user.user.brand_id}/script.js" async></script>`}
        </Paragraph>
      </Modal>
  )
}

export default OtherPlatformsModal