import React, {useEffect} from 'react'
import {useRef, useState} from "react";
import {CloseOutlined} from "@ant-design/icons";
import {Form, Button, message, Modal, Result} from "antd";
import ReactFlagsSelect from 'react-flags-select'
import PaymentForm from "./PaymentForm";
import PlanProduct from "../../../Common/Models/PlanProduct";
import {useHistory} from "react-router-dom";

export interface billingAddressInterface {
  full_name?: string,
  line_1?: string,
  postal_code?: string,
  country?: string
}

type PaymentModalProps = {
  isActive: boolean,
  closeModal: Function,
  selectedPlanId: number | null,
  plan: PlanProduct | undefined,
  refresh: Function
}

const DEFAULT_COUNTRY_CODE = "US"

const PaymentModal = (
    {
      isActive = true,
      closeModal,
      selectedPlanId,
        plan,
        refresh
    }: PaymentModalProps
) => {
  const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY_CODE)
  const [billingAddress, setBillingAddress] = useState<billingAddressInterface>({
    country: DEFAULT_COUNTRY_CODE
  })
  const [goToPay, setGoToPay] = useState(false)

  const backF = () => setGoToPay(false)

  const onSelectFlag = (newCC: string) => {
    setCountryCode(newCC)
    setBillingAddress({...billingAddress, country: newCC})
  }

  const proceed = () => {
    refresh()
  }

  function closeFunction() {
    closeModal()
  }

  if(!selectedPlanId || !plan) {
    return null
  }

  return(
      <Modal
          visible={isActive}
          footer={null}
          width={800}
          onCancel={() => closeFunction()}
          centered
          destroyOnClose={true}
      >
        {
          goToPay ? (
              <PaymentForm
                  back={backF}
                  price_id={selectedPlanId}
                  plan={plan}
                  billingAddress={billingAddress}
                  proceed={proceed}
              />
          ) : (
              <>
                <div className="subtitle text-black">
                  Billing Address
                  {/*          <CloseOutlined
              className="is-pulled-right is-cursor-pointer"
              onClick={() => closeModal()}
          />*/}
                </div>
                <Form
                    onFinishFailed={values=>console.log('f',values)}
                >
                  <Form.Item
                      name={'name'}
                  >
                    <div className="field">
                      <label className="label">Name</label>
                      <div className="control">
                        <input
                            name="name"
                            className="input"
                            type="text"
                            onChange={e => setBillingAddress({...billingAddress, full_name: e.target.value})}
                        />
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item
                      name={'address'}
                  >
                    <div className="field">
                      <label className="label">Address</label>
                      <div className="control">
                        <input
                            name="address"
                            className="input"
                            type="text"
                            onChange={e => setBillingAddress({...billingAddress, line_1: e.target.value})}
                        />
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item
                      name={'postal_code'}
                  >
                    <div className="field-body">
                      <div className="field">
                        <label className="label">
                          Postal Code
                        </label>
                        <div className="control">
                          <input
                              name="postal_code"
                              className="input"
                              type="number"
                              onChange={e => setBillingAddress({...billingAddress, postal_code: e.target.value})}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">
                          Country
                        </label>
                        <div className="control">
                          <ReactFlagsSelect
                              searchable
                              selected={countryCode}
                              searchPlaceholder="Search for a country"
                              onSelect={onSelectFlag}
                          />
                        </div>
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <Button
                        type="primary"
                        className="is-pulled-right"
                        htmlType="submit"
                        disabled={!(billingAddress?.full_name && billingAddress.line_1
                            && billingAddress.postal_code && billingAddress.country)}
                        onClick={() => setGoToPay(true)}
                    >
                      Next
                    </Button>
                  </Form.Item>
                </Form>
              </>
          )
        }
      </Modal>
  )
}

export default PaymentModal