import {useLocation} from "react-router";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {clearUser, getUserAsync, setUser} from "../app/features/user/userSlice";
import {useHistory} from "react-router-dom";
import {setToken} from "../Common/ApiCall/CookieMethods";
import {useAppDispatch} from "../app/store";

const ShopifyLoginRedirect = () => {
  const search = useLocation().search
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const history = useHistory();

  function redirectToNewPage(link = "/login") {
    history.push(link);
  }

  const autoLogin = async () => {
    const searchParams = new URLSearchParams(search)
    const authToken = searchParams.get('auth_token')
    if (authToken) {
      dispatch(clearUser(3));
      setToken(authToken);
      const {payload} = await appDispatch(getUserAsync());
      if (payload.success) {
        dispatch(setUser(payload.data));
        redirectToNewPage("/home")
      } else {
        notValid()
      }
    } else {
      notValid()
    }
  }

  const notValid = () => {
    redirectToNewPage()
  }

  useEffect(() => {
    autoLogin()
  }, [])

  return (
    <div>
      Logging in...
    </div>
  )
}

export default ShopifyLoginRedirect
