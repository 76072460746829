import ContentWrapper from "../../../Components/ContentWrapper";
import {Button, Divider, Typography} from "antd";
import Integrations from "../../Integrations";
import {BookOutlined} from "@ant-design/icons";
import {HelpGuideConstants} from "../../../Common/Constants/HelpGuideLinks";

const {Text} = Typography

const ConnectForm = () => {
  return(
      <ContentWrapper>
        <div className={"title is-size-4 is-size-3-fullhd"}>
          Collect Instagram Usernames
          <Button
              className={"is-pulled-right button"}
              size={'large'}
              onClick={() => {window.open(HelpGuideConstants.CAPTURE_INSTAGRAM_USERNAMEs, "_blank")}}
          >
            <BookOutlined /> Guide on How to Collect Usernames
          </Button>
        </div>
        <Divider />
        <Text>
          <div>
              Your Customers are your real influencers! In your Buyer's journey, ask for Instagram Username. Eg: At Checkout page or in your popups or in sign-up or anywhere else you want. Checkout our guide for more info.
          </div>
        </Text>
        <Integrations
            cardDetails={'small'}
            someOnly
            noTitle
            onlyForm
        />
      </ContentWrapper>
  )
}

export default ConnectForm