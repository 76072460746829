import ContentWrapper from "../../../Components/ContentWrapper";
import {Modal} from "antd";
import {useEffect, useState} from "react";
import GetPlans from "../../../Common/ApiCall/IbServer/GetPlans";
import {BadNotif, SuccessNotif} from "../../../Common/Utils/SendNotification";
import Loader from "../../../Components/Loader";
import PlanProduct from "../../../Common/Models/PlanProduct";
import PricingPlanCard from "./PricingPlanCard";
import PaymentModal from "./PaymentModal";
import {useDispatch, useSelector} from "react-redux";
import {clearUser, getUser, getUserAsync, setUser} from "../../../app/features/user/userSlice";
import {useAppDispatch} from "../../../app/store";
import LoadModal from "../../../Components/LoadModal";
import CancelSubscription from "../../../Common/ApiCall/IbServer/CancelSubscription";
import PaymentSuccess from "./PaymentSuccess";
import ShopifyPaymentModal from "./ShopifyPaymentModal";
import {openPopupWidget} from "react-calendly";
import Constants from "../../../Common/Constants/Constants";

const Plans = () => {
  const user = useSelector(getUser)
  const [prices, setPrices] = useState<PlanProduct[]>([])
  const [load, setLoad] = useState(false)
  const [hasFreemium, setHasFreemium] = useState(false)
  const [hasActivePlan, setHasActivePlan] = useState(false)
  const [selectedPlanId, setSelectPlanId] = useState<number | null>(null)
  const [showAnnual, setShowAnnual] = useState(true)
  const [modalActive, setModalActive] = useState(false)
  const [planToBuy, setPlanToBuy] = useState<number | null>(null)
  const [success, setSuccess] = useState(false)
  const [currentProduct] = useState<PlanProduct>(user.user.product)
  const [userLoad, setUserLoad] = useState(false)
  const appDispatch = useAppDispatch()
  const dispatch = useDispatch()

  const getPlans = async () => {
    setLoad(true)
    const prices_response = await GetPlans()
    if (prices_response.success) {
      setPrices(prices_response.data.plans)
    } else {
      BadNotif(prices_response)
    }
    setLoad(false)
  }

  const updateUserFunction = async () => {
    setUserLoad(true)
    const {payload} = await appDispatch(getUserAsync());
    if (payload.success) {
      dispatch(setUser(payload.data))
    } else {
      dispatch(clearUser(2))
    }
    setUserLoad(false)
  }

  const payment_done = async () => {
    setModalActive(false)
    setPlanToBuy(null)
    setSuccess(true)
    setLoad(true)
    await updateUserFunction()
    setLoad(false)
  }

  const CancelMySubscription = async () => {
    setLoad(true)
    const res = await CancelSubscription()
    if (res.success) {
      SuccessNotif("Plan Cancelled")
    } else {
      BadNotif(res)
    }
    await updateUserFunction()
    setLoad(false)
  }

  const changePlan = (price_id: number) => setPlanToBuy(price_id)

  const bookDemo = () => openPopupWidget({url: Constants.CALENDLY_LINK})

  useEffect(() => {
    setModalActive(!!planToBuy)
  }, [planToBuy])

  useEffect(() => {
    getPlans()
    const s = new URLSearchParams(window.location.search)
    if (s.get("payment_success")) setSuccess(true)
  }, [])

  useEffect(() => {
    if (prices.length > 0) {
      setShowAnnual(!user.user.price.is_monthly)
      setSelectPlanId(user.user.v1_price_id ?? prices[0]["yearly"]["id"])
      setHasFreemium(user.user.product.plan_name.toLowerCase() === "free")
      setHasActivePlan(user.user.is_paid)
    }
  }, [prices, user])

  const getPlan = () => prices.find(i => i.monthly.id === planToBuy || i.yearly.id === planToBuy);

  const SELECTED_CLASS_NAME = "is-active is-black"

  return (
    <ContentWrapper
      whiteBg
      style={{
        minHeight: '95%'
      }}
    >
      <LoadModal load={userLoad}/>
      <Modal
        visible={success}
        onCancel={() => setSuccess(false)}
        footer={null}
        width={700}
        centered
        zIndex={11}
      >
        <PaymentSuccess/>
      </Modal>
      {
        planToBuy && (
          user?.shopify ? (
            <ShopifyPaymentModal
              vis={modalActive}
              refresh={payment_done}
              closeModal={() => setPlanToBuy(null)}
              selectedPlanId={planToBuy}
              plan={getPlan()}
            />
          ) : (
            <PaymentModal
              refresh={payment_done}
              isActive={modalActive}
              key={Date.now()}
              closeModal={() => setPlanToBuy(null)}
              selectedPlanId={planToBuy}
              plan={getPlan()}
            />
          )
        )
      }
      <div
        className={"card m-4"}
      >
        <div
          className={"card-content text-center"}
        >
          <div className={"title is-3"}>
            Our Plans
          </div>
          <p className="subtitle">
            Simple pricing. No long term commitments!
          </p>
          {
            !user.shopify && (
              <div
                className={"buttons has-addons is-centered"}
              >
                <button
                  className={`button ${showAnnual ? SELECTED_CLASS_NAME : ""}`}
                  onClick={() => setShowAnnual(true)}
                >
                  Annual Billing
                  <span
                    className="tag ml-2 is-success is-medium"
                  >
                  Save 20%
                </span>
                </button>
                <button
                  className={`button ${!showAnnual ? SELECTED_CLASS_NAME : ""}`}
                  onClick={() => setShowAnnual(false)}
                >
                  Monthly Billing
                </button>
              </div>
            )
          }
          {
            selectedPlanId ? (
              <div className={"container"}>
                <div className="pricing-table">
                  {
                    prices.map((p) => (
                      <PricingPlanCard
                        plan={p}
                        key={p.id}
                        isPromoted={selectedPlanId === p.monthly.id || selectedPlanId === p.yearly.id}
                        show_annual={false}
                        currentProduct={currentProduct}
                        selectedPlanId={selectedPlanId}
                        changePlan={changePlan}
                        cancel={CancelMySubscription}
                        freemium={hasFreemium}
                        activePlan={hasActivePlan}
                        isDisabled={user.shopify && showAnnual}
                      />
                    ))
                  }
                </div>
                <div
                  className={"pt-2"}
                >
                  Want More Impressions?
                  <span
                    className={"text-main cursor-pointer underline"}
                    onClick={() => bookDemo()}
                  >
                    &nbsp;Contact Us
                  </span>
                </div>
              </div>
            ) : (
              <Loader/>
            )
          }
        </div>
      </div>
    </ContentWrapper>
  )
}

export default Plans