import { FormEvent, useEffect, useState } from "react";
import LoginApi from "../Common/ApiCall/IbServer/LoginApi";
import { BadNotif, SuccessNotif } from "../Common/Utils/SendNotification";
import logo from "../Common/Images/eb.png";
import { setToken } from "../Common/ApiCall/CookieMethods";
import { useDispatch } from "react-redux";
import { clearUser, getUserAsync, setUser } from "../app/features/user/userSlice";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../app/store";
import GoogleLogin from "react-google-login";
import SocialLoginApi from "../Common/ApiCall/IbServer/SocialLoginApi";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import "./css/Auth.css";

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
if (!GOOGLE_CLIENT_ID) {
  console.log("Google Client Id Not Present.");
}

const Login = ({}) => {
  const [load, setLoad] = useState(false);
  const [email, setEmail] = useState("");
  const [auth,setAuth]=useState(false)
  const [password, setPassword] = useState("");
  const appDispatch = useAppDispatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const loading = () => setLoad(true);
  const loaded = () => setLoad(false);

  const responseGoogleLoginSuccess = (res: any) => {
    console.log("[Login Success] currentUser: ", res.profileObj);
    socialLogin(
      res.profileObj.email,
      res.profileObj.name,
      res.profileObj.imageUrl,
      "GOOGLE"
    );
  };
  const responseGoogleLoginFailure = (v: any) => {
    console.log("Google Login Failed");
    console.log(v);
  };

  useEffect(() => {}, []);

  const socialLogin = async (
    email: string,
    name: string,
    image: string,
    social: "GOOGLE"
  ) => {
    if(load) return;
    loading();
    const res = await SocialLoginApi(email, "GOOGLE");
    if (res.success) {
      SuccessNotif("Logging you In.");
      setToken(res.data.token);
      const { payload } = await appDispatch(getUserAsync());
      loaded();
      if (payload.success) {
        dispatch(setUser(payload.data));
        history.replace({
          pathname: "/",
        });
        loaded();
      } else {
        dispatch(clearUser(2));
        loaded();
      }
    } else {
      BadNotif(res);
      loaded();
    }
  };

  const userLogin = async (e: FormEvent<HTMLFormElement>) => {
    if(load) return;
    e.preventDefault();
    loading();
    const res = await LoginApi(email, password);
    if (res.success) {
      SuccessNotif("Logging you In.");
      setToken(res.data.token);
      const { payload } = await appDispatch(getUserAsync());
      loaded();
      if (payload.success) {
        dispatch(setUser(payload.data));
        loaded();
      } else {
        dispatch(clearUser(2));
        loaded();
      }
    } else {
      BadNotif(res);
      loaded();
    }
  };

  return (
    <div className="container is-fluid loginContainer">
      <div className="columns is-vcentered is-multiline is-mobile ">
        <div className="column is-5-desktop is-12-tablet is-12-mobile is-offset-1-desktop">
          <div className="flex justify-center items-center">
            <img
              src={logo}
              alt="Engagebud-logo"
              className="image authImage"
            />
          </div>
        </div>
        <div className="column is-4-desktop is-pulled-right is-12-tablet is-12-mobile  is-offset-1-desktop">
          <div className="authContainer">
            <h3
              className="text-black is-size-3 text-left authHeader"
            >
              Log in
            </h3>
            <div className="formContainer mt-4">
              <form action="" onSubmit={userLogin}>
                <label htmlFor="email" className="text-black authLabel">
                  Email
                </label>
                <input
                  className="input authInput mt-2"
                  type="email"
                  name="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <br />
                <label htmlFor="password" className="text-black authLabel">
                  Password
                </label>
                <p className="control has-icons-right">
                  <input
                    className="input authInput mt-2 passInput"
                    type={auth?"text":"password"}
                    name="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="is-small is-right iconBox mt-2"  >
                    <i className={"pr-3"}>
                      {
                        auth ? (
                            <EyeOutlined
                                onClick={()=>setAuth(!auth)}
                            />
                        ) : <EyeInvisibleOutlined
                            onClick={()=>setAuth(!auth)}
                        />
                      }
                    </i>
{/*                    <i className={`fas ${auth?"fa-eye":"fa-eye-slash"} mr-2`} onClick={()=>setAuth(!auth)}></i>*/}
                  </div>
                </p>

                <p className="mt-2 forgotPassword hidden">Forgot Password?</p>
                <div className="text-center">
                  <button
                    className="bg-main text-white px-3 py-2 rounded-md hover:bg-white hover:text-main hover:border-main border font-bold is-size-6-desktop mt-4"
                  >
                    {load ? "Wait..." : "LOG IN"}
                  </button>
                </div>
                {GOOGLE_CLIENT_ID && (
                  <>
                    <p className="mt-4 text-black orText text-center">
                      or
                    </p>
                    <div className="mt-3 text-center">
                      <GoogleLogin
                        clientId={GOOGLE_CLIENT_ID}
                        onSuccess={responseGoogleLoginSuccess}
                        onFailure={responseGoogleLoginFailure}
                        theme={"dark"}
                        fetchBasicProfile
                        cookiePolicy={"single_host_origin"}
                      />
                    </div>
                  </>
                )}

                <p className="forgotText text-center mt-4">
                  <a
                    onClick={() => history.replace({ pathname: "/register" })}
                    className="forgotText"
                  >
                    New to Engagebud? Sign up
                  </a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
