import {Modal, Result} from "antd";
import PlanProduct from "../../../Common/Models/PlanProduct";
import {useEffect, useState} from "react";
import {BeatLoader} from "react-spinners";
import CreateShopifySubscription from "../../../Common/ApiCall/IbServer/Shopify/CreateShopifySubscription";
import {BadNotif} from "../../../Common/Utils/SendNotification";
import ShopifySubscription from "../../../Common/Models/ShopifySubscription";

interface ShopifyPaymentModalProps {
  vis: boolean,
  refresh: Function,
  closeModal: Function,
  selectedPlanId: number | null,
  plan: PlanProduct | undefined
}

const ShopifyPaymentModal = (
  {
    vis,
    refresh,
    closeModal,
    selectedPlanId,
    plan
  }: ShopifyPaymentModalProps
) => {
  const [load, setLoad] = useState(true)
  const [e, setE] = useState(false)
  const [eMess, setEMess] = useState("")

  const createPlan = async () => {
    setLoad(true)
    const res = await CreateShopifySubscription(String(selectedPlanId))
    if (res.success) {
      const s: ShopifySubscription = res.data
      window.open(s.confirmation_url, "_self")
    } else {
      BadNotif(res)
      /*      closeModal()*/
      setE(true)
      setEMess(res.errors[0] ? res.errors[0].message : "")
    }
    setLoad(false)
  }

  useEffect(() => {
    createPlan()
  }, [])

  if (!selectedPlanId || !plan) {
    return (
      <Modal
        visible={vis}
        footer={null}
        onCancel={() => closeModal()}
        centered
        closable={false}
      >
        <Result
          status={'warning'}
          title={'Invalid Plan'}
        />
      </Modal>
    )
  }

  return (
    <Modal
      visible={vis}
      footer={null}
      onCancel={() => closeModal()}
      centered
      closable={false}
    >
      <div className={"text-center container"}>
        {e ? (
          <Result
            status={'error'}
            title={"Something Went Wrong"}
            subTitle={eMess}
          />
        ) : (
          (
            <BeatLoader
              color={"rgb(24, 144, 255)"}
            />
          )
          /*                  <Result
                                status={'info'}
                                title={'Approve Payment in Shopify'}
          /!*                      subTitle={'If you dont see a new screen opened contact us.'}*!/
                            />*/
        )
        }
      </div>
    </Modal>
  )
}

export default ShopifyPaymentModal