import {Button, Form, Input, message, Select, Typography} from "antd";
import {useEffect, useState} from "react";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import MailerliteIntegration from "../../../../Common/Models/MailerliteIntegration";
import ConnectMailerliteApi from "../../../../Common/ApiCall/IbServer/Integrations/ConnectMailerlite";
import GetMailerliteGroups from "../../../../Common/ApiCall/IbServer/Integrations/GetMailerliteGroups";

type MailerliteGroupItem = {
  id: string,
  name: string
}

export type CommonContentProps = {
  close: Function,
  updateUserFunction: Function
}

type MailerliteContentProps = CommonContentProps & {
  details: MailerliteIntegration | null
}

const KlaviyoContent = (
  {
    close,
    details,
    updateUserFunction
  }: MailerliteContentProps
) => {
  const [lists, setLists] = useState<MailerliteGroupItem[]>([])
  const [load, setLoad] = useState(false)
  const [mlConnectForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
    details && mlConnectForm.setFieldsValue({
      api_key: details?.api_key
    })
  }, [details])

  const getMLGroupsForUser = (values: any) => {
    if (values.group_id) {
      integrateML(values);
      return;
    }
    loading()
    GetMailerliteGroups(values.api_key)
      .then(r => {
        if (r.success) {
          setLists(r.data.lists)
        } else {
          BadNotif(r)
        }
        loaded()
      })
  }

  const integrateML = async (values: any) => {
    loading()
    const res = await ConnectMailerliteApi(values.api_key, values.group_id)
    if (res.success) {
      updateUserFunction()
      message.success(res.data, 10)
    } else {
      BadNotif(res)
    }
    loaded()
    close()
  }

  return (
    <>
      <Form
        hideRequiredMark
        onFinish={getMLGroupsForUser}
        form={mlConnectForm}
        initialValues={isConnected ? {
          api_key: details?.api_key
        } : {}}
      >
        <Form.Item
          noStyle
        >
          Mailerlite API KEY
          <Form.Item
            name={"api_key"}
            rules={[{
              required: true
            }]}
          >
            <Input/>
          </Form.Item>
        </Form.Item>
        {
          lists.length > 0 ? (
            <Form.Item
              noStyle
            >
              <div>
                Mailerlite Group
                <Button
                  style={{
                    float: 'right'
                  }}
                  type={'link'}
                  onClick={() => getMLGroupsForUser({api_key: mlConnectForm.getFieldValue("api_key")})}
                >
                  Refresh Lists
                </Button>
              </div>
              <Form.Item
                name={"group_id"}
                style={{
                  width: '100%'
                }}
                rules={[{
                  required: true,
                  message: "Select a Mailerlite group to Continue"
                }]}
              >
                <Select
                  placeholder={"Select a List to Continue"}
                >
                  {lists.map((l) => (
                    <Select.Option
                      value={l.id}
                      title={l.name}
                      key={l.id}
                    >
                      {l.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
          ) : null
        }
        <Form.Item
          shouldUpdate
        >
          {() => {
            return (
              <Button
                disabled={load}
                loading={load}
                type={'primary'}
                title={"Connect Mailerlite"}
                htmlType={'submit'}
              >
                {isConnected ? "Update" : "Integrate"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}

export default KlaviyoContent