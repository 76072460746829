import {MenuGroupItem, MenuItemDetail} from "./TablesData";

const peopleItems: MenuItemDetail[] = [
  {
    title: "All Influencers",
    key: "all_influencers",
    description: "All the influencers that you have",
  },
  {
    title: "Nano Influencers",
    key: "nano_influencers",
    description: "Influencers with follower count less than 2000. Your next door neighbours!",
  },
  {
    title: "Micro Influencers",
    key: "micro_influencers",
    description: "Influencers with follower count in range of 2000 to 10000",
  },
  {
    title: "Large Influencers",
    key: "large_influencers",
    description: "Influencers with follower count greater than 10000",
  },
  {
    title: "Archived",
    key: "archived",
    description: "The People who are not tracked.",
  },
]

const inboundItems: MenuItemDetail[] = [
  {
    title: "All Inbound Influencers",
    key: "all_influencers",
    description: "All the inbound influencers that you have",
  },
  {
    title: "Nano Influencers",
    key: "nano_influencers",
    description: "Influencers with follower count less than 2000. Your next door neighbours!",
  },
  {
    title: "Micro Influencers",
    key: "micro_influencers",
    description: "Influencers with follower count in range of 2000 to 10000",
  },
  {
    title: "Large Influencers",
    key: "large_influencers",
    description: "Influencers with follower count greater than 10000",
  },
  {
    title: "Archived",
    key: "archived",
    description: "The People who are not tracked.",
  },
]

const tableMenus: MenuGroupItem = {
  "people": {
    title: "People",
    key: "people",
    items: peopleItems,
  },
  "inbound": {
    title: "Inbound",
    key: "inbound",
    items: inboundItems,
  },
  "media": {
    title: "Media",
    key: "media",
    items: [
      {
        title: "Your Mentions",
        key: "your_mentions",
        description: "All Mentions",
      },
      /*      {
        title: "Top Engaged",
        key: "top_engaged",
        description: "Top Engaged Mentions"
      }*/
    ],
  },
};

export default tableMenus;
