import { Area } from "@ant-design/charts";
import {Result} from "antd";
import { Line } from "react-chartjs-2";
import {
  BubbleDataPoint,
  ChartData,
  ChartTypeRegistry,
  LineProps,
  ScatterDataPoint,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement, LineElement, Title, Tooltip, Legend,
} from "chart.js";
import moment from "moment";
import {InsightsObject, InsightsResponse} from "../../../ShopifyForm/ShopifyGraphData/ShopifyGraphData";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

type GraphData = {
  value: number;
  end_time: string;
}[]

type GraphProps = {
  data: GraphData;
  names: string,
  insights: InsightsObject[]
};

const Graph = (
    {
      data,
      names,
      insights
}: GraphProps) => {

  // moment('2021-08-20T07:00:00+0000').format("DD/MM/YYYY HH:mm::ss")
  const config = {
    data,
    height: 400,
    xField: "end_time",
    yField: "value",
    point: {
      size: 5,
      shape: "diamond",
    },
    label: {
      style: {
        fill: "#aaa",
      },
    },
  };

  if (insights.length === 0) {
    return (
      <Result
        status={"warning"}
        title={"There are some issues with the metric. Try different one."}
      />
    );
  }

  const a = [];
  const b: any = [];
  console.log(insights)
  for(let i of insights[0].values) {
    a.push(moment(i.end_time).format("Do MMM YYYY"));
    b.push(i.value)
  }

  const clrs = ["#B8C0FF", 'rgb(255, 99, 132)', 'rgb(53, 162, 235)', "#ff0000"]

  const chartjsData: ChartData<
    keyof ChartTypeRegistry,
    (number | ScatterDataPoint | BubbleDataPoint | null)[],
    unknown
  > = {
    labels: a,
    datasets: insights.map((i, index) => (
        {
          label: i.name,
          data: i.values.map((j) => j.value),
          backgroundColor: `${clrs[index%clrs.length]}`,
          borderColor: `${clrs[index%clrs.length]}`,
/*          yAxisID: `y${index}`*/
        }
    )),
  };

  const options: any = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false
    },
    scales: {
      x: {
        ticks: {
          beginAtZero: true,
        },
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        min: 0,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
  };

  const chartProps = {
    height: 100
  };

  //@ts-ignore
  return <Line data={chartjsData} options={options} {...chartProps} />;

  return <Area {...config} padding={"auto"} smooth />;
};

export default Graph;
