import ContentWrapper from "../../../Components/ContentWrapper";
import {Divider, Typography} from "antd";
import {Link} from 'react-router-dom'
import ig from '../../../Common/Images/checkout2.png'

const {Text} = Typography

const ConnectCustomWidget = () => {

  return (
    <ContentWrapper>
      <div className={"title is-size-4 is-size-3-fullhd is-capitalized"}>
        Collect IG usernames at checkout
        {/*                <Button
                    className={"is-pulled-right button"}
                    size={'large'}
                    onClick={() => {window.open(HelpGuideConstants.CAPTURE_INSTAGRAM_USERNAMEs, "_blank")}}
                >
                    <BookOutlined /> Guide on How to Collect Usernames
                </Button>*/}
      </div>
      <Text>
        Collect instagram usernames at your Checkout Screen as shown below 👇
      </Text>
      <Divider/>
      {/*            <Text>
                <div>
                    Your Customers are your real influencers! In your Buyer's journey, ask for Instagram Username. Eg: At Checkout page or in your popups or in sign-up or anywhere else you want. Checkout our guide for more info.
                </div>
            </Text>*/}
      <img
        className={"my-4"}
        src={ig}
        alt={"Custom Widget"}
        title={"Custom Widget"}
      />
      <br/>
      <Link
        to={"/shopify_form"}
        className={"button bg-verdigris text-white is-capitalized font-semibold"}
      >
        Create Custom Widget Integration
      </Link>
    </ContentWrapper>
  )
}

export default ConnectCustomWidget