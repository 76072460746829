import {Button, Form, Input, message} from "antd";
import {useEffect, useState} from "react";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";
import GoAffProIntegration from "../../../../Common/Models/GoAffProIntegration";
import ConnectGoAffPro from "../../../../Common/ApiCall/IbServer/Integrations/ConnectGoAffPro";

type GoAffProContentProps = {
  details: GoAffProIntegration | null,
} & CommonContentProps

const GoAffProContent = (
  {
    close,
    details,
    updateUserFunction
  }: GoAffProContentProps
) => {
  const [load, setLoad] = useState(false)
  const [privyContentForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
  }, [details])

  const integratePrivy = async (values: any) => {
    loading()
    const res = await ConnectGoAffPro(values.access_token, values.public_token)
    if (res.success) {
      message.success("GoAffPro is connected now.")
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
  }

  return (
    <>
      <Form
        hideRequiredMark
        onFinish={integratePrivy}
        form={privyContentForm}
        initialValues={details ?? {}}
      >
        <Form.Item
          noStyle
        >
          Access Token
          <Form.Item
            name={"access_token"}
            rules={[{
              required: true
            }]}
          >
            <Input
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Public Token
          <Form.Item
            name={"public_token"}
            rules={[{
              required: true
            }]}
          >
            <Input
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          shouldUpdate
        >
          {() => {
            return (
              <Button
                disabled={load}
                loading={load}
                type={'primary'}
                htmlType={'submit'}
              >
                {isConnected ? "Update" : "Activate"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}

export default GoAffProContent