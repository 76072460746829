import LoadModal from "../../Components/LoadModal";
import ContentWrapper from "../../Components/ContentWrapper";
import PricingPlanCard from "../../Dashboard/Settings/Plans/PricingPlanCard";
import GetPlans from "../../Common/ApiCall/IbServer/GetPlans";
import {BadNotif, SuccessNotif} from "../../Common/Utils/SendNotification";
import {useEffect, useState} from "react";
import PlanProduct from "../../Common/Models/PlanProduct";
import AddPriceDetails from "../../Common/ApiCall/IbServer/Shopify/AddPriceDetails";
import {Button, Form, Input, message} from "antd";
import CheckCoupon from "../../Common/ApiCall/IbServer/Shopify/CheckCoupon";
import shopifyStyles from './ShopifyPlans.module.sass'
import {PlusOutlined} from "@ant-design/icons";

const ShopifyPlans = () => {
  const [prices, setPrices] = useState<PlanProduct[]>([])
  const [load, setLoad] = useState(false)
  const [couponForm] = Form.useForm()
  const [cVal, setCVal] = useState("")
  const [valid, setValid] = useState(false)
  const [trialDays, setTrialDays] = useState(null)

  const getPlans = async () => {
    setLoad(true)
    const prices_response = await GetPlans(valid ? cVal : "")
    if (prices_response.success) {
      setPrices(prices_response.data.plans)
      setTrialDays(prices_response.data.trial_days)
    } else {
      BadNotif(prices_response)
    }
    setLoad(false)
  }

  async function continuePayment(price_id: number) {
    setLoad(true)
    const domain = new URLSearchParams(window.location.search).get("domain")
    if (!domain) {
      setLoad(false)
      BadNotif(null, "Domain not found")
      return
    }
    const r = await AddPriceDetails(price_id, domain, cVal)
    if (r.success) {
      window.open(r.data.url, "_self")
    } else {
      BadNotif(r)
    }
    setLoad(false)
  }

  async function formSubmit(values: any) {
    if(!values.value) {
      message.warn("Please enter a coupon code")
      return
    }
    setLoad(true)
    const res = await CheckCoupon(cVal)
    if (res.success) {
      SuccessNotif(`Coupon Activated`)
      setValid(true)
    } else {
      BadNotif(res)
    }
    setLoad(false)
  }

  useEffect(() => {
    getPlans()
  }, [valid])

  let freePlan = prices.filter(a => a.plan_name.toLowerCase() === 'free')[0]

  return (
    <ContentWrapper
      addToClassName={`shopify-plan-wrapper ${shopifyStyles.shopifyPlanWrapper}`}
    >
      <LoadModal load={load}/>
      <div
        className={"p-6 container"}
      >
        <div
          className={"text-left"}
        >
          <div
            className={"title is-3 hidden"}
          >
            Every plan comes with a <span className={"bg-main text-white px-2"}>{trialDays} days trial!</span>
          </div>
          <p
            className="subtitle font-normal pt-2"
          >
            Cancel Anytime.
          </p>
          <div className={"text-left"}>
            <span className={"text-main uppercase"}>
              Enter Giftcard or Discount code
            </span>
            <Form
              form={couponForm}
            >
              <Form.Item>
                {valid && (
                  <span className="tag is-danger is-large">
                    {cVal}
                    <button
                      className="delete"
                      onClick={() => setValid(false)}
                    />
                  </span>
                )}
                <Form.Item
                  noStyle
                >
                  <Input
                    hidden={valid}
                    className={"max-w-[170px]"}
                    placeholder={"Enter Code Here"}
                    onChange={e => setCVal(e.target.value.toUpperCase())}
                    value={cVal}
                    onPressEnter={() => formSubmit({a: ""})}
                  />
                </Form.Item>
                <Button
                  hidden={valid}
                  onClick={() => formSubmit({value: cVal})}
                  className={"uppercase bg-verdigris text-white font-bold mx-2"}
                >
                  Apply
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="pricing-table">
            {
              prices.map((p) => {
                return(
                  <PricingPlanCard
                    plan={p}
                    key={p.id + cVal + (new Date().getTime())}
                    isPromoted={p.plan_name.toLowerCase() === 'starter'}
                    show_annual={false}
                    currentProduct={freePlan}
                    selectedPlanId={freePlan?.id || 123}
                    activePlan={true}
                    freemium={p.plan_name.toLowerCase() === 'free'}
                    isDisabled={false}
                    changePlan={(a: any) => continuePayment(a)}
                    cancel={() => {}}
                    // choose_plan_text={`${trialDays} Day-Trial`}
                    choose_plan_text={`Choose Plan`}
                  />
                )
              })
            }
          </div>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default ShopifyPlans