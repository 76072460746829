const Constants = {
    dateRangeFormat: "DD/MM/YYYY",
    currencySymbols: {
        inr: "₹",
        usd: "$",
    },
    CALENDLY_LINK: "https://calendly.com/abhinavai/engagebud",

    IMAGE_TYPES: ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'],
    AUDIO_TYPES: [
        'audio/mpeg',
        'audio/mp3',
        'audio/wav',
        'audio/ogg',
    ],
    FILE_STATUS: {
        UPLOADING: "uploading",
        DONE: "done",
    }

}

export default Constants