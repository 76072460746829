import DownloadButton from "./DownloadButton";
import {useDispatch, useSelector} from "react-redux";
import videoStyles from './IbVideoPlayer.module.sass'
import {getReduxMedia, removeMedia} from "../../../../../app/features/user/userSlice";
import ReactModal from "react-modal";
import {useEffect, useRef} from "react";
import {CloseOutlined} from "@ant-design/icons";

const VideoReact = require('video-react')

type IbVideoPlayerProps = {
}

const IbVideoPlayer = ({}: IbVideoPlayerProps) => {
  const media: {
    is_video: boolean | null, media_url: string | null
  } = useSelector(getReduxMedia)
  const dispatch = useDispatch()
  const videoRef = useRef()

  useEffect(() => {
    if(media.media_url) {
    }
  }, [media])

  if(!media.media_url) return null

  return (
    <ReactModal
      isOpen={!!media.media_url}
      contentLabel="Media"
      shouldFocusAfterRender
      className={`${videoStyles.reactMainModalPlayer} bg-white`}
      onRequestClose={() => dispatch(removeMedia(1))}
    >
      <div
        className={"bg-white react-main-modal-child"}
      >
        <div
          className={`${videoStyles.playerDivComponent} columns is-centered`}
        >
          <div className={"column"} />
          <div className={"column is-narrow"}>
            {
              media.is_video ? (
                <VideoReact.Player
                  playsInline
                  fluid={false}
                  src={media.media_url}
                  autoPlay
                  ref={videoRef}
                  height={600}
                >
                  <VideoReact.ControlBar autohide={false}>
                    <DownloadButton
                      order={7}
                    />
                  </VideoReact.ControlBar>
                </VideoReact.Player>
              ) : (
                <img
                  width={"100%"}
                  height={"200px"}
                  alt={"Media"}
                  className={`${videoStyles.playerImage}`}
                  src={media.media_url}
                />
              )
            }
          </div>
          <div className={"column"} />
          <div className={"column is-narrow pr-0"}>
            <CloseOutlined
              onClick={() => dispatch(removeMedia(1))}
            />
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

export default IbVideoPlayer