import {Button, Form, Input, message, Modal} from "antd";
import {useState} from "react";
import ChangePassword from "../../../Common/ApiCall/IbServer/ChangePassword";
import {BadNotif, SuccessNotif} from "../../../Common/Utils/SendNotification";

interface UpdatePasswordInterface {
  active: boolean,
  onClose: Function
}

const UpdatePassword = (
    {
        active,
        onClose
    }: UpdatePasswordInterface
) => {
  const [load, setLoad] = useState(false)

  const submit = async (values: any) => {
    if(values.password !== values.confirm_password) {
      message.error("Passwords doesn't match")
      return
    }
    setLoad(true)
    const res = await ChangePassword(values.password)
    if(res.success) {
      SuccessNotif(res.data)
      onClose()
    } else {
      BadNotif(res)
    }
    setLoad(false)
  }

  return(
      <Modal
        visible={active}
        onCancel={() => onClose()}
        title={"Change Password"}
        footer={null}
        centered
        destroyOnClose
      >
        <Form
          hideRequiredMark
          onFinish={submit}
        >
          <Form.Item
            noStyle
          >
            New Password
            <Form.Item
              name={"password"}
              rules={[{required: true}]}
            >
              <Input
                placeholder={"Enter Password Here"}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
              noStyle
          >
            Confirm Password
            <Form.Item
                rules={[{required: true}]}
                name={"confirm_password"}
            >
              <Input
                  placeholder={"Enter Password Again Here"}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button
              type={'primary'}
              size={'large'}
              htmlType={'submit'}
              loading={load}
              disabled={load}
            >
              {load ? "Submitting..." : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
  )
}

export default UpdatePassword