import {useSelector} from "react-redux";
import {getIsAuthed} from "../app/features/user/userSlice";
import {Redirect} from "react-router-dom";
import Login from "./Login";
import UserLogWrapper from "../Components/UserLogWrapper";
import ContentWrapper from "../Components/ContentWrapper";
import {Col, Row} from "antd";
import Register from "./Register";
import ShopifyRegister from "./ShopifyRegister";

type AuthProps = {
  signIn?: boolean,
  shopify?: boolean
}

const Auth = (
    {
        signIn = true,
        shopify = false
    }: AuthProps
) => {
  const isAuthed = useSelector(getIsAuthed)

  if(isAuthed) {
    return (
        <Redirect to={"/shopify_auth"+window.location.search} />
    )
  }

  return (
      signIn ? <Login /> : (shopify ? <ShopifyRegister /> : <Register />)
  )

}

export default Auth