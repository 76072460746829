import {Divider, Progress} from "antd";
import {useSelector} from "react-redux";
import {getUser} from "../../../app/features/user/userSlice";
import {useEffect, useState} from "react";
import UserLimit from "../../../Common/Models/UserLimit";

const UsageStats = () => {
  const user = useSelector(getUser)
  const [product, setProduct] = useState<UserLimit>(user.user.limits)

  useEffect(() => {
    setProduct(user.user.limits)
  }, [user])

  return (
    <div
      className={"m-4 bg-titan min-h-full"}
    >
      <div
        className={"rounded-md bg-titan border-[1px] border-black min-h-[80vh]"}
      >
        <div className={"card-content"}>
          <h2
            className={"text-black text-2xl font-bold"}
          >
            Usage Statistics
          </h2>
          <Divider className={"my-3"} />
          <div className={"hero"}>
            <div className={"hero-body pt-2 px-0"}>
              <div className={"container is-fluid px-1"}>
                <div className={"columns"}>
                  <div className={"column"}>
                    <div
                      className={"w-full space-y-3"}
                    >
                      <div className={`shadow-md w-full bg-[#111A22] text-white rounded-[6px]`}>
                        <div className={"card-content"}>
                          <h2
                            className={"is-pulled-left font-semibold text-xl text-white"}
                          >
                            Tracked Profiles
                          </h2>
                          <Progress
                            percent={product.tracked_profiles_used * 100 / product.max_tracked_profiles}
                            showInfo={false}
                          />
                          <h5
                            className={"text-white"}
                          >
                            <span>
                              {product.tracked_profiles_used} of {product.max_tracked_profiles} Tracked Profiles Used
                            </span>
                            <span
                              className={"float-right text-white"}
                            >
                              {`${(product.tracked_profiles_used * 100 / product.max_tracked_profiles).toFixed(2)}% Used`}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UsageStats