import { Button, Typography } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import IBIntegrations from "./IBIntegrations";
import IntegrationCard from "./IntegrationCard";
import Integration from "../../Common/Models/Integration";
import ConnectIntegrationModal from "./ConnectIntegrationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUser,
  getUser,
  getUserAsync,
  setUser,
} from "../../app/features/user/userSlice";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/store";
import LoadModal from "../../Components/LoadModal";
import { VideoCameraOutlined } from "@ant-design/icons";
import CommonIntegration from "../../Common/Models/CommonIntegration";
import DisconnectIntegration from "../../Common/ApiCall/IbServer/DisconnectIntergration";
import { BadNotif, SuccessNotif } from "../../Common/Utils/SendNotification";

const { Title } = Typography;

export type IntegrationsProps = {
  cardDetails?: "full" | "small";
  title?: string;
  noTitle?: boolean;
  onlyForm?: boolean;
  onlyMarketing?: boolean;
  someOnly?: boolean;
};

const Integrations = ({
  cardDetails = "full",
  title = "Integrations",
  noTitle = false,
  onlyForm = false,
  onlyMarketing = false,
  someOnly = false,
}: IntegrationsProps) => {
  const [allIntegrations] = useState(IBIntegrations);
  const [integrationToConnect, setIntegrationToConnect] = useState<{
    card: Integration | null;
    details: any;
  }>({ card: null, details: null });
  const [load, setLoad] = useState(false);
  const [showAll, setShowAll] = useState(!someOnly);
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState<string>('');

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>): void {
    setSearchQuery(event.target.value);
  }

  const showEveryIntegration = () => setShowAll(true);

  const loading = () => setLoad(true);
  const loaded = () => setLoad(false);

  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const [myIntegrations, setMyIntegrations] = useState(user.integrations);

  useEffect(() => {
    if (myIntegrations !== user.integrations) {
      setMyIntegrations(user.integrations);
    }
  }, [user]);
  useEffect(() => {
    if (integrationToConnect?.card?.type) {
      setIntegrationToConnect({
        card: integrationToConnect.card,
        details: myIntegrations[integrationToConnect.card.type],
      });
    }
  }, [myIntegrations]);

  const connectIntegration = (i: Integration) => {
    setIntegrationToConnect({
      card: i,
      details: myIntegrations[i.type],
    });
  };

  const disconnectIntegration = async (
    i: Integration,
    id: CommonIntegration["id"]
  ) => {
    const r = await DisconnectIntegration(i.type, Number(id));
    if (r.success) {
      SuccessNotif(`${i["name"]} Disconnected`);
    } else {
      BadNotif(r);
    }
    updateUserFunction();
  };

  const closeIntegrationConnect = () => {
    setIntegrationToConnect({
      card: null,
      details: null,
    });
  };

  function integrationsToShow(): Integration[] {
    return filterIntegrations(allIntegrations, searchQuery).filter((e) => e.is_showable);
  }

  function filterIntegrations(integrations: Integration[], query: string): Integration[] {
    if (!query) {
      return integrations;
    }

    const lowerCaseQuery = query.toLowerCase();

    return integrations.filter((integration) => {
      const name = integration.name.toLowerCase();
      const description = integration.description?.toLowerCase();

      return name.includes(lowerCaseQuery) || description?.includes(lowerCaseQuery);
    });
  }


  const updateUserFunction = async () => {
    loading();
    const { payload } = await appDispatch(getUserAsync());
    if (payload.success) {
      dispatch(setUser(payload.data));
    } else {
      dispatch(clearUser(2));
    }
    loaded();
  };

  return (
    <div className={"p-5 bg-cultured"}>
      <LoadModal load={load} />
      <ConnectIntegrationModal
        integration={integrationToConnect.card}
        myIntegration={integrationToConnect.details}
        close={closeIntegrationConnect}
        disconnect={disconnectIntegration}
        updateUserFunction={updateUserFunction}
      />
      {!noTitle && (
        <Title>
          {title} 
            <div
          className={"mx-4 mb-2 rounded-md inline-block shadow-xl cursor-pointer hover:shadow-none"}
        >
          <label
            className="relative block min-w-[350px]"
          >
            <span className="sr-only">Search</span>
            <span className="absolute inset-y-0 left-0 flex items-center px-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#cbd5e1"
                className="w-5 h-5 fill-slate-300"
              >
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
              </svg>
            </span>
            <input
              className="text-black placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-[36px] pr-3 shadow-sm focus:outline-none focus:border-darkmain focus:ring-darkmain focus:ring-1 sm:text-sm"
              placeholder={"Search by Integration name"}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </label>
        </div>
          <Button className={"is-pulled-right"} size={"large"}>
            <VideoCameraOutlined /> {t("integration.help")}
          </Button>
          
        </Title>
      )}
      <div
        className={
          "columns is-multiline is-variable is-5-fullhd is-0 justify-around items-stretch"
        }
      >
        {integrationsToShow().map((con, index) => (
          <div
            className={`column is-3-fullhd is-4-tablet m-1 m-2-widescreen p-1 mb-6`}
            key={con.name}
            hidden={!showAll && index > 2}
          >
            <IntegrationCard
              key={con.name}
              integration={con}
              connectFunction={connectIntegration}
              cardDetails={cardDetails}
              myIntegration={myIntegrations[con.type]}
              disconnect={disconnectIntegration}
            />
          </div>
        ))}
      </div>
      {!showAll && (
        <div className={"text-center"}>
          <button
            className={"button"}
            hidden={integrationsToShow().length <= 3}
            onClick={() => showEveryIntegration()}
          >
            Show More...
          </button>
        </div>
      )}
    </div>
  );
};

export default Integrations;
