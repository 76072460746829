import {Button, Form, Input} from "antd";
import {useEffect, useState} from "react";
import CustomButton from "../../../../../Common/Components/CustomButton";
import {campaignTypesArr} from "../CampaignTypeGameScreen/CampaignTypeGameScreen";
import {titleCase} from "../../../../../Common/HelperFunctions/Commons";

type CampaignNameProps = {
  basics: {
    name: string,
    description?: string
  },
  update: Function,
  next: Function,
  campaignType?: string,
  back: Function,
  campaignPosition?: string
}

const CampaignName = (
  {
    basics,
    update,
    next,
    campaignType,
    back,
    campaignPosition
  }: CampaignNameProps
) => {
  const [basicsForm] = Form.useForm()
  const [load, setLoad] = useState(false)
  const [here, setHere] = useState(false)

  const changedValues =  (a: any) => {
    update({...basics, ...a})
  }

  const done = async () => {
    setLoad(true)
    await next()
    setLoad(false)
  }

  const campaignTypeObject = campaignTypesArr.filter(a => a.key == campaignType)[0]

  useEffect(() => {
    if(!basics.name && !here) {
      let defaultName = basics?.name || `Awesome ${titleCase(campaignTypeObject?.title || campaignType)} Game - ${new Date().toLocaleDateString()}`
      defaultName = defaultName.replaceAll("_", " ").replaceAll("/", "-")
      basicsForm.setFieldsValue({
        ...basics,
        name: defaultName
      })
      changedValues({name: defaultName})
      setHere(true)
    }
  }, [basics])

  return(
    <div className={"text-left"}>
      <h5 className={"text-white text-5xl font-extrabold mb-5"}>
        Give your campaign a name
      </h5>
      <div
        className={"grid grid-cols-2 px-3 py-5 bg-main rounded-lg"}
      >
        {
          campaignTypeObject && (
            <div
              className={"text-center mx-auto"}
              key={"game_details"}
            >
              {
                campaignTypeObject.image && (
                  <img
                    src={campaignTypeObject.image}
                    alt={campaignTypeObject.title}
                    className={"max-w-[250px] py-3 pb-4"}
                  />
                )
              }
              <span
                className={"text-center mt-3 pb-3 text-lg text-white font-semibold"}
              >
                {campaignTypeObject.title}
              </span>
            </div>
          )
        }
        <div
          className={"flex flex-col align-center items-center justify-center"}
          key={"name_form"}
        >
          <Form
            form={basicsForm}
            onFinish={() => {}}
            initialValues={basics ?? {}}
            onValuesChange={e => changedValues(e)}
            className={"w-full"}
          >
            <Form.Item
              noStyle
            >
              <Form.Item
                name={"name"}
                className={"my-2"}
              >
                <Input
                  className={"px-3 py-4 !bg-[#EFECFF] rounded-[5px]"}
                  placeholder={"Your Campaign name"}
                  size="middle"
                  autoFocus
                  onPressEnter={() => done()}
                />
              </Form.Item>
              <div
                className="w-full flex justify-end space-x-3"
              >
                <div
                  onClick={() => back()}
                  className="text-gray-700 text-sm font-bold cursor-pointer bg-vodka hover:bg-main-dark rounded-lg px-2 py-2 hover:shadow-md hover:bg-opacity-80 w-fit"
                >
                  Back
                </div>
                <CustomButton
                  content={"Start Building"}
                  onClickAction={done}
                  disabled={load}
                  loading={load}
                  loadingContent={"Getting things ready..."}
                  zoomOnHover
                />
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default CampaignName