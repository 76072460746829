import {Redirect, useLocation} from "react-router";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {getUser} from "../app/features/user/userSlice";

const ShopifyAuth = () => {
  const user = useSelector(getUser)
  const search = useLocation().search

  useEffect(() => {
  }, [])

  if(user) {
    return <Redirect
        to={'/quick-start'+search}
    />
  }

  return (
      <Redirect to={'/register/shopify'+search} />
  )
}

export default ShopifyAuth