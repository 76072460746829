import {Button, Form, Input, message, Typography} from "antd";
import {useEffect, useState} from "react";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import {ApiConstants} from "../../../../Common/ApiCall/ApiConstants";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";
import CustomFormIntegration from "../../../../Common/Models/CustomFormIntegration";
import ConnectRefersionFormApi from "../../../../Common/ApiCall/IbServer/ConnectRefersionFormApi";

const {Text} = Typography

type RefersionFormContentProps = {
  details: CustomFormIntegration | null,
} & CommonContentProps

const RefersionFormContent = (
  {
    close,
    details,
    updateUserFunction
  }: RefersionFormContentProps
) => {
  const [load, setLoad] = useState(false)
  const [refersionFormAntdForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
  }, [details])

  const integrateCustomForm = async (values: any) => {
    loading()
    const res = await ConnectRefersionFormApi(values.email_field, values.instausername_field, values.firstname_field, values.last_name_field)
    if (res.success) {
      message.success("Refersion is connected now. You can start collecting data.")
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
  }

  return (
    <>
      <Form
        hideRequiredMark
        onFinish={integrateCustomForm}
        form={refersionFormAntdForm}
        initialValues={{
          instausername_field: details?.instausername_field ?? "value",
          email_field: "email"
        }}
      >
        {
          isConnected && (
            <Form.Item
              noStyle
            >
              Webhook URL
              <Form.Item
              >
                <Text
                  copyable
                >
                  {`${ApiConstants.PROD_SERVER_URL}/hooks/refersion/${details?.webhook_unique_id}`}
                </Text>
              </Form.Item>
            </Form.Item>
          )
        }
        <Form.Item
          noStyle
        >
          Email Property
          <Form.Item
            name={"email_field"}
            rules={[{
              required: true
            }]}
          >
            <Input
              disabled
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Custom Instagram Username Property
          <Form.Item
            name={"instausername_field"}
            rules={[{
              required: true
            }]}
          >
            <Input
              disabled
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          shouldUpdate
        >
          {() => {
            return (
              <Button
                disabled={load}
                loading={load}
                type={'primary'}
                htmlType={'submit'}
              >
                {isConnected ? "Update" : "Activate"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}

export default RefersionFormContent