import {Redirect, Route} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {getIsAuthed} from "../../app/features/user/userSlice";
import React from "react";

type PrivateRouteProps = {
  component: React.ReactNode,
  path?: string
}

const PrivateRoute = (
    {
      component,
        path
    }: PrivateRouteProps) => {
  const isAuthed = useSelector(getIsAuthed)

  return(
      <Route
        path={path}
        // @ts-ignore
        render={() => isAuthed ? (
            component
        ) : (
            <Redirect to={'/login'} />
        )}
      />
  )
}

export default PrivateRoute