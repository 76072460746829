import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import GetUserDetails from "../../../Common/ApiCall/IbServer/GetUserDetails";

const initialState = {
  value: 0,
  status: 'idle',
  isDev: process.env.NODE_ENV === 'development',
  user: null,
  isAuthed: false,
  init: true,
  igId: null,
  media: {
    is_video: null,
    media_url: null
  },
  helpVideo: null
};

export const getUserAsync = createAsyncThunk(
    'globalObject/GetUserDetails',
    async (payload, {dispatch, rejectWithValue}) => {
        let user = await GetUserDetails();
        return user;
    }
)

export const userSlice = createSlice({
  name: 'globalObject',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    setDevToProd: (state= initialState, action) => {
      state.isDev = action.payload
    },
    setUser: (state= initialState, action) => {
      state.user = action.payload
      state.isAuthed = true
    },
    clearUser: (state = initialState) => {
      state.user = null
      state.isAuthed = false
    },
    setInit: (state, action) => {
      state.init = action.payload
    },
    setIgUser: (state, action) => {
      state.igId = action.payload
    },
    clearIgUser: (state) => {
      state.igId = null
    },
    showHelpVideo: (state, action) => {
        state.helpVideo = action.payload
    },
    removeHelpVideo: (state) => {
        state.helpVideo = null
    },
    showMedia: (state, action) => {
      state.media = {
        is_video: action.payload.video, media_url: action.payload.url
      }
    },
    removeMedia: (state) => {
      state.media = initialState.media
    },
    extraReducers: (builder) => {
      builder
          .addCase(getUserAsync.pending, (state) => {
            state.status = "loading";
          })
          .addCase(getUserAsync.fulfilled, (state, action) => {
            state.status = "idle";
            state.user = action.payload;
          })
    },
    updateUser: async (state) => {
      GetUserDetails()
          .then(r => {
            if(r.success) {
              setUser(r.data)
            } else {
              clearUser()
            }
          })
      return false
    },
  }
});

export const {
  increment, decrement, incrementByAmount, setDevToProd,
  setUser, clearUser, updateUser, setIgUser, clearIgUser,
    showMedia, removeMedia, showHelpVideo, removeHelpVideo
} = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
const selectCount = (state) => state.globalObject.value

const checkIfDev = (state) => state.globalObject.isDev

const getUser = (state) => state.globalObject.user

const getIsAuthed = (state) => state.globalObject.isAuthed

const getInstaId = (state) => state.globalObject.igId

const getReduxMedia = (state) => state.globalObject.media

const getHelpVideo = (state) => state.globalObject.helpVideo

export {
  selectCount, checkIfDev, getUser, getIsAuthed, getInstaId, getReduxMedia, getHelpVideo
}

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};

export default userSlice.reducer;
