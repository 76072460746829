import AccountDetails from "./AccountDetails";
import ConnectFB from "../Settings/SocialAccounts/ConnectFB";

const MyAccount = () => {

  return(
      <div
        className={"m-4"}
      >
        <AccountDetails />
        <div className={"card hidden mt-4"}>
          <div className={"card-content"}>
            <ConnectFB whiteBg inlineCards />
          </div>
        </div>
      </div>
  )
}

export default MyAccount
