import PlanProduct from "../../../Common/Models/PlanProduct";
import {useEffect, useState} from "react";
import PlanPricing from "../../../Common/Models/PlanPricing";
import {Popconfirm, Spin} from "antd";

type PricingPlanCardProps = {
  plan: PlanProduct,
  isPromoted: boolean,
  show_annual: boolean,
  selectedPlanId: number,
  changePlan: Function,
  currentProduct: PlanProduct | null,
  cancel: Function,
  freemium: boolean,
  activePlan: boolean,
  isDisabled?: boolean,
  choose_plan_text?: string,
  dark?: boolean
}

export const getRoundedPrice = (a: number, is_monthly = true) => (a / (100 * (is_monthly ? 1 : 12))).toFixed(2)

const getFixedPrice = (a: number, is_monthly = true) => (a / (100 * (is_monthly ? 1 : 12))).toFixed(0)

function getClassColor(planName: string) {
  switch (planName.toLowerCase()) {
    case "free":
      return ""
    case "lite":
    case "basic":
    case "business":
    case "enterprise":
      return "is-info"
    default:
      return ""
  }
}

const PricingPlanCard = (
  {
    plan,
    isPromoted,
    show_annual,
    selectedPlanId,
    changePlan,
    cancel,
    currentProduct,
    freemium,
    activePlan,
    isDisabled,
    choose_plan_text = "Choose Plan",
    dark = false
  }: PricingPlanCardProps
) => {
  const [price, setPrice] = useState<PlanPricing | null>(null)
  const [isCurrent, setIsCurrent] = useState<boolean>(false)
  const [cancelling, setCancelling] = useState(false)

  const FREE_PLAN = plan.plan_name.toLowerCase() === "free"

  useEffect(() => {
    setPrice(show_annual ? plan.yearly : plan.monthly)
  }, [show_annual])

  useEffect(() => {
    setIsCurrent((selectedPlanId === price?.id) || (freemium && FREE_PLAN))
  }, [price, currentProduct, selectedPlanId, activePlan])

  const proceedToPay = async () => {
    if (isCurrent && !freemium && activePlan) {
      setCancelling(true)
      await cancel()
      setCancelling(false)
    } else {
      changePlan(price?.id)
    }
  }

  function formatNumber(num: number) {
    if (num < 1000) {
      return num.toString(); // return the number as-is if it's less than 1000
    } else if (num < 1000000) {
      return (num / 1000).toFixed(1) + 'K'; // convert to thousands and add 'K'
    } else {
      return (num / 1000000).toFixed(1) + 'M'; // convert to millions and add 'M'
    }
  }

  if (!price) return null

  return (
    <div
      className={`
      pricing-plan shadow-lg rounded-xl ${!isCurrent || freemium || !activePlan ? getClassColor(plan.plan_name) : "is-danger"}
      ${isPromoted ? "!bg-main !text-white" : ""}
      `}
    >
      <div
        className={`
        plan-header text-white font-bold !text-2xl
        ${isPromoted ? "!bg-main !text-white" : ""}
        `}
      >
        {plan.plan_name}
      </div>
      <div
        className={`
        plan-price text-black
        ${isPromoted ? "!bg-main !text-white" : ""} 
          `}
      >
        <span className={`plan-price-amount ${isPromoted ? "!text-white" : "!text-black"}`}>
          <span className={`plan-price-currency ${isPromoted ? "!text-white" : "!text-black"}`}>
            $
          </span>
          {getFixedPrice(price.price_in_cents, price.is_monthly)}
        </span>/month
      </div>
      <div
        className={`plan-items !text-gray-400 ${isPromoted ? "!text-white !bg-main" : ""}`}
      >
        <div
          className={`plan-item ${isPromoted ? "!text-white !bg-main" : "!text-gray-600"}`}
          data-feature="Unlimited Campaigns"
          key={"unlimited_campaigns"}
        >
          {`${FREE_PLAN?3:"Unlimited"} Campaigns`}
        </div>
        <div
          className={`plan-item font-bold ${isPromoted ? "!text-white !bg-main" : "!text-gray-600"}`}
          data-feature="Tracked Profiles"
          key={"Tracked Profiles"}
        >
          {plan.unlimited ? "Unlimited" : formatNumber(plan.tracked_profiles)}{" Impressions /month"}
        </div>
        <div
          className={`plan-item ${isPromoted ? "!text-white !bg-main" : "!text-gray-600"}`}
          data-feature="limit"
          key={"limit"}
        >
          No limitations on features for all plans
        </div>
        <div
          className={`plan-item hidden ${isPromoted ? "!text-white !bg-main" : "!text-gray-600"}`}
          data-feature="unique_coupons"
          key={"unique_coupons"}
        >
          Automatic Unique Coupons
        </div>
        <div
          className={`hidden plan-item ${isPromoted ? "!text-white !bg-main" : "!text-gray-600"}`}
          data-feature="game-sessions"
          key={"game-sessions"}
        >
          Pricing based on Game Sessions
        </div>

      </div>
      <div
        className={`plan-footer ${isPromoted ? "!bg-main !text-white" : ""}`}
      >
        {
          plan.plan_name.toLowerCase() === "free" ? (
            <div>
              <div
                className={`px-2 py-2 rounded-md cursor-pointer my-2 w-full text-center font-bold uppercase bg-white 
                border border-main border-2 text-main ${isCurrent && activePlan && !freemium && "hidden"}
                hover:bg-main hover:text-white ${isPromoted ? "hover:border-white" : ""}
                `}
                onClick={() => proceedToPay()}
              >
                {isCurrent ? "Current plan" : "choose plan"}
              </div>
            </div>
          ) : (
            <Popconfirm
              title={"Continue?"}
              onConfirm={() => proceedToPay()}
              disabled={isCurrent}
            >
              <div
                className={`px-2 py-2 select-none rounded-md cursor-pointer my-2 w-full text-center font-bold uppercase bg-white 
                border border-main border-2 text-main ${isCurrent && activePlan && !freemium && "is-danger"}
                hover:bg-main hover:text-white ${isPromoted ? "hover:border-white" : ""}
                ${((isCurrent && freemium) || isDisabled) ?? true ? "opacity-50 cursor-not-allowed" : ""} 
                `}
              >
                {
                  cancelling ? (
                    <Spin/>
                  ) : (
                    isCurrent ? (activePlan ? "Cancel" : "Renew") : choose_plan_text
                  )
                }
              </div>
            </Popconfirm>
          )
        }
      </div>
    </div>
  )
}

export default PricingPlanCard