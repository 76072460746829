import {GetRequest, PostRequest} from "../../Requests";
import ApiResponse from "../../../Models/ApiResponse";
import { ApiConstants, ServerURL } from "../../ApiConstants";
import authHeader from "../../auth-header";

const CheckCoupon = async (code: string) => {
    let apiResponse;
    if (ApiConstants.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
        apiResponse = await GetRequest(ServerURL("/shopify/coupon"), {
            code
        }, authHeader())
    } else {
        apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
        return apiResponse.body;
    } else {
        return apiResponse.error
    }
};

function dummyPlans() {
    return {
        data: "OK",
        success: true,
        error: null,
    };
}

export default CheckCoupon
