import React from "react";

class ErrorBoundary extends React.Component<{}, {hasError: boolean, error: any}>{
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    }
  }

  static getDerivedStateFromError(error: any) {
    return {hasError: true}
  }

  //@ts-ignore
  componentDidCatch(error: { toString: () => any; }, errorInfo: any) {
    //@ts-ignore
    this.setState({error: error.toString()});
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return(
        <div className="h-full min-h-[200px] text-center grid place-content-center p-5">
          <div className={"w-fit mx-auto"}>
            <div
              className={"max-w-[350px]"}
            >
              Sorry, Something went wrong
              <br />
              <div
                className={"bg-yellow-200 border border-yellow-700 rounded-md"}
              >
                {this.state.error}
              </div>
              <span
                className={"w-[400px]"}
              >
                Kindly refresh the page, if the problem persists, please contact support via chat icon or email us at abhinav@engagebud.com
              </span>
            </div>
            <div
              className={"bg-blue-700 hover:shadow-none hover:bg-blue-200 hover:scale-105 hover:text-black shadow-xl text-white font-bold cursor-pointer p-1 px-4 rounded-xl"}
              onClick={()=>window.location.reload()}
            >
              Refresh Page
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary