import ContentWrapper from "../../../Components/ContentWrapper";
import {Button, Divider, Space, Typography} from "antd";
import {BadNotif, SuccessNotif} from "../../../Common/Utils/SendNotification";
import RunUserTest from "../../../Common/ApiCall/IbServer/RunUserTest";
import {useState} from "react";
import {BookOutlined, VideoCameraOutlined} from "@ant-design/icons";
import {HelpGuideConstants} from "../../../Common/Constants/HelpGuideLinks";

const {Title, Text} = Typography

const RunTest = () => {
  const [load, setLoad] = useState(false)

  const checkData = async () => {
    setLoad(true)
    const res = await RunUserTest()
    if(res.success) {
      SuccessNotif("Integration Test Successful")
    } else {
      BadNotif(res)
    }
    setLoad(false)
  }

  return(
      <ContentWrapper>
        <Space
          direction={'vertical'}
          style={{
            width: "100%"
          }}
        >
          <div className={"title is-size-4 is-size-3-fullhd"}>
            Run a Successful Test
            <Button
                className={"is-pulled-right button"}
                size={'large'}
                onClick={() => {window.open(HelpGuideConstants.HOW_TO_RUN_TEST, "_blank")}}
            >
              <BookOutlined /> How to run it? I am Confused?
            </Button>
          </div>
          <Divider />
          <Title
              className={"my-5"}
              level={4}
          >
            In the previous step you connected your form / popup with InfluencerBit right? <br /><br />
            Now, enter the email and Instagram username in that form / popup and then click on "Check Data" button to check if integration was successful.
          </Title>
          <Space
              className={"my-5"}
          >
            <button
                disabled={load}
                onClick={checkData}
                className={"uppercase button is-large bg-verdigris text-white is-outlined is-link"}
            >
              {load ? "Checking..." : "Check Data"}
            </button>
            <button
                style={{
                  backgroundColor: 'inherit'
                }}
                onClick={() => {window.open(HelpGuideConstants.HOW_TO_RUN_TEST, "_blank")}}
                className={"button is-danger is-large is-inverted"}
            >
              <u>Not working? Things to check</u>
            </button>
          </Space>
        </Space>
      </ContentWrapper>
  )
}

export default RunTest