import {loadStripe} from "@stripe/stripe-js/pure";
import {useElements, useStripe, Elements, CardElement} from "@stripe/react-stripe-js";
import {useEffect, useState} from "react";
import {Form, message, Radio} from "antd";
import {getRoundedPrice} from "./PricingPlanCard";
import {BadNotif} from "../../../Common/Utils/SendNotification";
import authHeader from "../../../Common/ApiCall/auth-header";
import {ServerURL} from "../../../Common/ApiCall/ApiConstants";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
let stripePromise;
if(process.env.NODE_ENV === 'development') {
  let test_key = process.env.REACT_APP_STRIPE_TEST_KEY
  if(!test_key) {
    console.warn('**Stripe test key environment variable not set**');
    console.warn('**Add an environemnt variable REACT_APP_STRIPE_TEST_KEY**');
    console.warn('**Replace .env.example with .env and **');
  } else {
    //console.log(process.env.REACT_APP_STRIPE_TEST_KEY)
    stripePromise = loadStripe(test_key)
  }
} else {
  let live_key = process.env.REACT_APP_STRIPE_LIVE_KEY
  if (!live_key) {
    console.warn('**Stripe publishable key environment variable not set**');
    console.warn(
        '**Add an environemnt variable REACT_APP_STRIPE_LIVE_KEY**'
    );
    console.warn('**Replace .env.example with .env and **');
  } else {
    stripePromise = loadStripe(live_key)
  }
}

const CARD_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#000',
      fontFamily:
          '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
      '::placeholder': {
        color: '#a0aec0',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

const PaymentForm = (props) => {

  return(
      <>
        <Elements stripe={stripePromise}>
          <PaymentCheckoutForm
              {...props}
          />
        </Elements>
      </>
  )
}

const PaymentCheckoutForm = (
    {
        back,
        plan,
        price_id,
        billingAddress,
        proceed
    }
) => {
  const stripe = useStripe()
  const elements = useElements()
  const [subscribing, setSubscribing] = useState(false)
  const [latestInvoicePaymentIntentStatus, setLatestInvoicePaymentIntentStatus] = useState(null)
  const [latestInvoiceId, setLatestInvoiceId] = useState(null)
  const [totalPrice, setTotalPrice] = useState(getPrice().price_in_cents)
  const [selectedPriceId, setSelectedPriceId] = useState(price_id)
  const [selectedStripePriceId, setSelectedStripePriceId] = useState(getPrice().stripe_price_id)
  const [accountInformation, setAccountInformation] = useState(null)

  function getPrice(selected_price_id = price_id) {
    return (plan.yearly.id === selected_price_id ?
        plan.yearly : plan.monthly)
  }

  useEffect(() => {
    setTotalPrice(getPrice(selectedPriceId).price_in_cents)
    setSelectedStripePriceId(getPrice(selectedPriceId).stripe_price_id)
    setLatestInvoicePaymentIntentStatus(null)
    setLatestInvoiceId(null)
  }, [selectedPriceId])

  function handleCustomerActionRequired(
      {
          subscription,
          invoice,
          priceId,
          paymentMethodId,
          isRetry
      }
  ) {
    console.log(subscription)
    if(subscription && subscription.status === 'active') {
      // subscription is active, no customer actions required.
      setAccountInformation(subscription)
      return { subscription, priceId, paymentMethodId }
    }
    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    console.log(invoice, subscription)
    let paymentIntent;
    if(subscription) paymentIntent = subscription.latest_invoice.payment_intent;
    if(invoice) paymentIntent = invoice.payment_intent;
    if(paymentIntent.status === 'requires_action' ||
        (isRetry === true && paymentIntent.status === 'requires_payment_method')) {
      return stripe
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId
          })
          .then(result => {
            if(result.error) {
              // start code flow to handle updating the payment details
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
              //SendNotification('Card was declined', result.error.message)
              throw result;
            } else {
              if(result.paymentIntent.status === 'succeeded') {
                // There's a risk of the customer closing the window before callback
                // execution. To handle this case, set up a webhook endpoint and
                // listen to invoice.payment_succeeded. This webhook endpoint
                // returns an Invoice.
                return {
                  priceId: priceId,
                  subscription: subscription,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId
                }
              }
            }
          });
    } else {
      setAccountInformation(subscription)
      // No Customer Action needed
      return {subscription, priceId, paymentMethodId}
    }
  }

  async function retryInvoiceWithNewPaymentMethod({paymentMethodId, invoiceId}) {
    const body = {
      payment_method_id: paymentMethodId,
      invoice_id: invoiceId,
      address: billingAddress
    }
    let headers = authHeader()
    headers["Content-Type"] = "application/json"
    return (
      fetch(ServerURL("/payment/retry_invoice"), {
        method: 'post',
        headers,
        body: JSON.stringify(body)
      })
          .then(response => {
            return response.json()
          })
          .then(result => {
            if(result?.errors) {
              throw result.errors[0]
            }
            if(result.error) {
              throw result;
            }
            return result.data;
          })
          .then(result => {
            return {
              invoice: result,
              paymentMethodId,
              priceId: selectedPriceId,
              isRetry: true
            }
          })
          .then(handleCustomerActionRequired)
          .then(onSubscriptionComplete)
          .catch(error => {
            console.log(error)
            if(error?.error) {
              error = error.error
            }
            message.error(error.message)
            setSubscribing(false)
          })
    )
  }

  function handlePaymentMethodRequired(
      {
          subscription,
          paymentMethodId,
          priceId
      }
  ) {
    if(subscription.status === 'active') {
      setAccountInformation(subscription)
      // Subscription Active, no more customer actions required.
      return {subscription, priceId, paymentMethodId}
    } else if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
      setLatestInvoicePaymentIntentStatus(subscription.latest_invoice.payment_intent.status)
      setLatestInvoiceId(subscription.latest_invoice.id)
      throw {
        error: {
          message: "Your card was declined."
        }
      }
    } else {
      return {subscription, priceId, paymentMethodId}
    }
  }

  async function createSubscription({paymentMethodId}) {
    const body = {
      payment_method_id: paymentMethodId,
      price_id: selectedStripePriceId,
      address: billingAddress,
    }
    const headers = authHeader()
    headers['Content-type'] = 'application/json'
    return (
        fetch(ServerURL("/payment/subscription"), {
          method: 'post',
          headers,
          body: JSON.stringify(body)
        })
            .then(response => {
              return response.json()
            })
            .then(result => {
              if(result?.errors) {
                throw result.errors[0]
              }
              if(result.error) {
                throw result;
              }
              return result.data;
            })
            .then(result => {
              console.log(result);
              return {
                subscription: result,
                paymentMethodId,
                priceId: selectedPriceId
              };
            })
            .then(handleCustomerActionRequired)
            .then(handlePaymentMethodRequired)
            .then(onSubscriptionComplete)
            .catch((error) => {
              console.log(error)
              if(error?.error) {
                error = error.error
              }
              message.error(error.message)
              setSubscribing(false)
            })
    )
  }

  function onSubscriptionComplete(result) {
    console.log(result)
    setSubscribing(false)
    setLatestInvoiceId(null)
    setLatestInvoicePaymentIntentStatus(null)
    setAccountInformation(result)
    // Payment Success Page
    proceed()

    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  }

  async function handleSubmit(event) {
    //event.preventDefault()
    setSubscribing(true)

    if(!stripe || !elements) {
      BadNotif(null, "Fill Card Details to Continue")
      setSubscribing(false);return;
    }

    const cardElement = elements.getElement(CardElement)
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    })

    if(error) {
      console.log('[error]', error)
      BadNotif(null, error?.message)
      setSubscribing(false)
    } else {
      console.log('[PaymentMethod]', paymentMethod)
      const paymentMethodId = paymentMethod.id
      if(latestInvoicePaymentIntentStatus === 'requires_payment_method') {
        retryInvoiceWithNewPaymentMethod({
          paymentMethodId,
          invoiceId: latestInvoiceId
        })
      } else {
        createSubscription({
          paymentMethodId: paymentMethod.id
        })
      }
    }
  }

  return(
      <div>
        <div>
          Purchase {plan.plan_name}
        </div>
        <Form
          layout={'vertical'}
          onFinish={handleSubmit}
        >
          <Form.Item>
            <Radio.Group
                defaultValue={selectedPriceId}
                onChange={e => {
                  console.log(e.target.value)
                  setSelectedPriceId(e.target.value)
                }}
            >
              <Radio
                value={plan.yearly.id}
              >
                Yearly Billing ${getRoundedPrice(plan.yearly.price_in_cents/12)}/mo (${getRoundedPrice(plan.yearly.price_in_cents)} total, ${getRoundedPrice(plan.monthly.price_in_cents*12 - plan.yearly.price_in_cents)} discount)
              </Radio>
              <br />
              <Radio
                value={plan.monthly.id}
              >
                Monthly Billing ${getRoundedPrice(plan.monthly.price_in_cents)}/mo
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            Total Amount to be charged today: ${getRoundedPrice(totalPrice)}
          </Form.Item>
          <Form.Item>
            <div className={"field"} id={"card-field"}>
              <div className={"control block"}>
                <div id={"card-element"}>
                  <CardElement
                      options={CARD_OPTIONS}
                  />
                  <div
                    id={"card-element-errors"}
                    role={"alert"}
                  />
                </div>
              </div>
            </div>
            <div className={"is-pulled-right"}>
              Payment Handled by Stripe
            </div>
          </Form.Item>
          <Form.Item>
            <div className="field">
              <div className="control">
                <button
                    className="button is-fullwidth is-danger"
                    type="submit"
                    id="submit-premium"
                    disabled={!stripe || !elements}
                >
                  <div>
                    {subscribing ? 'Subscribing...' : "Subscribe"}
                  </div>
                </button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
  )
}

export default PaymentForm