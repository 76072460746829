import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { BadNotif } from "../../../Common/Utils/SendNotification";
import ContentWrapper from "../../../Components/ContentWrapper";
import {
  Space,
  DatePicker,
  Skeleton,
  message,
  Select,
  Result,
  Typography,
  Tabs, Statistic,
} from "antd";
import { useSelector } from "react-redux";
import { getUser } from "../../../app/features/user/userSlice";
import Graph from "./Graph";
import GetInsights from "../../../Common/ApiCall/IbServer/GetInsights";
import CustomTag from "../../../Common/Models/CustomTag";
import "./graph.sass";

import style from "../TablesData/css/TablesData.module.sass";
import Constants from "../../../Common/Constants/Constants";

const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

type graphItem = {
  key: string;
  value: string;
  minmFollowers?: number;
  description?: string;
  maxDays?: 30;
  tip: string;
  igRequired?: boolean
};

type InsightsObject = {
  description: string;
  id: string;
  name: string;
  period: string;
  title: string;
  values: { value: number; end_time: string }[];
  summary?: {value: number, title: string}
};

type InsightsResponse = {
  ig_id: string;
  insights: InsightsObject[];
};

const instaMetrics: graphItem[] = [
  {
    key: "captures",
    value: "Usernames Collected",
    description: "Total Influencers collected in the time period. Check how many influencers you are capturing every week.",
    tip: "The total number of users who have been added.",
  },
/*  {
    key: "engagement",
    value: "Engagement Rate",
    description: "The average engagement rate for the posts created by influencers where you were tagged / mentioned.",
    tip: "The average engagement rate for Posts created in the specified time period.",
  },*/
  {
    key: "active_collabs",
    value: "Unique Collaborations",
    description: "Total number of unique influencers who mentioned you in the time period.",
    tip: "Total number of unique influencers who mentioned you in the time period",
  },
  {
    key: "total_collabs",
    value: "Total Collaborations",
    description: "Total number of Mentions you received in the time period.",
    tip: "Total number of Mentions you received in the time period.",
  },
  {
    key: "followers",
    value: "Followers Growth",
    description: "Follower's growth for your brand over time.",
    tip: "The total number of followers in the time period",
    igRequired: true
  },
  {
    key: "reach",
    value: "Reach",
    description: "The total number of times that the business account's media objects have been uniquely viewed.",
    tip: "The total number of times that the business account's media objects have been uniquely viewed.",
  },
  {
    key: "profile_views",
    value: "Profile Views",
    description: "Total Users who viewed your profile in the period",
    tip: "The total number of users who have viewed your Brand's profile",
    igRequired: true
  },
];

const initialDateRange = [moment().subtract(7, "d"), moment().endOf("day")];

type GraphDataProps = {
  tags: CustomTag[];
};

export const RangePickerRanges: Record<string, any> = {
  Today: [moment().startOf("day"), moment().endOf("day")],
  Yesterday: [
    moment().subtract(1, 'day').startOf("day"),
    moment().subtract(1, 'day').endOf("day"),
  ],
  "This Week": [moment().startOf("week"), moment()],
  "This Month": [moment().startOf("month"), moment()],
  "Last Month": [
    moment().subtract(1, 'month').startOf("month"),
    moment().subtract(1, 'month').endOf("month"),
  ],
  "This Year": [moment().startOf("year"), moment()],
}

const GraphData = ({ tags }: GraphDataProps) => {
  const [load, setLoad] = useState(false);
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [igMetric, setIgMetric] = useState<graphItem | null>(null)
  const [metric, setMetric] = useState("");
  const [data, setData] = useState([
    { value: 0, end_time: "2021-08-14T07:00:00+0000" },
    { value: 0, end_time: "2021-08-15T07:00:00+0000" },
    { value: 2, end_time: "2021-08-16T07:00:00+0000" },
    { value: 0, end_time: "2021-08-17T07:00:00+0000" },
    { value: 1, end_time: "2021-08-18T07:00:00+0000" },
    { value: 0, end_time: "2021-08-19T07:00:00+0000" },
    { value: 0, end_time: "2021-08-20T07:00:00+0000" },
  ]);
  const [insightResponse, setInsightResponse] = useState<InsightsObject[]>([]);
  const user = useSelector(getUser);
  const [tag, setTag] = useState<string | null>(null)
  const [igAccounts] = useState(user.accounts.instagram);
  const [igAccount, setIgAccount] = useState<any | null>(
    igAccounts.length > 0 ? igAccounts[0] : null
  );

  const loading = () => setLoad(true);
  const loaded = () => setLoad(false);

  function onDateRangeChange(dates: any, dateStrings: any) {
    console.log("From: ", dateStrings[0], "to:", dateStrings[1]);
    const days = dates[1].diff(dates[0], "days") + 1;
    if (days > 364) {
      BadNotif(null, "Date range must be less than one Year");
      return;
    }
    setDateRange(dates);
  }

  const disabledDateRange = (current: any) => {
    return current && current > moment().endOf("day");
  };

  async function getInsights(
    metric_name = metric,
    ig_id = igAccount?.id,
startTime = dateRange[0],
endTime = dateRange[1]
/*    startTime = dateRange[0].tz("UTC").startOf("day").unix(),
    endTime = dateRange[1].tz("UTC").endOf("day").unix()*/
  ) {
    /*    if(process.env.NODE_ENV === 'development') {
      return;
    }*/
    loading();
    console.log(metric_name, ig_id, startTime, endTime, tag, new Date().getTimezoneOffset());
    const res = await GetInsights(
      [metric_name],
      String(ig_id),
      startTime,
      endTime,
        "MAIN",
        tag,
        new Date().getTimezoneOffset()
    );
    if (res.success) {
      const data: InsightsResponse = res.data;
      console.log(data, data.insights.length > 0);
      if (data.insights.length > 0) {
        const insight = data.insights[0].values;
        setInsightResponse(data.insights);
        setData(insight);
      } else {
        setData([]);
        message.warn("Cannot show this metric. Try again later.");
        loaded();
      }
    } else {
      BadNotif(res);
    }
    loaded();
  }

  useEffect(() => {
    if (metric) {
      getInsights();
    }
  }, [metric, dateRange, igAccount, tag]);

  const onIgAccountChange = (id: number) => {
    for (let i = 0; i < igAccounts.length; i++) {
      if (igAccounts[i].id == id) {
        setIgAccount(igAccounts[i]);
      }
    }
  };

  const onMetricChange = (e: any) => {
    setMetric(e);
    setIgMetric(instaMetrics.filter(a => a.key === e)[0])
  };

  useEffect(() => {
    setMetric(instaMetrics[0].key);
    setIgMetric(instaMetrics[0])
  }, []);

  const dataGraphOptions = (
    <Select
      style={{
        width: "200px",
      }}
      size="large"
      defaultValue={instaMetrics[0].value}
      onChange={onMetricChange}
    >
      {instaMetrics.map((graphItem) => (
        <Select.Option
          value={graphItem.key}
          key={graphItem.key}
          title={graphItem.tip ?? graphItem.description}
        >
          {graphItem.value}
        </Select.Option>
      ))}
    </Select>
  );

  const tagOptions = (
      <Select
        size={'large'}
        style={{width: '150px'}}
        placeholder={"Tag"}
        onChange={(e: string) => setTag(e)}
        defaultValue={""}
      >
        <Select.Option
            value={""}
            key={"all_influencerds"}
            title={"All Infuencers"}
        >
          All Influencers
        </Select.Option>
        {tags.map((i) => (
            <Select.Option
                value={i.value}
                key={i.value}
                title={i.value}
            >
              {i.value}
            </Select.Option>
        ))}
      </Select>
  )

  const IgAccountOption = (
    <Select
      size="large"
      style={{
        width: "170px",
      }}
      defaultValue={
        igAccounts && igAccounts.length > 0 ? igAccounts[0].username : ""
      }
      onChange={(e) => onIgAccountChange(Number(e))}
    >
      {igAccounts.map((igAcc: any) => (
        <Select.Option value={igAcc.id} key={igAcc.id} title={igAcc.username}>
          {igAccount.username}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <>
      <Title level={2} className={"mt-3"}>
        <button
          className={`button text-white uppercase font-[600] ${style.titleButton} is-pulled-right`}
          onClick={() => getInsights()}
        >
          check for new data
        </button>
        {instaMetrics
          .filter((i) => i.key === metric)
          .map((i) => (
            <>{i.value}</>
          ))}
      </Title>
      <Text className={"mb-6 is-size-6"}>
        {instaMetrics
          .filter((i) => i.key === metric)
          .map((i) => (
            <>{i.description}</>
          ))}
      </Text>
      <Tabs
        type={"card"}
        className={"mt-4"}
        size={"large"}
        onChange={onMetricChange}
      >
        {instaMetrics.map((i) => (
          <TabPane tab={i.value} key={i.key}>
            <div className={"bg-white p-3"}>
              <Space
                direction={"vertical"}
                size={"large"}
                className={"min-w-full"}
              >
                <div className={"level"}>
                  <div className={"level-left"}>
                    <div className={"level-item"}>
                      {insightResponse[0]?.summary && (
                        <div className={"subtitle capitalized"}>
                          {insightResponse[0].summary.title}: {insightResponse[0].summary.value}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={"level-right"}>
                    <div className={"level-item"}>
                      <Space size={"large"}>
                        {tagOptions}
                        {user.accounts.instagram.length === 0
                          ? "No Accounts Connected"
                          : IgAccountOption}
                        <RangePicker
                          ranges={RangePickerRanges}
                          showTime={{ format: "HH:mm"}}
                          format={Constants.dateRangeFormat}
                          disabledDate={disabledDateRange}
                          onChange={onDateRangeChange}
                          size={"large"}
                          defaultValue={[dateRange[0], dateRange[1]]}
                          className={`${style.rangePicker}`}
                        />
                      </Space>
                    </div>
                  </div>
                </div>
{/*                <Space size={"large"} className={"is-pulled-right"}>
                  {insightResponse[0]?.summary && (
                    <div className={"subtitle is-capitalized"}>
                      {insightResponse[0].summary.title}: {insightResponse[0].summary.value}
                    </div>
                  )}
                  {tagOptions}
                                        {dataGraphOptions}
                  {user.accounts.instagram.length === 0
                    ? "No Accounts Connected"
                    : IgAccountOption}
                  <RangePicker
                    ranges={RangePickerRanges}
                    showTime={{ format: "HH:mm"}}
                    format={Constants.dateRangeFormat}
                    disabledDate={disabledDateRange}
                    onChange={onDateRangeChange}
                    size={"large"}
                    defaultValue={[dateRange[0], dateRange[1]]}
                    className={`${style.rangePicker}`}
                  />
                </Space>*/}
                { igMetric && igMetric.igRequired && igAccounts.length === 0 ? (
                  <Result
                    status={"warning"}
                    title={"No Instagram Accounts connected to show metrics"}
                  />
                ) : !load && insightResponse ? (
                  <div>
                    <Graph
                      data={data}
                      names={metric}
                      insights={insightResponse}
                    />
                  </div>
                ) : (
                  <Skeleton active />
                )}
              </Space>
            </div>
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default GraphData;
