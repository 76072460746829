export type FilterOption = {
  title: string;
  type: "string" | "number" | "boolean" | "datetime" | "select";
  key: string;
  selectOptions?: any[]
};

// To Add: Captured Date, Custom Tags
const FilterOptions: FilterOption[] = [
  {
    title: "Followers",
    type: "number",
    key: "follower_count",
  },
  {
    title: "Following",
    type: "number",
    key: "following_count",
  },
  {
    title: "Posts",
    type: "number",
    key: "posts_count",
  },
  {
    title: "Name",
    type: "string",
    key: "name",
  },
  {
    title: "Instagram Username",
    type: "string",
    key: "username",
  },
  {
    title: "Email",
    type: "string",
    key: "email",
  },
  {
    title: "Bio",
    type: "string",
    key: "bio",
  },
  {
    title: "Private",
    type: "boolean",
    key: "is_private"
  },
  {
    title: "Captured On",
    type: 'datetime',
    key: "created_at"
  },
  {
    title: "Source",
    type: "select",
    key: "referrer",
    selectOptions: ["typeform", "influencer form", "privy", "manual", "refersion", "mention", "klaviyo", "wisepops", "custom_form", "shopify checkout", "instagram dm"]
  },
  {
    title: "Address",
    type: "string",
    key: "address"
  },
  {
    title: "Product",
    type: "string",
    key: "product_link"
  },
  {
    title: "IB Grade",
    type: "select",
    key: "rating",
    selectOptions: ["A", "B", "C", "D", "E"]
  }
];

export default FilterOptions;
