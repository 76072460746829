import React, { Key, useEffect, useState } from "react";
import GetPeopleData from "../../../../Common/ApiCall/IbServer/GetPeopleData";
import {
  BadNotif, SuccessNotif,
} from "../../../../Common/Utils/SendNotification";
import DataTable, {TableColumn} from "react-data-table-component";
import { BeatLoader } from "react-spinners";
import ContentWrapper from "../../../../Components/ContentWrapper";
import $ from "jquery";
import {
  Avatar, Button, Form, Input, Pagination, Popconfirm, Popover, Space, Tooltip, Typography, Checkbox, message
} from "antd";
import moment from "moment";
import { AiOutlineSearch } from "react-icons/ai";
import {
  ArrowUpOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, PlusOutlined,
  ReloadOutlined, TagsOutlined, UploadOutlined, VerticalAlignBottomOutlined
} from "@ant-design/icons";
import { MenuItemDetail } from "../TablesData";
import AddFilters from "../AddFilters";
import FilterOptions from "./filterOptions";
import AddCustomTags from "../../../../Common/ApiCall/IbServer/AddCustomTags";
import CustomTag from "../../../../Common/Models/CustomTag";
import AddUsersManual from "./AddUsersManual";
import RefreshIgUsers from "../../../../Common/ApiCall/IbServer/RefreshIgUsers";
import ArchiveIgUsers from "../../../../Common/ApiCall/IbServer/ArchiveIgUsers";
import { SegmentAnalytics } from "../../../../Components/SegmentAnalytics";
import RemoveUsersCustomTag from "../../../../Common/ApiCall/IbServer/RemoveUsersCustomTag";
import UnarchiveIgUsers from "../../../../Common/ApiCall/IbServer/UnarchiveIgUsers";
import style from "../css/TablesData.module.sass";
import {ServerURL} from "../../../../Common/ApiCall/ApiConstants";
import authHeader from "../../../../Common/ApiCall/auth-header";
import InstaInfluencer from "../../../../Common/Models/InstaInfluencer";
import {DataProps} from "../../Data";
import {setIgUser} from "../../../../app/features/user/userSlice";
import {useDispatch} from "react-redux";
import {unionArrays, diffArrays} from "../../../../Common/HelperFunctions/Commons";

const { Title } = Typography;

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_PAGE_NUMBER = 1;
const PAGE_SIZE_OPTIONS = ["10", "25", "50", "100", "200"];

type PeopleTableProps = {
  table_menu: MenuItemDetail;
  tags: CustomTag[];
  update: Function;
  page?: DataProps["totOptions"],
  customTag?: boolean,
  importBtn?: boolean,
  rtGap?: boolean
};

export type tableFilter = {
  title: string;
  key: string;
  value: string | string[];
  subFilter: string;
  isClosable: boolean;
  value_2?: string;
};

const PeopleTablesData = (
  {
    table_menu, tags, update,
    page = 'people',
    customTag = true,
    importBtn = true,
    rtGap = true
  }: PeopleTableProps) => {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [customTagIds, setCustomtagIds] = useState<string[]>([])
  const [pagination, setPagination] = useState({
    current: DEFAULT_PAGE_NUMBER,
    page_size: DEFAULT_PAGE_SIZE,
  });
  const [customTagForm] = Form.useForm()
  const [sorter, setSorter] = useState<any>({});
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState<tableFilter[]>([]);
  const [manualImport, setManualImport] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [archiveButtons] = useState(page === 'people')
  const [isCustomTag, setIsCT] = useState("");
  const [popLoading, setPopLoading] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [dnld, setDndl] = useState(false)
  const dispatch = useDispatch()
  const loading = () => setLoad(true);
  const loaded = () => setLoad(false);
  const importManually = () => setManualImport(true);
  const stopManualimport = () => setManualImport(false);

  const customStyles = {
    rows: {
      style: {
        // override the row height
        background: "#111A22",
        color: "#ffffff",
        padding: "15px 0px",
      },
    },
    headCells: {
      style: {
        background: "#111A22",
        color: "#ffffff",
        borderBottom: "none",
      },
    },
  };

  const handleTableChange = (
    paginationObj: any,
    filters: {} | undefined,
    sorterFields: any,
    x: any
  ) => {
    if (x?.action === "pagination") {
      setPagination({
        current: paginationObj.page,
        page_size: paginationObj.page_size,
      });
    }
    if (x?.action === "sort") {
      if (sorterFields.order) {
        setSorter({
          [sorterFields.field]: sorterFields.order,
        });
      } else {
        setSorter({});
      }
    }
  };

  const inboundReorderCols = (cols: any, keyToInsert = "Followers") => {
    const key1ToMove = "Engg. Rate", key2ToMove = "Avg. Likes"
    let k1 , k, k2, d1 = [], d2 = []
    for(let i = 0; i < cols.length; i++) {
      switch (cols[i].name) {
        case keyToInsert: k = i;break;
        case key1ToMove: k1 = i; break;
        case key2ToMove: k2 = i; break;
      }
    }
    if(k) {
      if(k2) d2 = cols.splice(k2, 2)
      if(k1) d1 = cols.splice(k1, 2)
    }
    const d = [...d1, ...d2]
    cols.splice(k, 0, ...d)
    return cols
  }

  // @ts-ignore
  const columns: TableColumn<InstaInfluencer> = [
    {
      name: "Ig Username",
      selector: (row: InstaInfluencer) => (
        <div className="min-w-full">
          <Avatar src={row.profile_pic_url} />
          <span
            //@ts-ignore
            onClick={() => dispatch(setIgUser(row.id))}
            className={"mx-2 cursor-pointer"}
          >
            {row.username}
            {row.is_instagram_verified && (
              <CheckOutlined
                className={"mx-2"}
                style={{
                  color: "rgb(131, 58, 180)"
                }}
              />
            )}
          </span>
        </div>
      ),
      grow: 2
    },
    {
      name: "Gift Sent On",
      selector: (row: any) => row.sent_at && moment(row.sent_at).format("Do MMM 'YY HH:mm"),
      omit: page !== 'defaulters',
      grow: 2
    },
    {
      name: "Influencer Grade",
      selector: (row: any) => (
        <span className={"is-size-6 font-semibold"}>
          {row.rating}
        </span>
      ),
      reorder: true
    },
    {
      key: "follower_count",
      name: "Followers",
      selector: (row: any) => row.follower_count,
      sortable: true,
      reorder: true
    },
    {
      key: "following_count",
      name: "Following",
      selector: (row: any) => row.following_count,
      sortable: true,
      reorder: true
    },
    {
      key: "posts_count",
      name: "Posts",
      selector: (row: any) => row.posts_count,
      sortable: true,
      wrap: true
    },
    {
      name: "Influencer Score",
      selector: (row: any) => row.rating_score,
      reorder: true,
      omit: true
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      cell: (r: any, i: any, c: any, id: any) => {
        return <Tooltip title={r.email}>{r.email}</Tooltip>
      },
      grow: 2,
      reorder: true
    },
    {
      name: "Name",
      selector: (row: InstaInfluencer) => [row.first_name ?? "", row.last_name ?? ""].join(" "),

    },
    {
      name: "Address",
      selector: (row: InstaInfluencer) => row.address,
      omit: page !== 'inbound',
      cell: (r: any, i: any, c: any, id: any) => <Tooltip title={r.address}>{r.address}</Tooltip>,
      grow: 2
    },
    {
      name: "Contact",
      selector: (row: any) => row.contact,
      reorder: true,
      cell: (r: any, i: any, c: any, id: any) => {
        return <Tooltip title={r.contact}>{r.contact}</Tooltip>
      },
    },
    {
      name: "Product",
      selector: (row: InstaInfluencer) => row.product_name,
      cell: (r: InstaInfluencer, i: any, c: any, id: any) => {
        return (
          <Tooltip title={r.product_name}>
            {r.product_link ? (
              <Button
                href={r.product_link ?? null}
                target={"_blank"}
                className={"px-1"}
                type={'text'}
                style={{
                  color: "#a5c",
                  overflow: "hidden"
                }}
              >
                {r.product_name}
              </Button>
            ) : r.product_name}
          </Tooltip>
        )
      },
      omit: page !== 'inbound',
      grow: 2
    },
    {
      name: "Ig Name",
      selector: (row: any) => row.name,
      reorder: true,
      cell: (r: any, i: any, c: any, id: any) => {
        return <Tooltip title={r.name}>{r.name}</Tooltip>
      }
    },
    {
      name: "Age",
      selector: (row: any) => row.age,
      reorder: true
    },
    {
      name: 'Gender',
      selector: (row: any) => <p className={"is-capitalized"}>{row.gender}</p>,
      reorder: true
    },
    {
      name: "Captured Date",
      selector: (row: any) => {
        const captured_date = moment(row.created_at)/*.tz("UTC")*/.format("Do MMM 'YY HH:mm");
        return (
            <Tooltip title={captured_date}>
              {captured_date}
            </Tooltip>
        )
      },
      grow: 2,
      reorder: true
    },
    {
      name: "Source",
      selector: (row: any) => <span className="tag is-info uppercase">{row.referrer}</span>,
      grow: 2,
      reorder: true,
      omit: page === 'inbound'
    },
    {
      key: "engagement_rate",
      name: "Engg. Rate",
      selector: (row: any) => row.engagement_rate,
      sortable: true,
      reorder: true
    },
    {
      key: "average_post_day_gap",
      name: `Avg Post Days Gap`,
      selector: (row: any) => row.average_post_day_gap,
      sortable: true,
      grow: 1,
    },
    {
      name: "Bio",
      selector: (row: any) => {
        const bio = row.bio
        return (
            <Tooltip title={bio}>
              {bio}
            </Tooltip>
        )
      },
      grow: 3,
    },
    {
      key: "average_likes",
      name: "Avg. Likes",
      selector: (row: any) => row.average_likes,
      sortable: true,
    },
    {
      key: "average_comments",
      name: "Avg. Comments",
      selector: (row: any) => row.average_comments,
      sortable: true,
    },
    {
      key: "is_private",
      name: "Private",
      selector: (row: any) => row.is_private ? "Yes" : "No"
    },
    {
      name: "Device",
      selector: (row: any) => row.submission_on_mobile !== null ? (row.submission_on_mobile ? "Mobile" : "PC") : "",
      reorder: true
    },
    {
      key: "description",
      name: "Description",
      selector: (row: any) => <Tooltip title={row.description}>{row.description}</Tooltip>,
    }
  ];

  const getData = async (params = {}) => {
    loading();
    const data = await GetPeopleData(params);
    if (data.success) {
      setData(data.data.values);
      setTotalItems(data.data.count);
    } else {
      BadNotif(data);
    }
    loaded();
  };

  useEffect(() => {
    const new_filters: tableFilter[] = []
/*    if(page === 'inbound') {
      new_filters.push({
        title: "Inbound Influencers",
        key: "referrer",
        value: ["influencer form"],
        isClosable: false,
        subFilter: "contains"
      })
    } else {
      new_filters.push({
        title: "All Except Inbound Influencers",
        key: "referrer",
        value: ["influencer form"],
        isClosable: false,
        subFilter: "doesnotcontains"
      })
    }*/
    let ct: string = "";
    switch (table_menu.key) {
      case "all_influencers":
        setFilters(new_filters);
        break;
      case "micro_influencers":
        setFilters([
          ...new_filters,
          {
            title: `Followers in range of 2000 to 10000`,
            key: "follower_count",
            value: "2000",
            value_2: "10000",
            subFilter: "range",
            isClosable: false,
          },
        ]);
        break;
      case "nano_influencers":
        setFilters([...new_filters,
          {
            title: `Followers in range of 1 to 1999`,
            key: "follower_count",
            value: "1",
            value_2: "1999",
            subFilter: "range",
            isClosable: false,
          },
        ]);
        break;
      case "large_influencers":
        setFilters([
          ...new_filters,
          {
            title: `Followers greater than 10000`,
            key: "follower_count",
            value: "10000",
            subFilter: "greaterthan",
            isClosable: false,
          },
        ]);
        break;
      case "archived":
        setFilters(new_filters);
        break;
      default:
        if(page==='custom_tag') {
          setFilters([...new_filters,
            {
              title: `Custom Tag - ${table_menu.key}`,
              key: "custom_tags",
              value: table_menu.key,
              subFilter: "equals",
              isClosable: false,
            },
          ]);
        }
        ct = table_menu.key;
    }
    setIsCT(ct);
    setIsArchived(table_menu.key === "archived");
    setSelectedRowKeys([]);
    setToggleCleared(!toggleCleared);
  }, [table_menu]);

  const refreshData = () => {
    //console.log(sorter, query, pagination, filters);
    getData({
      ...pagination,
      query: query,
      sort: sorter,
      filters: filters,
      archived: table_menu.key === "archived",
      page: page
    });
  };
  const handleRowSelected = React.useCallback((state) => {
    let curr_indexes = [],
      curr_page_selected = [];
    for (let i of state.selectedRows) {
      curr_indexes.push(i?.id);
    }

    curr_page_selected = unionArrays(state.selectedRows, curr_indexes);
    const other_pages_selected = diffArrays(selectedRowKeys, curr_indexes);
    console.log(
      "Selected Rows State: ",
      unionArrays(other_pages_selected, curr_indexes)
    );
    setSelectedRowKeys(unionArrays(other_pages_selected, curr_indexes));
  }, []);

  const newColumnSort = (selectedColumn: any, sortDirection: string) => {
    console.log("selectedCol", selectedColumn, sortDirection);
    if (sortDirection === "asc") {
      setSorter({
        [selectedColumn.key]: "ascend",
      });
    } else if (sortDirection === "desc") {
      setSorter({
        [selectedColumn.key]: "descend",
      });
    } else {
      setSorter({});
    }
  };
  useEffect(() => {
    refreshData();
  }, [sorter, query, pagination, filters]);

  const onChangeQuery = (query: string) => {
    setQuery(query);
  };

  const addFilters = (z: tableFilter) => {
    SegmentAnalytics.track("Add People Filter");
    setFilters([...filters, z]);
  };

  const removeFilter = (z: tableFilter) => {
    setFilters(
      filters.filter(function (item) {
        return item !== z;
      })
    );
  };

  const filtersContent = (
    <AddFilters
      options={page === 'inbound' ? FilterOptions.filter(a => a.key !== 'referrer') : FilterOptions}
      addFilters={addFilters}
      removeFilter={removeFilter}
    />
  );

  const refreshusers = async () => {
    if (selectedRowKeys.length === 0) {
      BadNotif(null, "No Users Selected to Refresh");
      return;
    }
    loading();
    const r = await RefreshIgUsers(selectedRowKeys);
    if (r.success) {
      SuccessNotif(r.data);
      if (selectedRowKeys.length === 1) {
        refreshData();
      } else {
        loaded();
      }
    } else {
      BadNotif(r);
      loaded();
    }
  };

  const archiveUsers = async () => {
    if (selectedRowKeys.length === 0) {
      BadNotif(null, "No users Selected to Archive");
      return;
    }
    loading();
    const res = await ArchiveIgUsers(selectedRowKeys);
    if (res.success) {
      SuccessNotif(res.data);
      update();
      refreshData();
    } else {
      BadNotif(res);
      loaded();
    }
  };

  const unarchiveUsers = async () => {
    if (selectedRowKeys.length === 0) {
      BadNotif(null, "No users Selected to Track");
      return;
    }
    loading();
    const res = await UnarchiveIgUsers(selectedRowKeys);
    if (res.success) {
      SuccessNotif(res.data);
      update();
      refreshData();
    } else {
      BadNotif(res);
      loaded();
    }
  };

  const addInfluencerToCustomTags = async (values: any) => {
    loading();
    if(!values.tag_value && customTagIds.length < 1) {
      message.warn("Select a Custom Tag to Continue");loaded();return;
    }
    if(selectedRowKeys.length < 1) {
      message.warn("Select a Influencer to Continue");loaded();return;
    }
    const res = await AddCustomTags(selectedRowKeys as string[], customTagIds, values.tag_value);
    if (res.success) {
      SuccessNotif("Custom Tags Added Successfully.");
      setCustomtagIds([])
      customTagForm.resetFields()
    } else {
      BadNotif(res);
    }
    refreshData()
    update();
    loaded();
  };

/*  const addCustomTags = async (tag_id?: number) => {
    loading();
    const res = await AddCustomTags(selectedRowKeys as string[], [
      { custom_tag_id: tag_id, custom_tag_name: customTagQuery },
    ]);
    if (res.success) {
      SuccessNotif("Custom Tags Added Successfully.");
    } else {
      BadNotif(res);
    }
    refreshData()
    update();
    loaded();
  };*/

  const rmInfluencersCustomTag = async () => {
    setPopLoading(true);
    let custom_tag_filter = filters.filter((i) => i.key === "custom_tags")[0];
    const r = await RemoveUsersCustomTag(
      selectedRowKeys,
      custom_tag_filter.value
    );
    if (r.success) {
      SuccessNotif("Influencers Removed");
      refreshData();
    } else {
      BadNotif(r);
    }
    update();
    setSelectedRowKeys([]);
    setToggleCleared(!toggleCleared);
    setPopupVisible(false);
    setPopLoading(false);
  };

  function removeEmpty(obj: any) {
    return Object.entries(obj)
        .filter(([_, v]) => v != null || {})
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }

  function download() {
    // setDndl(true)
    let url = new URL(ServerURL("/download_influencers"))
    let values: any = {
      archived: table_menu.key === "archived",
      query: query,
      page: page
    }
    if(filters.length > 0) values["filters"] = filters
    values["auth"] = authHeader().Authorization
    url.search = new URLSearchParams($.param(removeEmpty(values))).toString()
    window.open(url, "_blank")
/*    DownloadInfluencers()
        .then(r => {
          if(r.success) {
            SuccessNotif("Downloading File...")
            window.open(r.data)
          } else {
            BadNotif(r)
          }
          setDndl(false)
        })*/
  }

  const k_sort = Object.keys(sorter)
  const sorterParams = k_sort.length > 0 ? {
    defaultSortFieldId: k_sort[0],
    defaultSortAsc: sorter[k_sort[0]] !== "ascend"
  } : {}

  const tableTitle = () => (
    <div
      className={`w-full space-y-3`}
    >
      <div
        className={`${style.peoplesDataTitleBox}`}
        key={"people-data-title"}
      >
        <Title level={2} className={`is-capitalized text-[#111A22]`}>
          <span className={`${style.peoplesDataTitleSpan}`}>
            {table_menu.title}
          </span>
        </Title>
        <button
          className={`${style.tableTitleButton} ${rtGap ? style.mr8 :""} is-pulled-right uppercase button text-white`}
          onClick={() => refreshData()}
          key={"refresh-profiles"}
        >
          CHECK FOR NEW PROFILES
        </button>
      </div>
      <Space
        size={5}
        style={{ minWidth: "100%" }}
        className={`${style.peoplesDataSearchBox} `}
        key={"filters-menu"}
      >
        <Popover
          key={"people_add_filter"}
          content={filtersContent}
          trigger={"click"}
          destroyTooltipOnHide={true}
        >
          <Button
            icon={<PlusOutlined />}
            size={"large"}
            className={`${style.peoplesDataAddFilterBtn}`}
          >
            Add filter
          </Button>
        </Popover>
        <p className="control has-icons-left">
          <span className="icon is-small is-left">
            <AiOutlineSearch />
          </span>
          <input
            className={`is-pulled-right input ${style.peoplesDataSearch} text-white`}
            placeholder={"Search for a email, phone or handle"}
            value={query}
            type={'search'}
            onChange={(e) => onChangeQuery(e.target.value)}
          />
{/*          {
            query.length > 0 && (
                <span className={"icon is-small is-right"}>
                  <CloseOutlined />
                </span>
              )
          }*/}
        </p>

        <Button
          icon={<UploadOutlined />}
          type={"link"}
          title={"Import"}
          onClick={() => importManually()}
          className={`${style.proplesDataUploadBtn}`}
          hidden={!importBtn}
        >
          IMPORT
        </Button>
      </Space>
      {/* <div className={"columns my-1 is-multiline is-narrow"}>
      {filters.map((f) => (
        <div className={"column p-1 is-narrow"}>
          <span className={"tag is-medium is-info"} key={f.title}>
            <Space>
              {f.title}{" "}
              {f.isClosable && (
                <CloseOutlined onClick={() => removeFilter(f)} />
              )}
            </Space>
          </span>
        </div>
      ))}
    </div> */}
      <div style={{ backgroundColor: "#111A22" }}>
        <div className={"columns p-3 is-multiline is-narrow"}>
          {filters.map((f) => (
            <div className={"column is-narrow"}>
              <span
                className={`tag is-medium ${style.filterContainer}`}
                key={f.title}
              >
                <Space>
                  {f.title}{" "}
                  {f.isClosable && (
                    <CloseOutlined onClick={() => removeFilter(f)} />
                  )}
                </Space>
              </span>
            </div>
          ))}
        </div>

        <div className={"pt-1 pb-2 px-4 text-center"}>
          {isCustomTag!=="" && isCustomTag!=="native" ? (
              <Popconfirm
                  title={`Are you sure you want to remove ${selectedRowKeys.length} Influencers from this Custom Tag?`}
                  onConfirm={() => rmInfluencersCustomTag()}
                  visible={popupVisible}
                  okButtonProps={{ loading: popLoading }}
                  onCancel={() => setPopupVisible(false)}
                  key={"remove-custom-tag"}
              >
                <Button
                    icon={<CloseOutlined />}
                    className={`${style.peoplesDataFuncBtns} uppercase ml-2`}
                    onClick={() => setPopupVisible(true)}
                    type={"link"}
                    hidden={!customTag}
                >
                  Remove Tag
                </Button>
              </Popconfirm>
          ) : null}
          {!isArchived && (
              <Popover
                  trigger={"click"}
                  key={"add-custom-tag"}
                  content={() => (
                      <Form
                        form={customTagForm}
                        onFinish={(vals: any) => addInfluencerToCustomTags(vals)}
                      >
                        <Form.Item
                          className={"mb-0"}
                        >
                          <Checkbox.Group
                            onChange={a => setCustomtagIds(a as string[])}
                          >
                            {tags.filter(a => a.value !== "native").map((t) => (
                              <Checkbox
                                value={t.id}
                              >
                                {t.value}
                              </Checkbox>
                            ))}
                          </Checkbox.Group>
                        </Form.Item>
                        <Form.Item
                          noStyle
                        >
                          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                            <Form.Item
                              className={"mb-1"}
                              name={"tag_value"}
                            >
                              <Input
                                style={{ flex: 'auto' }}
                                placeholder={"Add New Custom Tag Name Here"}
                                title={"Add New Custom Tag Name Here"}
                                allowClear
                              />
                            </Form.Item>
                          </div>
                          <Form.Item
                            className={"mb-0"}
                          >
                            <Button
                              style={{ flex: 'none', padding: '2px', display: 'block', cursor: 'pointer' }}
                              className={"is-text button is-fullwidth is-info"}
                              htmlType={'submit'}
                              //onClick={() => addInfluencerToCustomTags(customTagForm.getFieldsValue())}
                            >
                              Add
                            </Button>
                          </Form.Item>
                        </Form.Item>
{/*                        <Space
                          direction={"vertical"}
                          className={"min-w-full"}
                        >
                          {tags.filter(a => a.value !== "native").map((item: { value: string; id: number }) => (
                              <button
                                  key={item.value}
                                  className={
                                    "button is-fullwidth is-info is-outlined uppercase"
                                  }
                                  value={item.value}
                                  onClick={() => addCustomTags(item.id)}
                              >
                                {item.value}
                              </button>
                          ))}
                          <Input
                              value={customTagQuery}
                              onChange={(e) => setCustomTagQuery(e.target.value)}
                              allowClear
                          />
                          <Button
                              className={"mt-1"}
                              onClick={() => addCustomTags()}
                          >
                            <PlusOutlined /> Add Custom Tag
                          </Button>
                        </Space>*/}
                      </Form>
                  )}
              >
                <Button
                    className={`${style.peoplesDataFuncBtns} ml-2`}
                    icon={<TagsOutlined />}
                    title={"Custom Tags"}
                    type={"link"}
                    hidden={!customTag}
                >
                  CREATE TAG
                </Button>
              </Popover>
          )}
          {archiveButtons && (
            isArchived ? (
                <Popconfirm
                  title={"Are you sure you want to track selected users?"}
                  onConfirm={() => unarchiveUsers()}
                  key={"track-accounts"}
                >
                  <Button
                    icon={<ArrowUpOutlined />}
                    className={`${style.peoplesDataFuncBtns} ml-2`}
                    title={"Track"}
                    type={"link"}
                  >
                    Track Accounts
                  </Button>
                </Popconfirm>
            ) : (
              <Popconfirm
                title={"Are you sure you want to archive selected users?"}
                key={"archive-users"}
                onConfirm={() => archiveUsers()}
              >
                <Button
                  className={`${style.peoplesDataFuncBtns} ml-2`}
                  icon={<DeleteOutlined />}
                  title={"Archive"}
                  type={"link"}
                >
                  ARCHIVE
                </Button>
              </Popconfirm>
            )
          )}
{/*          <Button
            className={`${style.peoplesDataFuncBtns} ml-2 uppercase`}
            onClick={() => sendInfluencerGift()}
            loading={giftLoad}
            disabled={giftLoad}
            icon={<GiftOutlined />}
          >
            send gift
          </Button>*/}
          <Button
              className={`${style.peoplesDataFuncBtns} ml-2`}
              icon={<ReloadOutlined />}
              type={"link"}
              title={"Refresh"}
              onClick={() => refreshusers()}
              key={"refresh-profile"}
          >
            REFRESH PROFILES
          </Button>
          {/*            <Button
            icon={<DownloadOutlined />}
            type={'link'}
            title={"Download CSV"}
          />*/}
          <Button
              className={`${style.peoplesDataFuncBtns} ml-2`}
              icon={<VerticalAlignBottomOutlined />}
              title={"Download Data"}
              loading={dnld}
              disabled={dnld}
              onClick={() => download()}
              key={"download-data"}
          >
            DOWNLOAD DATA
          </Button>
        </div>
        <div
            className={`pl-4 min-w-full text-center text-[#202F3C] `}
            key={"pagination-component"}
        >
          <Pagination
              className={` p-3 text-white `}
              total={totalItems}
              showSizeChanger
              showTotal={() => `Total ${totalItems} Influencers`}
              current={pagination.current}
              defaultCurrent={DEFAULT_PAGE_NUMBER}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              hideOnSinglePage={false}
              pageSize={pagination.page_size}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              onChange={(page, pageSize) =>
                  handleTableChange(
                      { page: page, page_size: pageSize },
                      {},
                      {},
                      { action: "pagination" }
                  )
              }
          />
        </div>
      </div>
    </div>
  );

  return (
    <ContentWrapper>
      <AddUsersManual
        visible={manualImport}
        onClose={stopManualimport}
        option={page}
      />
      {/* <Table
          dataSource={data}
          className={"mt-3 "}
          loading={load}
          columns={people_columns}
          title={() => tableTitle()}
          scroll={{
            x: 1600
          }}
          pagination={false}
          rowSelection={{
            type: 'checkbox',
            ...onRowSelection
          }}
          rowKey={"id"}
          size={'middle'}
          onChange={(i: any, f: any, s: any, x: any) => handleTableChange(i, f, s, x)}
        /> */}
      {tableTitle()}
      <div
        className=""
        key={"data-table"}
      >
        {!load ? (
          <DataTable<InstaInfluencer>
            className={`${style.peopleTable}`}
            //@ts-ignore
            columns={page==='people' ? columns : inboundReorderCols(columns)}
            progressPending={load}
            data={data}
            customStyles={customStyles}
            selectableRows
            responsive
            onSelectedRowsChange={handleRowSelected}
            sortServer={true}
            onSort={(selectedColumn, sortDirection) =>
              newColumnSort(selectedColumn, sortDirection)
            }
            {...sorterParams}
            clearSelectedRows={toggleCleared}
          />
        ) : (
          <div className="text-center mt-6">
            <BeatLoader />
          </div>
        )}
      </div>
    </ContentWrapper>
  );
};

export default PeopleTablesData;
