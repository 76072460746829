import {message} from "antd";
import {useEffect, useState} from "react";
import {Prompt, useHistory} from "react-router-dom";
import {tableFilter} from "../../../Data/TablesData/PeopleTablesData/PeopleTablesData";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import CreateCampaignApi from "../../../../Common/ApiCall/IbServer/Campaign/CreateCampaignApi";
import {DestinationItem} from "../CampaignPosition/CampaignPositionConstants";
import CreateCampaignV2Constants from "./CreateCampaignV2Constants";
import ReactModal from "react-modal";
import {CloseOutlined} from "@ant-design/icons";
import CAMPAIGN_TYPES from "../../../../Common/Constants/CAMPAIGN_TYPES";
import CampaignTypeGameScreen from "./CampaignTypeGameScreen/CampaignTypeGameScreen";
import CampaignName from "./CampaignName";
import CampaignNewPosition from "./CampaignNewPosition";

export interface CampaignDetails {
  campaign_type: string,
  rules: tableFilter[],
  basics?: {
    name: string,
    description?: string
  },
  destination?: DestinationItem['type']
}

type CreateCampaignV2Props = {
  target?: "new" | "widget",
  active: boolean,
  close: Function
}

const INITIAL_VALUES: any = {
  campaign_type: null,
  rules: [],
  basics: {
    name: null,
  },
  destination: "widget"
}

const CreateCampaignV2 = (
  {
    target = "new",
    active = false,
    close
  }: CreateCampaignV2Props
) => {
  const [current, setCurrent] = useState(0)
  const [load, setLoad] = useState(false)
  const [campaignDetails, setCampaignDetails] = useState<CampaignDetails | null>(INITIAL_VALUES)
  let history = useHistory()

  const closeModal = () => {
    close()
  }

  const next = async () => {
    if (current === 0 && !campaignDetails?.campaign_type) {
      message.warn(CreateCampaignV2Constants.first_screen.messages.SELECT_CAMPAIGN);
      return
    }
    if (current == 2 && !campaignDetails?.basics?.name) {
      message.warn(CreateCampaignV2Constants.first_screen.messages.ENTER_CAMPAIGN_NAME);
      return;
    }
    if (current == 1 && !campaignDetails?.destination) {
      message.warn(CreateCampaignV2Constants.first_screen.messages.ENTER_DESTINATION);
      return;
    }
    if (current >= (CreateCampaignV2Constants.first_screen.options.length - 1)) {
      await done()
    } else {
      setCurrent(current + 1)
    }
  }

  const done = async () => {
    if (load) return
    if (!campaignDetails || !campaignDetails.campaign_type || !campaignDetails.destination) return
    if (!campaignDetails.basics?.name) return;
    setLoad(true)
    const res = await CreateCampaignApi(
      campaignDetails.basics.name,
      campaignDetails.campaign_type,
      campaignDetails.destination,
    )
    if (res.success) {
      setCampaignDetails(null)
      closeModal()
      history.replace(`/campaigns/detail/${res.data.id}`)
    } else {
      BadNotif(res)
    }
    setLoad(false)
  }

  //useEffect(() => () => setCampaignDetails(null), [])

  const onChange = (a: any) => {
    setCurrent(a)
  }

  function getCampaignContent() {
    if (campaignDetails?.campaign_type === CAMPAIGN_TYPES.GAMES_TYPE) {
      return (
        <CampaignTypeGameScreen
          details={campaignDetails}
          selected={selectCampaignType}
          back={() => setCampaignDetails(null)}
        />
      )
    }
    switch (current) {
      case 0:
        return (
          <CampaignTypeGameScreen
            details={campaignDetails}
            selected={selectCampaignType}
            back={() => setCampaignDetails(null)}
          />
        )
      case 1:
        return (
          <CampaignNewPosition
            details={campaignDetails}
            update={changeDetails}
            next={next}
            back={() => setCurrent(current - 1)}
          />
        )
      case 2:
        return (
          <CampaignName
            basics={campaignDetails?.basics ?? {name: ""}}
            update={changeBasics}
            next={next}
            back={() => setCurrent(current - 1)}
            campaignType={campaignDetails?.campaign_type}
            campaignPosition={campaignDetails?.destination}
          />
        )
    }
  }

  const changeDetails = (v: any) => {
    let a = {
      ...campaignDetails,
      ...v
    }
    //@ts-ignore
    setCampaignDetails(a)
  }

  const changeBasics = (v: any) => {
    // console.log(v)
    let a: any = {
      ...campaignDetails,
      basics: v
    }
    setCampaignDetails(a)
  }

  const selectCampaignType = (t: string, next = true) => {
    if (t === CAMPAIGN_TYPES.GAMES_TYPE) {
      next = false
    }
    let a: CampaignDetails = {
      campaign_type: t,
      rules: campaignDetails?.rules || [],
      basics: campaignDetails?.basics,
      destination: campaignDetails?.destination || "widget"
    }
    setCampaignDetails(a)
    if (next) setCurrent(current + 1)
  }

  useEffect(() => {
    if (!active) {
      setCurrent(0)
      setCampaignDetails(null)
    }
  }, [active])

  return (
    <ReactModal
      isOpen={active}
      className={"min-w-[700px] shadow-sm rounded-xl"}
      onRequestClose={() => closeModal()}
      overlayClassName={"fixed inset-0 opacity-100 ReactModal__Overlay dark"}
      ariaHideApp={false}
      //@ts-ignore
      destroyOnClose
    >
      <Prompt
        message={"You have unsaved changes. are you sure you want to leave?"}
        when={!!campaignDetails}
      />
      <div className={"p-4 flex hidden"}>
        <span
          className={"flex-none text-xl text-white select-none font-bold w-fit inline"}
        >
          {CreateCampaignV2Constants.first_screen.title}
        </span>
        <span className={"grow"}/>
        <span
          className={"flex-none hover:bg-[#aaaaaa99] rounded-md w-fit p-1 cursor-pointer inline"}
          onClick={() => closeModal()}
        >
          <CloseOutlined/>
        </span>
      </div>
      <div>
        <div
          className={`rounded-md min-h-[200px] px-4 py-2 mx-auto`}
        >
          {getCampaignContent()}
        </div>
      </div>
    </ReactModal>
  )
}

export default CreateCampaignV2