import {Button, Form, Input, message, Select, Switch} from "antd";
import {useEffect, useState} from "react";
import {BadNotif, SuccessNotif} from "../../../../Common/Utils/SendNotification";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";
import ConnectWati from "../../../../Common/ApiCall/IbServer/Integrations/ConnectWati";
import StoreShopifyIntegration from "../../../../Common/Models/Integrations/StoreShopifyIntegration";
import ConnectStoreShopify from "../../../../Common/ApiCall/IbServer/Integrations/ConnectStoreShopify";

type StoreShopifyContentProps = {
  details: StoreShopifyIntegration | null,
} & CommonContentProps

const StoreShopifyContent = (
  {
    close,
    details,
    updateUserFunction
  }: StoreShopifyContentProps
) => {
  const [load, setLoad] = useState(false)
  const [storeShopifyContentForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
  }, [details])

  const integrateShopify = async (values: any) => {
    loading()
    const res = await ConnectStoreShopify(values)
    if(res.success) {
      SuccessNotif(res.data.message)
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
  }

  return(
    <>
      <Form
        hideRequiredMark
        onFinish={integrateShopify}
        form={storeShopifyContentForm}
        initialValues={details ? details : {}}
      >
        <Form.Item
          noStyle
        >
          Sync Customers
          <Form.Item
            name={"sync_customers"}
            valuePropName={"checked"}
          >
            <Switch />
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate
        >
          {
            () => {
              return(
                <>
                  <span
                    className={storeShopifyContentForm.getFieldValue("sync_customers") ? "text-black" : "text-gray-500"}
                  >
                    Invite Customers for Account Activation
                  </span>
                  <Form.Item
                    name={"invite"}
                    valuePropName={"checked"}
                  >
                    <Switch
                      disabled={!(storeShopifyContentForm.getFieldValue("sync_customers"))}
                    />
                  </Form.Item>
                </>
              )
            }
          }
        </Form.Item>
        <Form.Item
          shouldUpdate
        >
          {() => {
            return (
              <Button
                disabled={load}
                loading={load}
                type={'primary'}
                title={"Activate Shopify"}
                htmlType={'submit'}
              >
                {isConnected ? "Update" : "Sync Data"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}

export default StoreShopifyContent