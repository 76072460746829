import {PostRequest} from "../../Requests";
import ApiResponse from "../../../Models/ApiResponse";
import { ApiConstants, ServerURL } from "../../ApiConstants";
import authHeader from "../../auth-header";

const ConnectWebengage = async (
  api_key: string,
  license_code: string,
  datacenter_global: string
) => {
  let apiResponse;
  if (ApiConstants.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(ServerURL("/connect_webengage"), {
      api_key,
      license_code,
      datacenter_global
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    data: "ok",
    success: true,
    errors: []
  }
}

export default ConnectWebengage
