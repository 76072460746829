import {useState} from "react";
import "./Onboarding.css";
import Screen1 from "./Screens/Screen1";
import Screen2 from "./Screens/Screen2";
import Screen3 from "./Screens/Screen3";
import Screen4 from "./Screens/Screen4";
import {useHistory} from "react-router-dom";

const Onboarding = () => {
  const [curr, setCurr] = useState(0);
  const his = useHistory();
  const arr = [1, 2, 3, 4];
  const next = () => setCurr(curr + 1);

  function getCurrentScreen() {
    switch (curr) {
      case 0:
        return <Screen1/>;
      case 1:
        return <Screen2/>;
      case 2:
        return <Screen3/>;
      case 3:
        return <Screen4/>;
      case 4:
        his.replace({pathname: "/quick-start"});
    }
  }

  return (
    <div
      className={"absolute inset-0 z-[11]"}
    >
      <div className={"hero onboarding-main is-fullheight"}>
        <div className={"hero-body onboarding-card mb-6"}>
          <div className="tile is-ancestor">
            <div className="tile is-vertical is-parent">
              <div className="tile is-child">{getCurrentScreen()}</div>
              <div
                className="column is-12 is-child is-offset-1-mobile  has-text-left-desktop text-center-mobile has-text-right-tablet onboardingBtnBox ">
                <button
                  className={
                    "button uppercase bg-verdigris onboarding-next-button text-white"
                  }
                  onClick={() => next()}
                >
                  next
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="column is-4 text-center is-offset-4-desktop is-offset-4-tablet stepsContainer">
          <ul className="steps">
            {arr.map((key, index: number) => {
              return (
                <>
                  <li
                    className={`step-item ${
                      curr === index && "is-completed is-info"
                    } ${curr > index && "is-completed is-success"}`}
                  >
                    <div className="step-marker">
                      {curr > index && (
                        <span className="icon">
                          <i className="fa fa-check"/>
                        </span>
                      )}
                    </div>
                  </li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
