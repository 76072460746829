const ApiConstants = {
  // PROD_SERVER_URL: 'https://dev.influencerbit.com',
  PROD_SERVER_URL: 'https://dev.engagebud.co',
  // PROD_SERVER_URL: 'https://engagebud.dreamboat.ai',
  // DEV_SERVER_URL: "https://5015-2406-7400-61-7bf9-96ae-aa37-2fed-7b4a.ngrok-free.app" ?? 'https://dev.influencerbit.com' ?? "http://localhost:3000",
  DEV_SERVER_URL: 'https://dev.engagebud.co',
  // DEV_SERVER_URL: "http://localhost:3000",
  SERVER_NAMESPACE: '/ib/api/portal/v1',
  V2_SERVER_NAMESPACE: '/ib/api/portal/v2',
  V3_SERVER_NAMESPACE: '/ib/api/portal/v3',
  V4_SERVER_NAMESPACE: '/ib/api/portal/v4',
  V5_SERVER_NAMESPACE: '/ib/api/portal/v5',
  RUN_PROD_SERVER: true,
  LOCALHOST_SERVER: process.env.NODE_ENV === 'development',
  DEV_DOMAIN: 'localhost',
  PROD_DOMAIN: "." + window.location.host,
  AUTH_COOKIE_NAME: process.env.NODE_ENV === 'development' ? 'ebDevMainAppAuthToken' : 'ebMainAppAuthToken',
  AUTH_COOKIE_PATH: "/",
  IS_PRODUCTION: process.env.NODE_ENV !== 'development',
  GAMES_PREVIEW_URL: process.env.NODE_ENV === 'development' ? "http://localhost:5253" :  "https://games.engagebud.com"
}

function ServerURL(url = '') {
  return (ApiConstants.LOCALHOST_SERVER ? ApiConstants.DEV_SERVER_URL : ApiConstants.PROD_SERVER_URL) + ApiConstants.SERVER_NAMESPACE + url
}

const V2ServerURL = (url = "") => {
  return (ApiConstants.LOCALHOST_SERVER ? ApiConstants.DEV_SERVER_URL : ApiConstants.PROD_SERVER_URL) + ApiConstants.V2_SERVER_NAMESPACE + url
}

const V3ServerURL = (url = "") => {
  return (ApiConstants.LOCALHOST_SERVER ? ApiConstants.DEV_SERVER_URL : ApiConstants.PROD_SERVER_URL) + ApiConstants.V3_SERVER_NAMESPACE + url
}

const V4ServerURL = (url = "") => {
  return (ApiConstants.LOCALHOST_SERVER ? ApiConstants.DEV_SERVER_URL : ApiConstants.PROD_SERVER_URL) + ApiConstants.V4_SERVER_NAMESPACE + url
}

const createFinalUrl = (url: string) => {
  return (ApiConstants.LOCALHOST_SERVER ? ApiConstants.DEV_SERVER_URL : ApiConstants.PROD_SERVER_URL) + url
}

const FinalServerUrl = (url = "", version = 4) => {
  return createFinalUrl("/ib/api/portal/v" + version + url)
}

export {FinalServerUrl, createFinalUrl, V4ServerURL, V2ServerURL, V3ServerURL, ServerURL, ApiConstants}
