import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clearUser, getIsAuthed, getUser, getUserAsync, setUser} from "../app/features/user/userSlice";
import Auth from "../Auth";
import {Switch, Route} from 'react-router-dom'
import PrivateRoute from "../Common/CustomRoutes/PrivateRoute";
import Loader from "../Components/Loader";
import {getToken} from "../Common/ApiCall/CookieMethods";
import {useAppDispatch} from "../app/store";
import AuthRoute from "../Common/CustomRoutes/AuthRoute";
import Dashboard from "../Dashboard";
import GeneralRoute from "../Common/CustomRoutes/GeneralRoute";
import ShopifyAuth from "./ShopifyAuth";
import {SegmentAnalytics} from "../Components/SegmentAnalytics";
import {useLocation} from "react-router";
import RegisterSetup from "../Auth/RegisterSetup";
import {hotjar} from "react-hotjar";
import ReactGA from 'react-ga'
import ShopifyPlans from "./ShopifyPlans";
import {ApiConstants} from "../Common/ApiCall/ApiConstants";
import ShopifyLoginRedirect from "./ShopifyLoginRedirect";

declare global {
  interface Window {
    FB: any,
    analytics: SegmentAnalytics.AnalyticsJS,
    Intercom: any,
    ib: any
  }
}

const Home = () => {
  const [init, setInit] = useState(true)
  const user = useSelector(getUser)
  const isAuthed = useSelector(getIsAuthed)
  const location = useLocation()
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()

  async function getUserIfToken() {
    const token = getToken()
    if(token) {
      const {payload} = await appDispatch(getUserAsync());
      if(payload.success) {
        dispatch(setUser(payload.data))
      } else {
        dispatch(clearUser(2))
      }
      setInit(false)
    } else {
      console.log('No Initial Token')
      setInit(false)
    }
  }

  async function initCall() {
    // SegmentAnalytics.load()
    await getUserIfToken()
    if(ApiConstants.IS_PRODUCTION) {
      ReactGA.initialize("UA-154475603-11")
    }
    if(process.env.REACT_APP_HOTJAR_CODE) {
      hotjar.initialize(Number(process.env.REACT_APP_HOTJAR_CODE), 6)
    }
    // IntercomWidget.boot()
  }

  useEffect(() => {
    initCall()
  }, [])

  useEffect(() => {
    //console.log(user?.user?.id, location.pathname)
    SegmentAnalytics.page(user?.user?.id, location.pathname)
    if(ApiConstants.IS_PRODUCTION) {
      ReactGA.pageview(location.pathname + location.search)
    }
  }, [location.search])

  if(init) {
    return <Loader
        fullScreen
        loaderSize={'large'}
    />
  }

  return (
      <Switch>
        <GeneralRoute path={"/login/shopify"}>
          <ShopifyLoginRedirect />
        </GeneralRoute>
        <AuthRoute
          path={"/login"}
        >
          <Auth />
        </AuthRoute>
        <GeneralRoute path={"/register/shopify"}>
          <Auth
              signIn={false}
              shopify={true}
          />
        </GeneralRoute>
        <AuthRoute
            path={"/register"}
        >
          <Auth
            signIn={false}
          />
        </AuthRoute>
        <PrivateRoute
            path="/register-setup"
            component={<RegisterSetup />}
        />
        <GeneralRoute
          path={"/shopify_auth"}
        >
          <ShopifyAuth />
        </GeneralRoute>
        <GeneralRoute path={"/plans_shopify"}>
            <ShopifyPlans />
        </GeneralRoute>
        <PrivateRoute
            component={<Dashboard />}
        />
        <Route
          render={() => {
            return <div>not found 404</div>
          }}
        />
        </Switch>
    )
}

export default Home
