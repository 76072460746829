import {Button, Form, Input, Select} from "antd";
import {useEffect, useState} from "react";
import {BadNotif, SuccessNotif} from "../../../../Common/Utils/SendNotification";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";
import WatiIntegration from "../../../../Common/Models/WatiIntegration";
import ConnectWati from "../../../../Common/ApiCall/IbServer/Integrations/ConnectWati";
import GetWatiTemplates from "../../../../Common/ApiCall/IbServer/Integrations/GetWatiTemplates";

type WatiContentProps = {
  details: WatiIntegration | null,
} & CommonContentProps

type WatiMessageTemplate = {
  id: string,
  elementName: string,
  status: "DRAFT" | "APPROVED"
}

const WatiContent = (
  {
    close,
    details,
    updateUserFunction
  }: WatiContentProps
) => {
  const [lists, setLists] = useState<WatiMessageTemplate[]>([])
  const [load, setLoad] = useState(false)
  const [watiContentForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
  }, [details])

  const getWatiTemplates = async (values: any) => {
    loading()
    const r = await GetWatiTemplates(values)
    if (r.success) {
      setLists(r.data.items)
    } else {
      BadNotif(r)
    }
    loaded()
  }

  const integrateWati = async (values: any) => {
    if (lists.length < 1) {
      getWatiTemplates(values)
      return
    }
    loading()
    console.log(values)
    if (values.unique_code_active_template_name) {
      values.unique_code_active_template_id = lists.filter((a) => a.elementName === values.unique_code_active_template_name)[0].id
    }
    if (values.affiliate_code_active_template_name) {
      values.affiliate_code_active_template_id = lists.filter((a) => a.elementName === values.affiliate_code_active_template_name)[0].id
    }
    if (values.incentivize_post_template_name) {
      values.incentivize_post_template_id = lists.filter((a) => a.elementName === values.incentivize_post_template_name)[0].id
    }
    if (values.incentivize_private_post_template_name) {
      values.incentivize_private_post_template_id = lists.filter((a) => a.elementName === values.incentivize_private_post_template_name)[0].id
    }
    const res = await ConnectWati(values)
    if (res.success) {
      SuccessNotif("Wati is connected now.")
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
  }

  return (
    <>
      <Form
        hideRequiredMark
        onFinish={integrateWati}
        form={watiContentForm}
        initialValues={details ? details : {}}
      >
        <Form.Item
          noStyle
        >
          API ENDPOINT
          <Form.Item
            name={"api_endpoint"}
            rules={[{required: true}]}
          >
            <Input/>
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          ACCESS TOKEN
          <Form.Item
            name={"access_token"}
            rules={[{required: true}]}
          >
            <Input/>
          </Form.Item>
        </Form.Item>
        {
          lists.length > 0 ? (
            <Form.Item
              noStyle
            >
              <div>
                Events - Templates
                <Button
                  className={'is-pulled-right'}
                  type={'link'}
                >
                  Refresh
                </Button>
              </div>
              <Form.Item
                noStyle
              >
                Incentivize to Post
                <Form.Item
                  name={'incentivize_post_template_id'}
                  noStyle
                >
                </Form.Item>
                <Form.Item
                  name={"incentivize_post_template_name"}
                  style={{
                    width: '100%'
                  }}
                  rules={[{
                    required: true,
                    message: "Select a  Template to Continue"
                  }]}
                >
                  <Select
                    placeholder={"Select a Template to Continue"}
                  >
                    {lists.map((l) => (
                      <Select.Option
                        value={l.elementName}
                        title={l.elementName}
                        key={l.id}
                      >
                        {l.elementName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item
                noStyle
              >
                Incentivize to Post (Private)
                <Form.Item
                  name={'incentivize_private_post_template_id'}
                  noStyle
                >
                </Form.Item>
                <Form.Item
                  name={"incentivize_private_post_template_name"}
                  style={{
                    width: '100%'
                  }}
                  rules={[{
                    required: true,
                    message: "Select a  Template to Continue"
                  }]}
                >
                  <Select
                    placeholder={"Select a Template to Continue"}
                  >
                    {lists.map((l) => (
                      <Select.Option
                        value={l.elementName}
                        title={l.elementName}
                        key={l.id}
                      >
                        {l.elementName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item
                noStyle
              >
                Discount Code Active
                <Form.Item
                  name={'affiliate_code_active_template_id'}
                  noStyle
                >
                </Form.Item>
                <Form.Item
                  name={"affiliate_code_active_template_name"}
                  style={{
                    width: '100%'
                  }}
                >
                  <Select
                    placeholder={"Select a Template to Continue"}
                  >
                    {lists.map((l) => (
                      <Select.Option
                        value={l.elementName}
                        title={l.elementName}
                        key={l.id}
                      >
                        {l.elementName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item
                noStyle
              >
                Unique Code Active
                <Form.Item
                  name={'unique_code_active_template_id'}
                  noStyle
                >
                </Form.Item>
                <Form.Item
                  name={"unique_code_active_template_name"}
                  style={{
                    width: '100%'
                  }}
                >
                  <Select
                    placeholder={"Select a Template to Continue"}
                  >
                    {lists.map((l) => (
                      <Select.Option
                        value={l.elementName}
                        title={l.elementName}
                        key={l.id}
                      >
                        {l.elementName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
            </Form.Item>
          ) : null
        }
        <Form.Item
          shouldUpdate
        >
          {() => {
            return (
              <Button
                disabled={load}
                loading={load}
                type={'primary'}
                title={"Activate Wati"}
                htmlType={'submit'}
              >
                {isConnected ? "Update" : "Activate"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}

export default WatiContent