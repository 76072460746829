import React from "react";
import "../Onboarding.css";
import screen2Img from "../../../Common/Images/onboarding/screen2.png";
function Screen2() {
  return (
    <div className="container is-fluid screen1Container">
      <div className="columns is-multiline is-vcentered  is-mobile">
      <div className="column is-12-mobile is-12-tablet is-5-desktop is-offset-2-desktop imageContainer is-hidden-tablet">
          <img loading="eager" src={screen2Img} alt="screen1img" className="image" />
        </div>
        <div className="column is-12-mobile is-12-tablet is-7-desktop  ">
          <h2 className="is-size-2-desktop  is-size-3-tablet text-white has-text-left screen2Header">
            We Embed an Instagram Box where ever you are taking Email / Contact
            Information. <br /> Eg: At popups, order checkout, newsletter, etc
          </h2>
        </div>
        <div className="column is-5-mobile is-12-tablet is-5-desktop imageContainer is-hidden-mobile">
          <img loading="eager" src={screen2Img} alt="screen1img" className="image" />
        </div>
      </div>
      
    </div>
  );
}

export default Screen2;
