import ConnectFB from "./ConnectFB";
import ContentWrapper from "../../../Components/ContentWrapper";

const SocialAccounts = () => {

  return (
      <div
        className={"p-4 bg-titan min-h-full"}
      >
        <div
          className={"bg-cultured border-black border-[1px] border-solid p-3 rounded-md text-black"}
        >
          <ConnectFB
            standalone
          />
        </div>
      </div>
  )
}

export default SocialAccounts