import {Button, Divider, Modal, Typography} from "antd";
import Integration from "../../../Common/Models/Integration";
import {BookOutlined} from "@ant-design/icons";

const {Title} = Typography

type ConnectIntegrationModalProps = {
  integration: Integration | null,
  close: Function,
  myIntegration: any,
  updateUserFunction: Function,
  disconnect: Function
}

const ConnectIntegrationModal = (
    {
      integration,
        close,
        myIntegration,
        updateUserFunction,
        disconnect
    }: ConnectIntegrationModalProps) => {

  const openTutorial = () => {
    if(integration?.tutorial_url) {
      window.open(integration.tutorial_url, "_blank")
    }
  }

  if(integration === null) return null

  return (
      <Modal
        visible
        footer={null}
        width={900}
        onCancel={() => close()}
        destroyOnClose
        centered
      >
        <Title
            level={4}
            className={"mt-2 uppercase"}
        >
          <span>
            {integration.name}
          </span>
          {
            integration.tutorial_url && (
              <Button
                className={"is-pulled-right"}
                size={'large'}
                onClick={() => openTutorial()}
              >
                <BookOutlined /> Stuck? Step by Step Guide
              </Button>
            )
          }
        </Title>
        <Divider />
        <integration.component
            close={close}
            details={myIntegration}
            updateUserFunction={updateUserFunction}
            disconnect={disconnect}
        />
      </Modal>
  )
}

export default ConnectIntegrationModal