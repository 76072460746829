import {Button, Form, Input, message} from "antd";
import {useEffect, useState} from "react";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";
import ConnectMailmodo from "../../../../Common/ApiCall/IbServer/Integrations/ConnectMailmodo";
import GrowlyticsIntegration from "../../../../Common/Models/Integrations/GrowlyticsIntegration";
import ConnectGrowlytics from "../../../../Common/ApiCall/IbServer/Integrations/ConnectGrowlytics";

type GrowlyticsContentProps = {
    details: GrowlyticsIntegration | null,
} & CommonContentProps

const GrowlyticsContent = (
    {
        close,
        details,
        updateUserFunction
    }: GrowlyticsContentProps
) => {
    const [load, setLoad] = useState(false)
    const [growlyticsContentForm] = Form.useForm()
    const [isConnected, setIsConnected] = useState(details?.is_connected)

    const loading = () => setLoad(true)
    const loaded = () => setLoad(false)

    useEffect(() => {
        setIsConnected(details?.is_connected ?? false)
    }, [details])

    const integrateGrowlytics = async (values: any) => {
        loading()
        const res = await ConnectGrowlytics(values.api_key)
        if(res.success) {
            message.success("Growlytics is connected now.")
            updateUserFunction()
        } else {
            BadNotif(res)
        }
        loaded()
    }

    return(
        <>
            <Form
                hideRequiredMark
                onFinish={integrateGrowlytics}
                form={growlyticsContentForm}
                initialValues={{
                    api_key: details?.api_key
                }}
            >
                <Form.Item
                    noStyle
                >
                    API KEY
                    <Form.Item
                        name={"api_key"}
                        rules={[{
                            required: true
                        }]}
                    >
                        <Input />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    shouldUpdate
                >
                    {() => {
                        return (
                            <Button
                                disabled={load}
                                loading={load}
                                type={'primary'}
                                title={"Activate Growlytics"}
                                htmlType={'submit'}
                            >
                                {isConnected ? "Update" : "Activate"}
                            </Button>
                        )
                    }}
                </Form.Item>
            </Form>
        </>
    )
}

export default GrowlyticsContent