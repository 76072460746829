import { GetRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import {ApiConstants, ServerURL, V4ServerURL} from "../ApiConstants";
import authHeader from "../auth-header";

const GetUserDetails = async () => {
  let apiResponse;
  if (ApiConstants.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(V4ServerURL("/me"), null, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    data: {
      "email": "aman",
      "id": 1
    },
    success: true,
    errors: []
  }
}

export default GetUserDetails
