import {Route} from "react-router-dom";
import React from "react";

type GeneralRouteProps = {
  children: React.ReactNode,
  path: string
}

const GeneralRoute = (
    {
        children,
        path
    }: GeneralRouteProps
) => {

  return (
      <Route
        path={path}
        // @ts-ignore
        render={() => (
            children
        )}
      />
  )
}

export default GeneralRoute