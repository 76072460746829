const prod_env_check = process.env.NODE_ENV === 'production';

let actions = {
  load: () => {
    const segment_token = process.env.REACT_APP_SEGMENT_TOKEN
    if(prod_env_check && segment_token) {
      window.analytics.load(segment_token)
      window.analytics.debug(false)
    } else {
      window.analytics.debug()
      // console.log("Cannot Start Segment Prod: ", prod_env_check, " segment token: ", segment_token)
    }
  },
  identify: (id?: string | number) => {
    if(typeof id === 'number') {
      id = String(id)
    }
    if(prod_env_check) {
      window.analytics.identify(id);
    }
  },
  alias: (id: string | number) => {
    if(typeof id === 'number') {
      id = String(id)
    }
    if(prod_env_check) {
      window.analytics.alias(id);
    }
  },
  track: (name: string, props?: any) => {
    if(prod_env_check) {
      window.analytics.track(name, props);
    }
  },
  page: (id?: string | number, name?: string) => {
    if(typeof id === 'number') {
      id = String(id)
    }
    if(prod_env_check) {
      window.analytics.page(name)
    }
  }
};

export let SegmentAnalytics = actions;