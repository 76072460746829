import {Button, Form, Input, message, Typography} from "antd";
import {useEffect, useState} from "react";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import ConnectYotpoApi from "../../../../Common/ApiCall/IbServer/ConnectYotpoApi";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";
import YotpoIntegration from "../../../../Common/Models/YotpoIntegration";

const {Text} = Typography

type YotpoContentProps = CommonContentProps & {
  details: YotpoIntegration | null
}

const YotpoContent = (
  {
    close,
    details,
    updateUserFunction
  }: YotpoContentProps
) => {
  const [load, setLoad] = useState(false)
  const [yotpoContentForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
    details && yotpoContentForm.setFieldsValue({
      api_key: details?.api_key,
      guid: details?.guid
    })
  }, [details])

  const integrateYotpo = async (values: any) => {
    loading()
    const res = await ConnectYotpoApi(values.guid, values.api_key)
    if (res.success) {
      message.success(res.data, 10)
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
  }

  return (
    <>
      <Form
        hideRequiredMark
        onFinish={integrateYotpo}
        form={yotpoContentForm}
        initialValues={isConnected ? {
          api_key: details?.api_key,
          guid: details?.guid
        } : {}}
      >
        <Form.Item
          noStyle
        >
          Api Key
          <Form.Item
            rules={[{
              required: true
            }]}
            name={"api_key"}
          >
            <Input/>
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          GUID
          <Form.Item
            rules={[{
              required: true,
            }]}
            name={"guid"}
          >
            <Input/>
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Yotpo Custom Action and Campaign Name
          <Form.Item>
            <Text copyable>
              Mentioned You on Instagram (Influencerbit)
            </Text>
          </Form.Item>
        </Form.Item>
        <Form.Item
          shouldUpdate
        >
          {() => {
            return (
              <Button
                disabled={load}
                loading={load}
                type={'primary'}
                htmlType={'submit'}
              >
                {isConnected ? "Update" : "Connect"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}

export default YotpoContent