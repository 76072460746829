import {Button, Form, Input, message} from "antd";
import {useEffect, useState} from "react";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";
import MailmodoIntegration from "../../../../Common/Models/MailmodoIntegration";
import ConnectMailmodo from "../../../../Common/ApiCall/IbServer/Integrations/ConnectMailmodo";

type MailmodoContentProps = {
    details: MailmodoIntegration | null,
} & CommonContentProps

const MailmodoContent = (
    {
        close,
        details,
        updateUserFunction
    }: MailmodoContentProps
) => {
    const [load, setLoad] = useState(false)
    const [mailmodoContentForm] = Form.useForm()
    const [isConnected, setIsConnected] = useState(details?.is_connected)

    const loading = () => setLoad(true)
    const loaded = () => setLoad(false)

    useEffect(() => {
        setIsConnected(details?.is_connected ?? false)
    }, [details])

    const integrateMailmodo = async (values: any) => {
        loading()
        const res = await ConnectMailmodo(values.api_key, values.list_name, values.welcome, values.thanks, values.campaign)
        if(res.success) {
            message.success("Mailmodo is connected now.")
            updateUserFunction()
        } else {
            BadNotif(res)
        }
        loaded()
    }

    return(
        <>
            <Form
                hideRequiredMark
                onFinish={integrateMailmodo}
                form={mailmodoContentForm}
                initialValues={{
                    api_key: details?.api_key,
                    list_name: details?.list_name,
                    welcome: details?.new_contact_journey_id,
                    thanks: details?.thanks_journey_id,
                    campaign: details?.campaign_url
                }}
            >
                <Form.Item
                    noStyle
                >
                    API KEY
                    <Form.Item
                        name={"api_key"}
                        rules={[{
                            required: true
                        }]}
                    >
                        <Input
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    noStyle
                >
                    List Name
                    <Form.Item
                        name={"list_name"}
                        rules={[{
                            required: true
                        }]}
                    >
                        <Input
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    noStyle
                >
                    New Contact Journey Id
                    <Form.Item
                        name={"welcome"}
                    >
                        <Input
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    noStyle
                >
                    Mentioned You Journey Id
                    <Form.Item
                        name={"thanks"}
                    >
                        <Input
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    noStyle
                >
                    Campaign URL
                    <Form.Item
                        name={"campaign"}
                    >
                        <Input
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    shouldUpdate
                >
                    {() => {
                        return (
                            <Button
                                disabled={load}
                                loading={load}
                                type={'primary'}
                                title={"Activate Mailmodo"}
                                htmlType={'submit'}
                            >
                                {isConnected ? "Update" : "Activate"}
                            </Button>
                        )
                    }}
                </Form.Item>
            </Form>
        </>
    )
}

export default MailmodoContent