import {Steps} from "antd";
import {StepItem} from "./QuickStart";
import {useState} from "react";
import ConnectPlatform from "./ConnectPlatform";
import {SIDER_WIDTH} from "../Dashboard";
import {useHistory} from "react-router-dom";

type QuickStartStepsProps = {
  steps: StepItem[],
  curr: number,
  change: Function
}

const QuickStartSteps = (
    {
        steps,
        curr,
        change
    }: QuickStartStepsProps
) => {
  const hist = useHistory()

  return(
      <div
          className={"tile is-child bg-white min-h-full pl-3 pt-6"}
          style={{
            position: 'fixed',
            height: '100vh',
            overflow: 'auto',
            left: SIDER_WIDTH,
            width: 250
          }}
      >
        <Steps
            className={"quick-start"}
          direction={'vertical'}
          current={curr}
            onChange={i => change(i)}
        >
          {steps.map((step) => (
              <Steps.Step
                  className={"my-5"}
                title={step.title}
                key={step.title}
              />
          ))}
        </Steps>
      </div>
  )
}

export default QuickStartSteps