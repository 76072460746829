import {Button, Col, Form, InputNumber, message, Row, Select, Typography} from "antd";
import {useEffect, useState} from "react";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import {ApiConstants} from "../../../../Common/ApiCall/ApiConstants";
import TypeformIntegration from "../../../../Common/Models/TypeformIntegration";
import ConnectTypeformApi from "../../../../Common/ApiCall/IbServer/ConnectTypeformApi";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";

const {Text} = Typography

export type TypeformContentProps = {
  details: TypeformIntegration | null,
  firstName?: boolean,
  lastName?: boolean,
} & CommonContentProps

const TypeformContent = (
  {
    close,
    details,
    firstName = false,
    lastName = false,
    updateUserFunction
  }: TypeformContentProps
) => {
  const [load, setLoad] = useState(false)
  const [typeformContentForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
    details && typeformContentForm.setFieldsValue({
      instausername_field: details.instausername_field + 1,
      email_field: details.email_field + 1,
      firstname_field: details?.first_name_field,
      lastname_field: details?.last_name_field
    })
  }, [])

  const integrateTypeform = async (values: any) => {
    if (values.email_field === values.instausername_field) {
      message.error("Email and Insta Username cannot be at same page")
      return
    }
    loading()
    const res = await ConnectTypeformApi(values.email_field - 1, values.instausername_field - 1, values.firstname_field, values.lastname_field)
    if (res.success) {
      message.success("Connected", 10)
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
  }

  useEffect(() => {
  }, [])

  return (
    <>
      <Form
        hideRequiredMark
        onFinish={integrateTypeform}
        form={typeformContentForm}
        initialValues={details ? {
          instausername_field: details.instausername_field + 1,
          email_field: details.email_field + 1,
          firstname_field: details?.first_name_field,
          lastname_field: details?.last_name_field
        } : {
          instausername_field: 2,
          email_field: 1
        }}
      >
        {
          details?.webhook_unique_id && (
            <Form.Item
              noStyle
            >
              Webhook URL
              <Form.Item
                name={"public_key"}
              >
                <Text
                  copyable
                >
                  {`${ApiConstants.PROD_SERVER_URL}/hooks/typeform/${details.webhook_unique_id}`}
                </Text>
              </Form.Item>
            </Form.Item>
          )
        }
        <Row gutter={24}>
          <Col md={{span: 10}}>
            <Form.Item
              noStyle
            >
              Email At Page
              <Form.Item
                rules={[{
                  required: true,
                  type: 'number',
                  min: 0
                }]}
                name={"email_field"}
              >
                <Select>
                  {
                    Array.apply(null, Array(10)).map((_, i) =>
                      <Select.Option
                        value={i + 1}
                      >
                        {i + 1}
                      </Select.Option>
                    )
                  }
                </Select>
              </Form.Item>
            </Form.Item>
          </Col>
          <Col md={{span: 10}}>
            <Form.Item
              noStyle
            >
              Instagram Username At Page
              <Form.Item
                rules={[{
                  required: true,
                  type: 'number'
                }]}
                name={"instausername_field"}
              >
                <Select>
                  {
                    Array.apply(null, Array(10)).map((_, i) =>
                      <Select.Option
                        value={i + 1}
                      >
                        {i + 1}
                      </Select.Option>
                    )
                  }
                </Select>
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          {
            firstName && (
              <Col md={{span: 10}}>
                <Form.Item
                  noStyle
                >
                  First Name
                  <Form.Item
                    name={"firstname_field"}
                  >
                    <InputNumber
                      disabled
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
            )
          }
          {
            lastName && (
              <Col md={{span: 10}}>
                <Form.Item
                  noStyle
                >
                  Last Name
                  <Form.Item
                    name={"lastname_field"}
                  >
                    <InputNumber
                      min={0}
                      disabled
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
            )
          }
        </Row>
        <Form.Item
          shouldUpdate
        >
          {() => {
            return (
              <Button
                disabled={load}
                loading={load}
                type={'primary'}
                title={"Refresh your klaviyo lists"}
                htmlType={'submit'}
              >
                {isConnected ? "Update" : "Activate"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}

export default TypeformContent