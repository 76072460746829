import {PostRequest} from "../../Requests";
import ApiResponse from "../../../Models/ApiResponse";
import {ApiConstants, FinalServerUrl} from "../../ApiConstants";
import authHeader from "../../auth-header";

const ConnectMailmodo = async (
  api_key: string,
  list_name: string,
  welcome?: string,
  thanks?: string,
  campaign?: string
) => {
    let apiResponse;
    if (ApiConstants.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
        apiResponse = await PostRequest(FinalServerUrl("/connect_mailmodo", 1), {
            api_key,
            list_name,
            welcome,
            thanks,
            campaign_url: campaign
        }, authHeader())
    } else {
        apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
        return apiResponse.body;
    } else {
        return apiResponse.error
    }
};

function dummyPlans() {
    return {
        data: "ok",
        success: true,
        errors: []
    }
}

export default ConnectMailmodo
