import {Button, Input, DatePicker, InputNumber, Radio, Select, Space, Checkbox} from "antd";
import {FilterOption} from "../PeopleTablesData/filterOptions";
import {useState} from "react";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import moment from "moment-timezone";
import Constants from "../../../../Common/Constants/Constants";
import {Moment} from "moment/moment";
import {RangePickerRanges} from "../../GraphData/GraphData";

const {RangePicker} = DatePicker

const stringOptions = [
  {
    title: "Equals",
    key: "equals"
  },
  {
    title: "Contains",
    key: "contains"
  },
  {
    title: "Does not contains",
    key: "doesnotcontains"
  },
  {
    title: "Exists",
    key: "exists",
    value: "none"
  },
  {
    title: "Does not Exists",
    key: "doesnotexists",
    value: "none"
  }
]

const numberOptions = [
  {
    title: "Equals",
    key: "equals"
  },
  {
    title: "Less than",
    key: "lessthan"
  },
  {
    title: "Greater Than",
    key: "greaterthan"
  },
  {
    title: "Less than or Equals",
    key: "lessthanorequals"
  },
  {
    title: "Greater than or Equals",
    key: "greaterthanorequals"
  }
]

const booleanOptions = [
  {
    title: "Yes",
    key: "true"
  },
  {
    title: "No",
    key: "false"
  }
]

const selectOptions = [
  {
    title: "Contains",
    key: "contains"
  },
  {
    title: "Does not contains",
    key: "doesnotcontains"
  }
]

const dateOptions: any[] = [{
    title: "Range",
    key: "range"
}]

type AddStringFilterProps = {
  f: FilterOption,
  backToAll: Function,
  setFilter: Function
}

const initialDateRange = [moment().subtract(7, "d"), moment().endOf("day")];

const AddStringFilter = (
    {
        f,
        backToAll,
        setFilter
    }: AddStringFilterProps
) => {
  function getOptions() {
    switch (f.type) {
      case "string": return stringOptions
      case "number": return numberOptions
      case "boolean": return booleanOptions
      case "datetime": return dateOptions
      case "select": return selectOptions
    }
  }

  function getDefaultVale() {
      switch (f.type) {
          case "string": return ""
          case "number": return ""
          case "boolean": return ""
          case "datetime": return initialDateRange
        case "select": return ""
      }
      return ""
  }

  const options = getOptions()
  const [value, setValue] = useState(options ? options[0] : "")
  const [filterValue, setFilterValue] = useState<string | number | string[] | Moment[]>(getDefaultVale())

  const onValueChange = (e: any) => setValue(e.target.value)

  const finalizeFilter = () => {
    if((f.type==='number' || f.type==='string' || f.type==='datetime') && (filterValue!==0 ? !filterValue : true)) {
      if(value?.value == "none") {
      } else {
        BadNotif(null, "Enter a Value to Continue")
        return
      }
    }
    setFilter(value, filterValue, f.type)
    backToAll()
  }

    function onDateRangeChange(dates: any, dateStrings: any) {
        // console.log("From: ", dateStrings[0], "to:", dateStrings[1]);
        const days = dates[1].diff(dates[0], "days") + 1;
        setFilterValue(dates);
    }

    const disabledDateRange = (current: any) => current && current > moment().endOf("day");

    const getFilterMenu = () => {
    switch (f.type) {
      case "number":
          return (
          <InputNumber
              style={{
                width: "170px"
              }}
              //@ts-ignore
              value={filterValue}
              min={-1}
              step={1}
              type={'number'}
              onChange={e => e && setFilterValue(e)}
              placeholder={"Enter Value Here"}
          />)
      case "string": return (
          <Input
              style={{
                width: "170px"
              }}
              //@ts-ignore
              value={filterValue}
              onChange={e => setFilterValue(e.target.value)}
              placeholder={"Enter Value Here"}
              onPressEnter={() => finalizeFilter()}
          />
      )
      case "datetime": return (
          <RangePicker
              ranges={RangePickerRanges}
              showTime={{ format: "HH:mm"}}
              format={Constants.dateRangeFormat}
              disabledDate={disabledDateRange}
              onChange={onDateRangeChange}
              size={"large"}
              defaultValue={[initialDateRange[0], initialDateRange[1]]}
          />
      )
      case "select": return (
          <Checkbox.Group
            options={f.selectOptions ?? []}
            onChange={(c) => setFilterValue(Array.from(c, (e) => String(e)))}
          />
      )
      case "boolean": return null
    }
  }

  return(
      <Radio.Group
          onChange={onValueChange}
          value={value}
          disabled={options ? options?.length < 2 : false}
      >
        <Space
          direction={'vertical'}
          style={{
            maxWidth: "320px"
          }}
        >
          {f.type !== 'select' ? (
              options?.map((o) => (
                  <Radio
                      value={o}
                  >
                    {o.title}
                  </Radio>
              ))
          ) : (
            selectOptions.map((s) => (
              <Radio value={s}>
                {s.title}
              </Radio>
            ))
          )}
          {getFilterMenu()}
          <Button
            onClick={() => finalizeFilter()}
            type={'primary'}
          >
            Add
          </Button>
        </Space>
      </Radio.Group>
  )
}

export default AddStringFilter