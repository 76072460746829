import React, { ChangeEvent, FormEvent, useState } from "react";
import logo from "../Common/Images/auth/navLogo.png";
import bullet from "../Common/Images/auth/diamond.svg";
import info from "../Common/Images/auth/infoVector.svg";
import "./css/Auth.css";
import {useHistory} from "react-router-dom";
import AddBrandDetails from "../Common/ApiCall/IbServer/AddBrandDetails";
import {BadNotif, SuccessNotif} from "../Common/Utils/SendNotification";
import {useSelector} from "react-redux";
import {getUser} from "../app/features/user/userSlice";

function RegisterSetup() {
  const history = useHistory()
  const [load, setLoad] = useState(false)
  const u = useSelector(getUser)
  // console.log(u)
  const [details,setDetails]=useState<{ name: string, store: string, answer: string}>({
    name: u?.shopify?.name,
    store: u?.shopify?.domain,
    answer: ""
  })

  const onHandleChange=(e:ChangeEvent<HTMLInputElement>)=>{
      setDetails({...details,[e.target.name]:e.target.value})
  }

  const onFormSubmit = async (e: FormEvent<HTMLFormElement>)=>{
    e.preventDefault()
    setLoad(true)
    const res = await AddBrandDetails(details.store, details.answer, details.name)
    if(res.success) {
      SuccessNotif("Details Added")
    } else {
      BadNotif(res)
    }
    setLoad(false)
    setTimeout(() => {
      history.push('/')
    }, 1000)
  }

  return (
    <div>
      <nav className="navbar" role="navigation" aria-label="main-navigation">
        <div className="container is-fluid nav-container">
          <div className="navbar-brand">
            <a href="/" className="logo-link ">
              <img src={logo} alt="navLogo" className="image navbarImage" />
            </a>
          </div>
        </div>
      </nav>
      <div className="container is-fluid registerSetupContainer">
        <div className="columns  is-multiline is-mobile">
          <div className="column is-4-desktop is-5-tablet is-12-mobile bgColumn">
            <div className="setupPointsBox p-3">
              <h2 className="has-text-left setupHeader is-size-2-desktop is-size-3-tablet is-size-3-mobile">
                Setup in minutes ...
              </h2>
              <div className="setupPoints extra">
                <img src={bullet} alt="bullet" width="16px" />
                <p className="setupPointsInfo ml-4">
                  See how influencial your Customer Community is on Instagram
                </p>
              </div>
              <div className="setupPoints mt-6">
                <img src={bullet} alt="bullet" width="16px" />
                <p className="setupPointsInfo ml-4">
                  Incentivise them to post about your awesome brand!
                </p>
              </div>
              <div className="setupPoints mt-6">
                <img src={bullet} alt="bullet" width="16px" />
                <p className="setupPointsInfo ml-4">
                  Track & Measure impressions, likes, etc when they post about
                  you.
                </p>
              </div>
              <div className="setupPoints mt-6">
                <img src={bullet} alt="bullet" width="16px" />
                <p className="setupPointsInfo ml-4">
                  Reward them automatically!
                </p>
              </div>
            </div>
          </div>
          <div className="column is-8-desktop is-7-tablet is-12-mobile tellUsCol">
            <h3 className="tellUsColHeader text-black ">
              Tell us more about you
            </h3>
            <div className="tellUsFormContainer">
              <form onSubmit={(e)=>onFormSubmit(e)}>
                <label htmlFor="name" className="text-black tellUsLabel ">
                  Your Name
                </label>
                <div className="column is-5-desktop is-12-tablet is-12-mobile">
                  <input
                    className="input tellUsInput text-black"
                    type="text"
                    name="name"
                    required
                    onChange={onHandleChange}
                    value={details.name}
                  />
                </div>
                <label htmlFor="name" className="text-black tellUsLabel ">
                  Store Url
                </label>
                <div className="column is-5-desktop is-12-tablet is-12-mobile">
                  <input
                    className="input tellUsInput text-black"
                    type="text"
                    name="store"
                    required
                    onChange={onHandleChange}
                    value={details.store}
                  />
                </div>
                
                <div className="setupPoints mt-6">
{/*                  <img src={info} alt="bullet" width="16px" />*/}
                  <p className="tellUsPointsInfo is-size-5 ml-3">
                    Tried Influencer Marketing for your Brand?
                  </p>
                  
                  
                </div>
                <div className="radioContainer mt-4">
                    <div className="radioControl">
                      <label className="radio">
                        <input type="radio"   name="answer" required onChange={onHandleChange}  value="No Idea what it is"/>
                        <span className="radioText ml-3">No Idea what it is</span>
                      </label>
                      <br />
                      <label className="radio mt-2">
                        <input type="radio" name="answer" required onChange={onHandleChange} value="Have tried a Little"/>
                        <span className="radioText ml-3">Have tried a Little</span>
                      </label>
                      <br />
                      <label className="radio mt-2">
                        <input type="radio"  name="answer" required onChange={onHandleChange} value="Am a pro"/>
                        <span className="radioText ml-3">Am a pro</span>
                      </label>
                    </div>
                  </div>
                  <div className="has-text-left mt-6">
                    <button className="button bg-verdigris tellUsSubmitBtn text-white">
                      {load ? "Wait..." : "NEXT"}
                    </button>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterSetup;
