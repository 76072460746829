import ContentWrapper from "../../../Components/ContentWrapper";
import {Button, Divider, Typography} from "antd";
import Integrations from "../../Integrations";
import {BookOutlined} from "@ant-design/icons";
import {HelpGuideConstants} from "../../../Common/Constants/HelpGuideLinks";

const {Text} = Typography

const ConnectMarketingTools = () => {

  return (
    <ContentWrapper>
      <div className={"title is-size-4 is-size-3-fullhd"}>
        Sync Data to Your Marketing Tools
        <Button
          className={"is-pulled-right button"}
          size={'large'}
          onClick={() => {
            window.open(HelpGuideConstants.SYNC_DATA_TO_MARKETING_TOOLS, "_blank")
          }}
        >
          <BookOutlined/> I didn't get this. Help Guide please!
        </Button>
      </div>
      <Divider/>
      <Text>
        Pass InfluencerBit data to your email marketing system and more and streamline your influencer marketing
      </Text>
      <Integrations
        cardDetails={'small'}
        someOnly
        noTitle
        onlyMarketing
      />
    </ContentWrapper>
  )
}

export default ConnectMarketingTools