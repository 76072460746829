import {Button, Collapse, Form, Input, InputNumber, message, Select} from "antd";
import {useEffect, useState} from "react";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";
import NetcoreIntegration from "../../../../Common/Models/Integrations/NetcoreIntegration";
import ConnectNetcore from "../../../../Common/ApiCall/IbServer/Integrations/ConnectNetcore";

type NetcoreContentProps = {
  details: NetcoreIntegration | null,
} & CommonContentProps

const NetcoreIPCs = [
  {
    name: "US",
    value: "api2"
  },
  {
    name: "EU",
    value: "apieu2"
  },
  {
    name: "India",
    value: "apiin2"
  }
]

const NetcoreContent = (
    {
      close,
      details,
      updateUserFunction
    }: NetcoreContentProps
) => {
  const [load, setLoad] = useState(false)
  const [privyContentForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
  }, [details])

  const integrateNetcore = async (values: any) => {
    loading()
    const res = await ConnectNetcore(values)
    if(res.success) {
      message.success("Netcore is connected now. You can start collecting data.")
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
  }

  const formProps = {
    rules: [{required: true}],
    className: "my-1"
  }

  return(
      <>
        <Form
          className={"space-y-3"}
            hideRequiredMark
            onFinish={integrateNetcore}
            form={privyContentForm}
            initialValues={ details ?? {}}
        >
          <div>
            <span>
              API KEY
            </span>
            <Form.Item
                name={"netcorecloud_api_key"}
                {...formProps}
            >
              <Input
                placeholder={"API KEY"}
              />
            </Form.Item>
          </div>

          <Collapse
            defaultActiveKey={['events', 'email']}
          >
            <Collapse.Panel
                header={"Events"}
                key={"events"}
            >
              <div>
                <span>
                  IPC
                </span>
                <Form.Item
                  name={"ipc"}
                  {...formProps}
                >
                  <Select>
                    {
                      NetcoreIPCs.map((ipc, index) => (
                        <Select.Option
                          key={index}
                          value={ipc.value}
                        >
                          {ipc.name}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </div>

              <div>
                <span>
                  Asset ID
                </span>
                <Form.Item
                  name={"asset_id"}
                  {...formProps}
                >
                  <Input
                    placeholder={"Asset Id"}
                  />
                </Form.Item>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
                header={"Email"}
                key={"email"}
            >
              <div>
                <span>
                  From Email
                </span>
                <Form.Item
                  name={"netcorecloud_from_email"}
                  {...formProps}
                >
                  <Input
                    placeholder={"From Email"}
                  />
                </Form.Item>
              </div>

              <Form.Item
                noStyle
              >
                From Name
                <Form.Item
                  name={"netcorecloud_from_name"}
                  {...formProps}
                >
                  <Input
                    placeholder={"From Name"}
                  />
                </Form.Item>
              </Form.Item>

              <div>
                Subject
                <Form.Item
                  name={"netcorecloud_mail_subject"}
                  {...formProps}
                >
                  <Input
                    placeholder={"Mail Subject"}
                  />
                </Form.Item>
              </div>

              <div>
                Template Id
                <Form.Item
                  name={"netcorecloud_mail_template_id"}
                  {...formProps}
                >
                  <Input
                    placeholder={"Template Id"}
                  />
                </Form.Item>
              </div>

              <div>
            <span>
              Send Mail After Minutes
            </span>
                <Form.Item
                  name={"netcorecloud_mail_time_after_minutes"}
                  {...formProps}
                >
                  <InputNumber
                    placeholder={"Send Mail After Minutes"}
                    className={"min-w-[200px]"}
                    style={{minWidth: "200px"}}
                  />
                </Form.Item>
              </div>

            </Collapse.Panel>
          </Collapse>

          <Form.Item
              shouldUpdate
          >
            {() => {
              return (
                <Button
                      disabled={load}
                      loading={load}
                      type={'primary'}
                      htmlType={'submit'}
                  >
                    {isConnected ? "Update" : "Activate"}
                  </Button>
              )
            }}
          </Form.Item>
        </Form>
      </>
  )
}

export default NetcoreContent