import {Button, Form, Input, message, Typography} from "antd";
import {useState} from "react";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import GorgiasIntegration from "../../../../Common/Models/GorgiasIntegration";
import ConnectGorgiasApi from "../../../../Common/ApiCall/IbServer/ConnectGorgiasApi";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";

type GorgiasContentProps = {
  close: Function,
  details: GorgiasIntegration | null
} & CommonContentProps

const GorgiasContent = (
  {
    close,
    details,
    updateUserFunction
  }: GorgiasContentProps
) => {
  const [load, setLoad] = useState(false)
  const [gorgiasContentForm] = Form.useForm()
  const isConnected = details?.is_connected

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  const integrateTypeform = async (values: any) => {
    loading()
    const res = await ConnectGorgiasApi(values.username, values.api_key, values.base_url)
    if (res.success) {
      message.success(res.data, 10)
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
    close()
  }

  return (
    <>
      <Form
        hideRequiredMark
        onFinish={integrateTypeform}
        form={gorgiasContentForm}
        initialValues={isConnected ? {
          username: details?.username,
          api_key: details?.api_key,
          base_url: details?.base_url
        } : {}}
      >
        <Form.Item
          noStyle
        >
          Username (Email Address)
          <Form.Item
            rules={[{
              required: true
            }]}
            name={"username"}
          >
            <Input/>
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Password (API KEY)
          <Form.Item
            rules={[{
              required: true,
            }]}
            name={"api_key"}
          >
            <Input/>
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Base URL
          <Form.Item
            rules={[{
              required: true,
            }]}
            name={"base_url"}
          >
            <Input/>
          </Form.Item>
        </Form.Item>
        <Form.Item
          shouldUpdate
        >
          {() => {
            return (
              <Button
                disabled={load}
                loading={load}
                type={'primary'}
                title={"Refresh your klaviyo lists"}
                htmlType={'submit'}
              >
                {isConnected ? "Update" : "Activate"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}

export default GorgiasContent