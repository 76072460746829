import {Button, Form, Input, Select} from "antd";
import {useEffect, useState} from "react";
import {BadNotif, SuccessNotif} from "../../../../Common/Utils/SendNotification";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";
import MoEngageIntegration from "../../../../Common/Models/MoEngageIntegration";
import ConnectMoengage from "../../../../Common/ApiCall/IbServer/Integrations/ConnectMoengage";

type MoEngageContentProps = {
  details: MoEngageIntegration | null,
} & CommonContentProps

const DATA_VERSIONS = [
  {
    title: "dashboard-01",
    key: "01"
  },
  {
    title: "dashboard-02",
    key: "02"
  },
  {
    title: "dashboard-03",
    key: "03"
  }
]

const MoEngageContent = (
  {
    close,
    details,
    updateUserFunction
  }: MoEngageContentProps
) => {
  const [load, setLoad] = useState(false)
  const [moContentForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
  }, [details])

  const integrateMoengage = async (values: any) => {
    loading()
    const res = await ConnectMoengage(values.api_key, values.app_id)
    if(res.success) {
      SuccessNotif("MoEngage is connected now.")
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
  }

  return(
    <>
      <Form
        hideRequiredMark
        onFinish={integrateMoengage}
        form={moContentForm}
        initialValues={details ?? {}}
      >
        <Form.Item
          noStyle
        >
          APP ID
          <Form.Item
            name={"app_id"}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          API KEY
          <Form.Item
            name={"api_key"}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Data Center (Check Dashboard URL)
          <Form.Item
            name={"data_version"}
            rules={[{required: true}]}
          >
            <Select>
              {
                DATA_VERSIONS.map((d) => (
                  <Select.Option
                    value={d.key}
                    key={d.key}
                  >
                    {d.title}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item
          shouldUpdate
        >
          {() => {
            return (
              <Button
                disabled={load}
                loading={load}
                type={'primary'}
                title={"Activate MoEngage"}
                htmlType={'submit'}
              >
                {isConnected ? "Update" : "Activate"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}

export default MoEngageContent