import PeopleTablesData from "./PeopleTablesData";
import MediaTablesData from "./MediaTablesData";
import {Menu, Button, Popconfirm, Skeleton, Table} from "antd";
import React, {CSSProperties, useEffect, useState} from "react";
import tableMenus from "./TableMenuData";
import CustomTag from "../../../Common/Models/CustomTag";
import ShowCustomTag from "../../../Common/ApiCall/IbServer/ShowCustomTag";
import {BadNotif, SuccessNotif} from "../../../Common/Utils/SendNotification";
import HideCustomTag from "../../../Common/ApiCall/IbServer/HideCustomTag";
import {CloseOutlined, MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import {DataProps} from "../Data";
import style from './css/TablesData.module.sass'

const MEDIA_MENU_PREPEND = "-media"

export type MenuItemDetail = {
  title: string,
  key: string,
  description?: string
}

export type MenuGroupItem = {
  [key: string]: {
    title: string,
    key: string,
    items: MenuItemDetail[]
  }
}

type TableDataProps = {
  tags: CustomTag[],
  updateTags: Function,
  option: DataProps["totOptions"],
  counts: any,
  update: Function,
  showCustomTags: boolean,
  basic: boolean,
  collap?: boolean,
  hideSider: boolean
}

const TablesData = (
  {
    tags,
    updateTags,
    option,
    counts,
    update,
    showCustomTags,
    basic,
    collap = false,
    hideSider
  }: TableDataProps
) => {
  const [collapsed, setCollapsed] = useState(hideSider || collap)
  const [menuKey, setMenuKey] = useState<string[]>([])
  const [selectedMenu, setSelectedMenu] = useState<MenuItemDetail | null>(null)
  const [menuParent, setMenuParent] = useState<string>('')

  const toggleCollapsed = () => setCollapsed(!collapsed)

  const getItemParent = (key: string) => {
    if(option && tableMenus[option]) {
      console.log("menu", tableMenus, tableMenus[option])
      let m = tableMenus[option]
      for(let j=0 ; j < m.items.length ; j++ ) {
        if(m.items[j].key === key) {
          return {
            parent_key: m.key,
            child_item: m.items[j]
          }
        }
      }
    }
    return {
      parent_key: null,
      child_item: null
    }
  }

  const onMenuItemClick = (i: any, media: boolean = false) => {
    setMenuKey([i.key])
    let res = getItemParent(i.key)
    if(res.parent_key !== null) {
      setMenuParent(res.parent_key)
      setSelectedMenu(res.child_item)
    } else {
      let k = i.key
      if(media) k = k.replace(MEDIA_MENU_PREPEND, "")
      let d = media ? `Media of Users with tag ${k}` : `Users in Tag ${k}`
      setMenuParent(media ? "media" : "people")
      setSelectedMenu({
        title: k,
        key: k,
        description: d
      })
    }
  }

  function getTableComponent() {
    if(selectedMenu === null) return <Skeleton active />
    switch (menuParent) {
      case "inbound":
      case "rejected":
      case "finalized":
      case "people": return (
        <PeopleTablesData
          tags={tags}
          table_menu={selectedMenu}
          update={update}
          rtGap={hideSider ? false : !(["rejected"].includes(option ?? ""))}
          page={option}
        />
      )
      case 'custom_tag_media':
      case "gift_media":
      case "affiliate_media":
      case "media": return (
        <MediaTablesData
          tags={tags}
          table_menu={selectedMenu}
          update={update}
          rtGap={!hideSider}
          page={option}
        />
      )
      default: return <Table loading />
    }
  }

  const addTagMenu = async (tag_id: number) => {
    const res = await ShowCustomTag(tag_id)
    if(res.success) {
      SuccessNotif("Tag Added to Menu")
    } else {
      BadNotif(res)
    }
    updateTags()
  }

  const removeTagMenu = async (tag_id: number) => {
    const res = await HideCustomTag(tag_id)
    if(res.success) {
      SuccessNotif("Tag Removed")
      onMenuItemClick("all_influencers", false)
    } else {
      BadNotif(res)
    }
    updateTags()
  }

  useEffect(() => {
    switch (option) {
      case "affiliate_media":
      case "gift_media":
      case "media": onMenuItemClick(tableMenus["media"].items[0], true);break;
      case 'custom_tag_media': onMenuItemClick({key: "native"}, true);break;
      case "rejected":
      case "inbound":
      case "people": onMenuItemClick(tableMenus["people"].items[0]);break;
      case "custom_tag": onMenuItemClick({key: "native"});break;
      default: onMenuItemClick(tableMenus["people"].items[0]);break;
    }
  }, [option])

  if(menuKey.length === 0) {
    return (
      <Skeleton
        active
      />
    )
  }

  const menuStyle: CSSProperties = {
    minHeight: collapsed ? "" : "100%",
    position: 'fixed',
    zIndex: 9,
    maxWidth: "235px",
    right: 10,
  }

  return (
      <>
        <div
            className={"columns is-mobile min-h-full is-justify-content-space-between"}
        >
          <div
            className={"column is-10 min-f-full"}
            style={{
              flexGrow: collapsed ? 1 : 0
            }}
          >
            {getTableComponent()}
          </div>
          <div
            className={`column is-narrow ${!collapsed && "min-h-full"} ${collapsed ? "" : "bg-white"}`}
            style={menuStyle}
            hidden={hideSider}
          >
            <Button
              icon={collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
              onClick={() => toggleCollapsed()}
              title={collapsed ? "Expand" : "Collapse"}
            />
            <Menu
              mode={'inline'}
              onSelect={(a) => onMenuItemClick(a, false)}
              defaultSelectedKeys={menuKey}
              selectedKeys={menuKey}
              key={"people"}
              hidden={collapsed || ["media", "custom_tag_media", "affiliate_media", "gift_media"].includes(option ?? "")}
              style={menuStyle}
            >
              <Menu.ItemGroup
                className={`${style.rightSiderHeader}`}
                title={tableMenus["people"].title}
                key={tableMenus["people"].key}
              >
                {basic && tableMenus["people"].items.map((menuChild) => (
                  <Menu.Item
                    title={menuChild.description}
                    key={menuChild.key}
                  >
                      <span>
                        {menuChild.title}
                        <span style={{float: 'right'}}>
                          {counts["people"] && (counts["people"][menuChild.key])}
                          {/*counts["people"] && (counts["people"][menuChild.key] - counts["inbound"][menuChild.key])*/}
                        </span>
                      </span>
                  </Menu.Item>
                ))}
                {showCustomTags && tags.map(it => (
                  <Menu.Item
                    title={it.description ?? it.value}
                    key={`${it.value}`}
                  >
                    {
                      !it.static && (
                        <Popconfirm
                          title={"Are you Sure you want to delete this tag?"}
                          onConfirm={() => removeTagMenu(it.id)}
                        >
                          <Button
                            size={'small'}
                            icon={<CloseOutlined/>}
                          />
                        </Popconfirm>
                      )
                    }
                    <span
                      className={`${it.static ? "is-capitalized" : "pl-2 "}`}
                    >
                      {it.value}
                      <span
                        className={"is-pulled-right"}
                      >
                        {counts["people"] && (counts["people"][it.value] - counts["inbound"][it.value])}
                      </span>
                    </span>
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            </Menu>
            <Menu
              mode={'inline'}
              selectedKeys={menuKey}
              onSelect={(a) => onMenuItemClick(a, true)}
              defaultSelectedKeys={menuKey}
              key={"mentions"}
              hidden={collapsed || !["media", "gift_media", "custom_tag_media", "affiliate_media"].includes(option ?? "")}
              style={menuStyle}
            >
              <Menu.ItemGroup
                title={tableMenus["media"].title}
                key={tableMenus["media"].key}
                className={`${style.rightSiderHeader}`}
              >
                {basic && tableMenus["media"].items.map((menuChild) => (
                  <Menu.Item
                    title={menuChild.title}
                    key={menuChild.key}
                  >
                    {menuChild.title}
                    <span className={"is-pulled-right"}>
                      {counts["media"] && counts["media"][menuChild.key]}
                    </span>
                  </Menu.Item>
                ))}
                {showCustomTags && tags.map(it => (
                  <Menu.Item
                    title={it.description ?? it.value}
                    key={`${it.value + MEDIA_MENU_PREPEND}`}
                  >
                      <span className={`${it.static && "uppercase"}`}>
                        {it.value}
                        <span className={"is-pulled-right"}>
                          {counts["media"] && counts["media"][it.value] }
                        </span>
                      </span>
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            </Menu>
     
          </div>
        </div>
      </>
  )
}

export default TablesData