import {Redirect, Route} from "react-router-dom";
import {useSelector} from "react-redux";
import {getIsAuthed} from "../../app/features/user/userSlice";

type AuthRouteProps = {
  children: React.ReactNode,
  path: string
}

const AuthRoute = (
    {
        children,
        path
    }: AuthRouteProps
) => {
  const isAuthed = useSelector(getIsAuthed)

  return (
      <Route
          path={path}
          //@ts-ignore
        render={() => isAuthed ? (
            <Redirect to={"/"+window.location.search} />
        ) : (
            children
        )}
      />
  )
}

export default AuthRoute