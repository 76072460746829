import React, {ReactNode} from 'react'

type CustomButtonProps = {
  content: ReactNode,
  type?: "light" | "dark" | "light-reverse" | "dark-reverse" | "danger" | "warning",
  size?: "small" | "large",
  onClickAction: Function,
  disabled?: boolean,
  loading?: boolean,
  loadingContent?: ReactNode,
  zoomOnHover?: boolean,
  hidden?: boolean,
  inline?: boolean
}

const CustomButton = (
  {
    content,
    type,
    size,
    onClickAction,
    loadingContent = "Loading...",
    disabled = false,
    loading = false,
    zoomOnHover = false,
    hidden = false,
    inline = false
  }: CustomButtonProps) => {

  const getColorStyles = (): string => {
    switch (type) {
      case "light": {
        return "bg-[#423C4E] text-white border-[#423C4E] hover:text-[#423C4E] hover:bg-transparent"
      }
      case "dark": {
        return "bg-black text-white border-black hover:text-black hover:bg-transparent"
      }
      case "light-reverse": {
        return "bg-white text-[#423C4E] border-[#423C4E] hover:text-white hover:bg-[#423C4E]"
      }
      case "dark-reverse": {
        return "bg-white text-black border-black hover:text-white hover:bg-black"
      }
      case "danger": {
        return "bg-red-800 text-white border-red-800 hover:text-red-800 hover:bg-white"
      }
      case "warning": {
        return "bg-yellow-600 text-white border-yellow-600 hover:text-yellow-600 hover:bg-white"
      }
      default: {
        return "bg-[#2A2535] text-white border-[#2A2535] hover:bg-white hover:text-[#2A2535]"
      }
    }
  }

  const getSizeStyles = (): string => {
    switch (size) {
      case "small": {
        return "px-3 py-2 text-xs"
      }
      case "large": {
        return "px-5 py-3 text-lg"
      }
      default: {
        return "px-4 py-2 text-sm"
      }
    }
  }

  const onClick = () => {
    if(disabled || loading) return
    onClickAction()
  }

  return (
    <div
      onClick={() => onClick()}
      hidden={hidden}
      className={`text-center select-none w-fit uppercase rounded-md font-bold cursor-pointer transition 
      duration-200 border ${inline?"inline-block":""} ${getSizeStyles()} ${getColorStyles()} ${zoomOnHover ? "hover:scale-105" : ""}`}
    >
      {
        loading ? loadingContent : content
      }
    </div>
  )
}

export default CustomButton