// To Add: Captured Date, Custom Tags
import {FilterOption} from "../PeopleTablesData/filterOptions";

const FilterOptions: FilterOption[] = [
  {
    title: "Likes",
    type: 'number',
    key: "likes_count"
  },
  {
    title: "Media Type",
    type: 'select',
    key: "media_type",
    selectOptions: ["story", "reel", "post"]
  },
  {
    title: "Captured On",
    type: 'datetime',
    key: "created_at"
  }
]

export default FilterOptions