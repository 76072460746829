import {CampaignDetails} from "../CreateCampaignV2";
import CampaignPositionConstants, {DestinationItem} from "../../CampaignPosition/CampaignPositionConstants";
import {Radio} from "antd";
import TooltipComponent from "../../../../../Components/TooltipComponent";
import overlayImage from './overlay.png'
import newPageImage from './new.png'

type CampaignNewPositionProps = {
  details: CampaignDetails | null,
  update: Function,
  next: Function,
  back: Function
}

export const DestinationItems: DestinationItem[] = [
  {
    type: "widget",
    title: "Overlay",
    info: "This game will come OVER the current page when it is opened. Select this if you want to open it with Floating Button",
    image: overlayImage
  },
  {
    type: "new",
    title: "New Page",
    info: "This game will be hosted on a new page in your site. Select this if you want to get traffic on it from banner or email / sms",
    image: newPageImage
  },
  {
    type: "thanks",
    title: "Thank You Page",
    campaigns: []
  },
]

const CampaignNewPosition = (
  {
    details,
    update,
    next,
    back
  }: CampaignNewPositionProps
) => {

  return (
    <div
      className={"min-w-[300px] max-w-[580px] mx-auto w-full h-full flex flex-col justify-center items-center"}
    >
      <div
        className={"w-full text-5xl font-bold mb-3 text-white text-left"}
      >
        {CampaignPositionConstants.TITLES.CAMPAIGN_POSITION}
      </div>
      <div
        className={"bg-main px-5 py-5 rounded-md"}
      >
        <Radio.Group
          // className={"w-full grid grid-cols-2 gap-3 contents-center bg-main p-3 rounded-md"}
          className={"w-full p-1 rounded-md"}
          defaultValue={details?.destination || "widget"}
          onChange={(b) => update({destination: b.target.value})}
          size={"large"}
        >
          {DestinationItems.map((d) => {
            if (d.campaigns && !d.campaigns.includes(details?.campaign_type || "")) return null
            return (
              <Radio
                className={"px-2 min-w-[200px] bg-white col-span-1 rounded-md bg-[#EEF0FF] hover:text-main hover:bg-[#CFD1F3] cursor-pointer"}
                key={d.type}
                value={d.type}
              >
                <div>
                  <div
                    className={"p-3 font-bold text-lg uppercase"}
                  >
                    <span
                      className={"px-2"}
                    >
                      {d.title}
                    </span>
                    <TooltipComponent comp={d.info}/>
                  </div>
                  {
                    d.image && (
                      <div>
                        <img
                          src={d.image}
                          className={"max-w-[200px] pb-1"}
                          alt={d.title}
                        />
                      </div>
                    )
                  }
                </div>
              </Radio>
            )
          })}
        </Radio.Group>
        <div
          className={"w-full flex mt-3 justify-end space-x-3"}
        >
          <div
            onClick={() => back()}
            className="text-gray-700 font-bold cursor-pointer bg-vodka hover:bg-main-dark rounded-lg px-2 py-2 hover:shadow-md hover:bg-opacity-80 w-fit"
          >
            Back
          </div>
          <div
            className={"bg-black font-bold cursor-pointer border border-black px-6 py-2 text-xl rounded-lg text-white " +
              "hover:bg-white hover:text-black transition duration-150"}
            onClick={() => next()}
          >
            Next
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignNewPosition