import Integration from "../../Common/Models/Integration";
import KlaviyoContent from "./IntegrationModalContents/KlaviyoContent";
import klaviyoLogo from '../../Common/Images/integrations/klaviyo-logo.png'
import yotpoLogo from '../../Common/Images/integrations/yotpo-logo.png'
import typeformlogo from '../../Common/Images/integrations/typeform-logo.png'
import mailchimpLogo from '../../Common/Images/integrations/Mailchimp-logo.png'
import zapierLogo from '../../Common/Images/integrations/zapier-logo.png'
import customFormLogo from '../../Common/Images/integrations/form-logo.svg'
import wisepopslogo from '../../Common/Images/integrations/wisepops-logo.png'
import privyLogo from '../../Common/Images/privy-logo.png'
import webhooksLogo from '../../Common/Images/integrations/webhooks.png'
import gorgiasLogo from '../../Common/Images/integrations/gorgias-logo.svg'
import refersionLogo from '../../Common/Images/integrations/refersion_logo.png'
import sendinblueLogo from '../../Common/Images/integrations/sendinblue.jpeg'
import mailmodoLogo from '../../Common/Images/integrations/mailmodo.png'
import mailerLiteLogo from '../../Common/Images/integrations/mailerlite-logo.png'
import moengageLogo from '../../Common/Images/integrations/moengage.png'
import webenageLogo from '../../Common/Images/integrations/webengageLogo.jpg'
import watiLogo from '../../Common/Images/integrations/wati.png'
import clevertapLogo from '../../Common/Images/integrations/clevertaplogo.png'
import yellowAiLogo from '../../Common/Images/integrations/yellowailogo.jpg'
import wigzoLogo from '../../Common/Images/integrations/wigzologo.png'
import goAffProLogo from '../../Common/Images/integrations/goaffpro.png'
import contloLogo from '../../Common/Images/integrations/contlo.png'
import shopifyLogo from '../../Common/Images/integrations/shopify_logo.png'
import limechatLogo from '../../Common/Images/integrations/limechatLogo.webp'
import growlyticsLogo from '../../Common/Images/integrations/growlytics-logo.png'
import netCoreLogo from '../../Common/Images/integrations/netcore.png'

import PrivyContent from "./IntegrationModalContents/PrivyContent";
import TypeformContent from "./IntegrationModalContents/TypeformContent/TypeformContent";
import GorgiasContent from "./IntegrationModalContents/GorgiasContent";
import WisepopsContent from "./IntegrationModalContents/WisepopsContent/WisepopsContent";
import YotpoContent from "./IntegrationModalContents/YotpoContent";
import ZapierContent from "./IntegrationModalContents/ZapierContent";
import CustomFormContent from "./IntegrationModalContents/CustomFormContent";
import CustomWebhookContent from "./IntegrationModalContents/CustomWebhookContent";
import RefersionFormContent from "./IntegrationModalContents/RefersionFormContent";
import SendinblueContent from "./IntegrationModalContents/SendinblueContent";
import MailmodoContent from "./IntegrationModalContents/MailmodoContent/MailmodoContent";
import MailerLiteContent from "./IntegrationModalContents/MailerliteContent/MailerLiteContent";
import MoEngageContent from "./IntegrationModalContents/MoEngageContent";
import WebEngageContent from "./IntegrationModalContents/WebEngageContent";
import WatiContent from "./IntegrationModalContents/WatiContent";
import ClevertapContent from "./IntegrationModalContents/ClevertapContent";
import WigzoContent from "./IntegrationModalContents/WigzoContent";
import GoAffProContent from "./IntegrationModalContents/GoAffProContent";
import ContloContent from "./IntegrationModalContents/ContloContent/ContloContent";
import StoreShopifyContent from "./IntegrationModalContents/StoreShopifyContent/StoreShopifyContent";
import LimechatContent from "./IntegrationModalContents/LimechatContent";
import GrowlyticsContent from "./IntegrationModalContents/GrowlyticsContent/GrowlyticsContent";
import NetcoreContent from "./IntegrationModalContents/NetcoreContent";

const IBIntegrations: Integration[] = [
  {
    time_to_take: '2-3 minutes',
    name: "Shopify Sync",
    description: "",
    is_marketing: true,
    is_form: false,
    component: StoreShopifyContent,
    is_showable: true,
    image_url: shopifyLogo,
    type: "store_shopify_integration",
    // tutorial_url: "https://help.influencerbit.com/en/articles/5757761-connect-klaviyo-with-influencerbit\n"
  },
  {
    time_to_take: '10-15 minutes',
    name: "Klaviyo",
    description: "E-Commerce Email marketing Solution",
    is_marketing: true,
    is_form: true,
    component: KlaviyoContent,
    is_showable: true,
    image_url: klaviyoLogo,
    type: "klaviyo_integration",
    tutorial_url: "https://help.influencerbit.com/en/articles/5757761-connect-klaviyo-with-influencerbit\n"
  },
  {
    time_to_take: "10-15 minutes",
    name: "Sendinblue",
    description: "Email Marketing Solution",
    is_marketing: true,
    is_form: false,
    is_showable: true,
    image_url: sendinblueLogo,
    type: "sendinblue_integration",
    component: SendinblueContent
  },
  {
    time_to_take: "10-15 minutes",
    name: "Wati",
    description: "Whatsapp Solution",
    is_marketing: true,
    is_form: false,
    is_showable: true,
    image_url: watiLogo,
    type: "wati_integration",
    component: WatiContent
  },
  {
    time_to_take: "10 - 15 minutes",
    name: "WebEngage",
    description: "omnichannel campaign manager",
    is_form: false,
    is_showable: true,
    is_marketing: true,
    image_url: webenageLogo,
    tutorial_url: "https://help.influencerbit.com/en/articles/6184371-webengage-integration",
    type: "webengage_integration",
    component: WebEngageContent
  },
  {
    name: "Wigzo",
    time_to_take: "2-5 minutes",
    is_form: false,
    is_marketing: true,
    is_showable: true,
    component: WigzoContent,
    type: "wigzo_integration",
    image_url: wigzoLogo
  },
  {
    name: "GoAffPro",
    time_to_take: "2-5 minutes",
    is_form: false,
    is_marketing: true,
    is_showable: true,
    component: GoAffProContent,
    type: "goaffpro_integration",
    image_url: goAffProLogo
  },
  {
    name: "Clevertap",
    time_to_take: "2-5 minutes",
    is_form: false,
    is_marketing: true,
    is_showable: true,
    component: ClevertapContent,
    type: "clevertap_integration",
    image_url: clevertapLogo
  },
  {
    name: "Yellow.ai",
    time_to_take: "2-5 minutes",
    is_form: false,
    is_marketing: true,
    is_showable: false,
    component: ClevertapContent,
    type: "yellowai_integration",
    image_url: yellowAiLogo
  },
  {
    name: "Privy",
    time_to_take: "2-5 minutes",
    description:"Collect Instagram usernames through Privy Forms / Popups",
    is_form: true,
    is_marketing: false,
    is_showable: true,
    component: PrivyContent,
    type: "privy_integration",
    image_url: privyLogo,
    tutorial_url: "https://help.influencerbit.com/en/articles/5633302-connect-privy-with-influencerbit"
  },
  {
    name: "Contlo",
    time_to_take: "2-5 minutes",
    is_form: false,
    is_marketing: true,
    is_showable: true,
    type: "contlo_integration",
    image_url: contloLogo,
    component: ContloContent
  },
  {
    name: "MailerLite",
    time_to_take: "2-5 minutes",
    is_form: false,
    is_marketing: true,
    is_showable: true,
    type: "mailerlite_integration",
    image_url: mailerLiteLogo,
    component: MailerLiteContent
  },
  {
    name: "moengage",
    is_form: false,
    is_marketing: true,
    is_showable: true,
    image_url: moengageLogo,
    time_to_take: "7-10 minutes",
    type: "moengage_integration",
    component: MoEngageContent
  },
  {
    name: "Limechat",
    is_form: false,
    is_marketing: true,
    is_showable: true,
    image_url: limechatLogo,
    time_to_take: "3-5 minutes",
    type: "limechat_integration",
    component: LimechatContent
  },
  {
    name: "Growlytics",
    is_form: false,
    is_marketing: true,
    is_showable: true,
    image_url: growlyticsLogo,
    time_to_take: "3-5 minutes",
    type: "growlytics_integration",
    component: GrowlyticsContent,
    tutorial_url: "https://restlessmonks.notion.site/Connect-Growlytics-with-Engagebud-6500ec0d7e3b4dbfb043958f17bab191"
  },
  {
    name: "mailmodo",
    is_form: false,
    is_marketing: true,
    is_showable: true,
    type: "mailmodo_integration",
    component: MailmodoContent,
    image_url: mailmodoLogo
  },
  {
    name: "yotpo loyalty",
    is_form: false,
    is_marketing: true,
    is_showable: true,
    image_url: yotpoLogo,
    description: "Give reward points on Instagram Mention",
    time_to_take: "5-7 minutes",
    type: "yotpo_integration",
    component: YotpoContent,
    tutorial_url: "https://help.influencerbit.com/en/articles/5665537-connect-yotpo-loyalty-with-influencerbit"
  },
  {
    name: "refersion",
    is_showable: true,
    is_form: true,
    is_marketing: false,
    description:"Manage payouts and commission tracking for these influencers",
    image_url: refersionLogo,
    time_to_take: "3-5 minutes",
    type: "refersion_form_integration",
    component: RefersionFormContent,
    tutorial_url: "https://help.influencerbit.com/en/articles/5760642-connect-refersion-with-influencerbit"
  },
  {
    name: "gorgias",
    description:"Instagram Insights to your Support process",
    is_form: false,
    is_marketing: true,
    is_showable: true,
    type: "gorgias_integration",
    component: GorgiasContent,
    image_url: gorgiasLogo,
    time_to_take: "10-12 minutes",
  },
  {
    name: "mailchimp",
    is_form: false,
    is_marketing: false,
    is_showable: false,
    image_url: mailchimpLogo,
    type: "mailchimp_integration",
    time_to_take: "3-5 minutes",
  },
  {
    name: "typeform",
    description:"Create Beautiful forms using Typeform and ask for Instagram username",
    is_showable: true,
    is_marketing: false,
    is_form: true,
    image_url: typeformlogo,
    component: TypeformContent,
    type: "typeform_integration",
    time_to_take: "3-5 minutes",
    tutorial_url: "https://help.influencerbit.com/en/articles/5633303-connect-typeform-with-influencerbit"
  },
  {
    name: "zapier",
    is_form: false,
    is_showable: true,
    is_marketing: true,
    image_url: zapierLogo,
    type: "zapier_integration",
    time_to_take: "2-5 minutes",
    component: ZapierContent,
    description: "Connect Engagebud to 1000+ apps via Zapier",
  },
  {
    name: "custom webhook",
    is_form: false,
    is_showable: true,
    is_marketing: true,
    image_url: webhooksLogo,
    type: "custom_webhook_integration",
    time_to_take: "1-2 minutes",
    component: CustomWebhookContent,
    description: "Send Social Insights data to a specific url",
  },
  {
    name: "wisepops",
    is_marketing: false,
    is_showable: true,
    is_form: true,
    image_url: wisepopslogo,
    time_to_take: "2-5 minutes",
    description: "Collect Instagram Usernames through wisepops popups / forms",
    type: "wisepops_integration",
    component: WisepopsContent,
    tutorial_url: "https://help.influencerbit.com/en/articles/5633300-connect-wisepops-with-influencerbit",
  },
  {
    name: "netcorecloud",
    is_marketing: true,
    is_showable: true,
    is_form: false,
    image_url: netCoreLogo,
    time_to_take: "2-5 minutes",
    description: "Send Emails using Netcorecloud",
    type: "netcorecloud_integration",
    component: NetcoreContent,
  },
  {
    name: "custom form",
    is_marketing: false,
    is_showable: true,
    is_form: true,
    image_url: customFormLogo,
    time_to_take: "2-5 minutes",
    description: "Collect Instagram Usernames through your custom html form",
    type: "custom_form_integration",
    component: CustomFormContent
  }
]

export default IBIntegrations