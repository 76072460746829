import {Button, Result} from "antd";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {getUser} from "../../../app/features/user/userSlice";

const PaymentSuccess = () => {
  const history = useHistory()
  const user = useSelector(getUser)
  const [plan, setPlan] = useState(user?.product)

  useEffect(() => {
    setPlan(plan)
  }, [user])

  return(
      <Result
          title={user?.product?.plan_name ? `Successfully Purchased ${user?.product?.plan_name}` : "Payment Successful"}
          status={'success'}
          subTitle={!plan && "Subscription will be activated in a few minutes. Try refreshing the page. If you" +
          " are still not able to see the active subscription, contact us."}
          extra={[
            <Button
                type={'primary'}
                key={'data'}
                onClick={() => history.replace({pathname: "/people"})}
            >
              Go to Data
            </Button>,
            <Button
                onClick={() => history.replace({pathname: "/profile"})}
            >
              Visit My Profile
            </Button>
          ]}
      />
  )
}

export default PaymentSuccess