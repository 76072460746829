import {useHistory} from "react-router-dom";
import menuItems from "./MenuItems";
import React, {Key, useEffect, useState} from "react";
import LeftMenuItem from "./LeftMenuItem";
import ebLogo from "../../Common/Images/ebwhite.png";
import CreateCampaignV2 from "../CampaignsComponent/CreateCampaign/CreateCampaignV2";
import { SECTION_DIMENSIONS } from "../../Common/Constants/SectionDimensionConstants";
import UsageBarMenu from "./UsageBarMenu";

const SIDER_CLASSNAMES = {
  ACTIVE_MENU: "bg-white text-darkmain hover:bg-blue-200 hover:text-black",
  INACTIVE_MENU: "text-white bg-main hover:bg-gray-100 hover:text-darkmain",
  ICON_ACTIVE: "text-darkmain",
  ICON_INACTIVE: "text-white hover:text-darkmain"
}

interface LeftMenuProps {
  active: string
}

const LeftMenu = (
    {
        active
    }: LeftMenuProps
) => {
  const [siderMenu, setSiderMenu] = useState<string>("/data")
  const [activeToggle, setActiveToggle] = useState<string[]>([])
  const [createCampaignModal, setCreateCampaignModal] = useState(false)
  const hist = useHistory()

  const createNewCampaignV2 = () => {
    setCreateCampaignModal(true)
    // history.push(`${window.location.pathname}/create`)
  }

  const onOpenChange = (keys: Key[]) => {
    let b: React.Key = ""
    if(keys.length !== 0) {
      b = keys[keys.length-1]
      setSiderMenu(b as string)
    }
  }

  useEffect(() => {
    // console.log(active)
  }, [active])

  const FlowBiteChildRenderer = ({siderMenu, child = 0}: {siderMenu: LeftMenuItem, child: number}) => {
    return(
      <li
        className={child?"pl-2":""}
        key={siderMenu.link}
      >
        {
          siderMenu.child ? (
            <>
              <button
                type="button"
                className={`flex ${activeToggle.includes(siderMenu.link) ? SIDER_CLASSNAMES.ACTIVE_MENU : SIDER_CLASSNAMES.INACTIVE_MENU} items-center p-2 w-full text-base font-normal rounded-lg transition duration-75 group`}
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                title={siderMenu.info || siderMenu.title}
                onClick={() => {
                  let k = 0
                  if(child) {
                    if(siderMenu.link === activeToggle[1]) {
                      setActiveToggle([activeToggle[0]])
                    } else {
                      setActiveToggle([activeToggle[0], siderMenu.link])
                    }
                  } else {
                    if(siderMenu.link === activeToggle[0]) {
                      setActiveToggle([])
                    } else {
                      setActiveToggle([siderMenu.link])
                    }
                  }
                }}
              >
                {
                  siderMenu.Icon ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill={"currentColor"}
                      viewBox="0 0 24 24"
                      className={`w-6 h-6 ${activeToggle.includes(siderMenu.link) ? SIDER_CLASSNAMES.ICON_ACTIVE : SIDER_CLASSNAMES.ICON_INACTIVE} transition duration-75 group-hover:text-darkmain`}
                    >
                      {siderMenu.Icon}
                    </svg>
                  ) : (
                    <svg
                      aria-hidden="true"
                      className={`w-6 h-6 ${activeToggle.includes(siderMenu.link) ? SIDER_CLASSNAMES.ICON_ACTIVE : SIDER_CLASSNAMES.ICON_INACTIVE} transition duration-75 group-hover:text-darkmain`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"/>
                      <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"/>
                    </svg>
                  )
                }
                <span
                  className={"flex-1 ml-3 font-semibold text-left whitespace-nowrap"}
                  title={siderMenu.info || siderMenu.title}
                >
                  {siderMenu.title}
                </span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <ul
                id={siderMenu.link+"_toggle"}
                key={siderMenu.link+"_toggle"}
                className={activeToggle[child ? 1 : 0]===siderMenu.link ? "py-2 space-y-2" : "hidden py-2 space-y-2"}
              >
                {siderMenu.child.filter(a => a.hide === false || a.hide === undefined).map((c) => (
                  <FlowBiteChildRenderer
                    siderMenu={c}
                    key={c.link}
                    child={child+1}
                  />
                ))}
              </ul>
            </>
          ) : (
            <a
              className={`flex ${activeToggle.includes(siderMenu.link) ? SIDER_CLASSNAMES.ACTIVE_MENU : "text-white hover:bg-gray-100 hover:text-darkmain"} items-center p-2 text-base font-normal rounded-lg`}
              onClick={() => {
                setActiveToggle(child ? [...activeToggle.slice(0, child), siderMenu.link] : [siderMenu.link])
                if(siderMenu.externalLink) {
                  window.open(siderMenu.link, "_blank")
                } else {
                  hist.push(siderMenu.link)
                }
              }}
              title={siderMenu.info || siderMenu.title}
            >
              {
                siderMenu.Icon ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    className={`w-6 h-6 ${activeToggle.includes(siderMenu.link) ? "text-darkmain" : "hover:text-darkmain"} transition duration-75 group-hover:text-darkmain`}
                  >
                    {siderMenu.Icon}
                  </svg>
                ) : (
                  siderMenu.icon ? (
                    siderMenu.icon
                  ) : (
                    <svg
                      aria-hidden="true"
                      className={`w-6 h-6 ${activeToggle.includes(siderMenu.link) ? "text-darkmain" : "hover:text-darkmain"} transition duration-75 group-hover:text-darkmain`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"/>
                      <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"/>
                    </svg>
                  )
                )
              }
              <span
                className="flex-1 ml-3 font-semibold whitespace-nowrap"
                title={siderMenu.info || siderMenu.title}
              >
                {siderMenu.title}
              </span>
            </a>
          )
        }
      </li>
    )
  }

  return (
    <aside
      className={"min-h-screen left-0 overflow-auto fixed bg-main shadow-lg z-[250]"}
      aria-label="Sidebar"
      style={{
        width: `${SECTION_DIMENSIONS.SIDEBAR}px`
      }}
    >
      <CreateCampaignV2
        active={createCampaignModal}
        close={() => setCreateCampaignModal(false)}
      />
      <div
        className="flex flex-col justify-start bg-main h-screen overflow-y-auto py-4 px-3 rounded dark:bg-gray-800"
      >
        <ul
          className="space-y-3"
        >
          <div
            className={"min-h-[65px]"}
          >
            <img
              className={"max-w-[160px] cursor-pointer mx-auto my-4 select-none"}
              src={ebLogo}
              height={65}
              onClick={() => window.location.href = window.location.origin}
              alt={"Engagebud"}
            />
          </div>
          <div
            className={"cursor-pointer px-4 py-3 mx-auto select-none bg-darkmain rounded-xl hover:bg-white hover:text-darkmain text-white text-md font-semibold"}
            onClick={() => createNewCampaignV2()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-4 h-4 inline mr-3 align-middle"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            <span
              className={"align-middle"}
            >
              Create Campaign
            </span>
          </div>
          {
            menuItems.sider.filter(a => a.hide === false || a.hide === undefined).map((siderMenu) => (
              <FlowBiteChildRenderer
                siderMenu={siderMenu}
                child={0}
                key={siderMenu.link}
              />
            ))
          }
          <div
            className={"border-t-white border"}
          />
          {
            menuItems.siderBottom.filter(a => a.hide === false || a.hide === undefined).map((siderMenu) => (
              <FlowBiteChildRenderer
                siderMenu={siderMenu}
                child={0}
                key={siderMenu.link}
              />
            ))
          }
          <UsageBarMenu />
        </ul>
      </div>
    </aside>
  )
}

export default LeftMenu
