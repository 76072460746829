import {PostRequest} from "../../Requests";
import ApiResponse from "../../../Models/ApiResponse";
import {ApiConstants, FinalServerUrl} from "../../ApiConstants";
import authHeader from "../../auth-header";

const CreateCampaignApi = async (
  name: string,
  campaign_type: string,
  destination: string = "new",
) => {
  let apiResponse;
  if (ApiConstants.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(FinalServerUrl("/campaign", 5), {
      name,
      campaign_type,
      destination: destination
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    data: "OK",
    success: true,
    error: null,
  };
}

export default CreateCampaignApi