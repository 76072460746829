import {Button, Form, Input, message, Typography} from "antd";
import {useEffect, useState} from "react";
import {BadNotif, SuccessNotif} from "../../../../Common/Utils/SendNotification";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";
import CustomWebhookIntegration from "../../../../Common/Models/CustomWebhookIntegration";
import ConnectCustomWebhookApi from "../../../../Common/ApiCall/IbServer/ConnectCustomWebhookApi";

const {Title, Text} = Typography

type CustomWebhookContentProps = {
  details: CustomWebhookIntegration | null,
} & CommonContentProps

const CustomWebhookContent = (
    {
      close,
      details,
      updateUserFunction
    }: CustomWebhookContentProps
) => {
  const [load, setLoad] = useState(false)
  const [customHookContentForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
    customHookContentForm.setFieldsValue({
      url_field: details?.webhook_url
    })
  }, [details])

  const integrateCustomHook = async (values: any) => {
    loading()
    const res = await ConnectCustomWebhookApi(values.url_field)
    if(res.success) {
      message.success("Custom Webhook is connected now.")
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
  }

  return(
      <>
        <Form
            hideRequiredMark
            onFinish={integrateCustomHook}
            form={customHookContentForm}
            initialValues={isConnected ? {
              url_field: details?.webhook_url
            }: {}}
        >
          <Form.Item
              noStyle
          >
            Custom Webhook URL
            <Form.Item
                name={"url_field"}
                rules={[{
                  required: true
                }]}
            >
              <Input />
            </Form.Item>
          </Form.Item>
          <Form.Item
              shouldUpdate
          >
            {() => {
              return (
                  <Button
                      disabled={load}
                      loading={load}
                      type={'primary'}
                      htmlType={'submit'}
                  >
                    {isConnected ? "Update" : "Activate"}
                  </Button>
              )
            }}
          </Form.Item>
        </Form>
      </>
  )
}

export default CustomWebhookContent