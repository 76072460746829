import ContentWrapper from "../../../../Components/ContentWrapper";
import {Button, Divider, Space, Spin, Typography} from "antd";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clearUser, getUser, getUserAsync, setUser} from "../../../../app/features/user/userSlice";
import ConnectFacebook from "../../../../Common/ApiCall/IbServer/ConnectFacebook";
import {BadNotif, SuccessNotif} from "../../../../Common/Utils/SendNotification";
import FacebookLogin from 'react17-facebook-login'
import IgAccount from "../../../../Common/Models/IgAccount";
import IgAccountCard from "./IgAccountCard";
import {useAppDispatch} from "../../../../app/store";
import RemoveIgAccount from "../../../../Common/ApiCall/IbServer/RemoveIgAccount";
import { VideoCameraOutlined} from "@ant-design/icons";
import {SegmentAnalytics} from "../../../../Components/SegmentAnalytics";

const {Title, Text} = Typography

const appId = process.env.REACT_APP_FB_APP_ID;
if(appId === undefined || appId === null || appId .length < 1) {
  console.log("appId Not Present")
}

type ConnectFBProps = {
  standalone?: boolean,
  whiteBg?: boolean,
  inlineCards?: boolean,
  help?: boolean,
  influencer?: boolean
}

const ConnectFB = (
    {
        standalone = false,
        whiteBg = false,
        inlineCards = false,
        help = false,
        influencer = false
    }: ConnectFBProps
) => {
  const [load, setLoad] = useState(false)
  const user = useSelector(getUser)
  const appDispatch = useAppDispatch()
  const dispatch = useDispatch()

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  const handleFacebookResponse = async (e: any) => {
    const accessToken = e.accessToken;
    if(!accessToken) {
      return
    }
    SegmentAnalytics.track("Brand Facebook Connect")
    loading()
    const response = await ConnectFacebook(accessToken)
    if(response.success) {
      SuccessNotif("Facebook Successfully Added")
      const {payload} = await appDispatch(getUserAsync());
      if(payload.success) {
        dispatch(setUser(payload.data))
      } else {
        dispatch(clearUser(2))
      }
    } else {
      BadNotif(response)
    }
    loaded()
  }

  const removeAccount = async (account_id: number) => {
    SegmentAnalytics.track("Brand Ig Account Removed")
    const response = await RemoveIgAccount(account_id)
    if(response.success) {
      SuccessNotif("Ig Account Removed. Refreshing your Details")
      const {payload} = await appDispatch(getUserAsync());
      if(payload.success) {
        dispatch(setUser(payload.data))
      } else {
        dispatch(clearUser(2))
      }
    } else {
      BadNotif(response)
    }
    loaded()
    return true
  }

  if(appId === undefined) {
    return (
        <ContentWrapper>
          <Title>
            Something Went Wrong. Try again later
          </Title>
        </ContentWrapper>
    )
  }

  let options: any = {}
  if(standalone) {
    options = {
      style: {
        minHeight: '70vh'
      }
    }
  }

  return(
      <div
        className={''}
          {...options}
      >
        {
          !influencer && (
              <>
                <Title>
                  Connect your Facebook / Instagram
                  {help && (
                      <Button
                          className={"is-pulled-right"}
                          size={'large'}
                      >
                        <VideoCameraOutlined /> Confused? Step by Step Guide
                      </Button>
                  )}
                </Title>
                <Divider />
              </>
          )
        }
        <Space
          direction={inlineCards ? "horizontal" : "vertical"}
        >
          <FacebookLogin
              appId={appId}
              textButton={"Connect With Facebook"}
              fields={"name,email,picture"}
              scope={"email," +
              "public_profile," +
              "pages_show_list," +
              "instagram_manage_insights," +
              "instagram_manage_comments," +
              "pages_manage_metadata," +
              "pages_read_engagement," +
              "instagram_basic,"+
              "instagram_manage_messages," +
              "pages_messaging"}
              callback={handleFacebookResponse}
              icon={"fa-facebook-square"}
              // Have to write complete css if go for custom
/*              cssClass={"fbCustomButton"}*/
          />
          {
            load && (
                <Title className={"py-4"} level={3}>
                  Connecting to your Instagram Accounts... <Spin />
                </Title>
            )
          }
          {user.accounts.instagram.map((acc: IgAccount) => (
              <IgAccountCard
                  account={acc}
                  key={acc.username}
                  remove={removeAccount}
                  removable
              />
          ))}
        </Space>
      </div>
  )
}

export default ConnectFB
