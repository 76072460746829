const SECTION_DIMENSIONS = {
    SIDEBAR: 235,
    COLLAPSED_SIDEBAR: 80,
    INNER_SIDEBAR: 150,
    TOPBAR: 64,
    BUILDER_STEPS: 70,
    HOME_CAMPAIGN_NAVBAR: 96,
    EDIT_CAMPAIGN_NAVBAR: 116
}

export {SECTION_DIMENSIONS}