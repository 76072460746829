import {Button, Form, Input, Modal} from "antd";
import {useState} from "react";
import GetPermissionURL from "../../../Common/ApiCall/IbServer/Shopify/GetPermissionURL";
import {BadNotif} from "../../../Common/Utils/SendNotification";

interface ShopifyModalProps {
  vis: boolean,
  close: Function
}

let mess = `
Confirm New Integration
You're about to integrate the Klaviyo account Restlessmonks (VF7Cbn) with my-test-store-aman.myshopify.com. Are you sure that you want to continue?
Not the right account? Check to make sure that you do not have any other tabs or browsers open to Klaviyo before you add or update this integration.
`

const ShopifyModal = (
    {
        vis,
        close
    }: ShopifyModalProps
) => {
  const [permissionUrl, setPermissionUrl] = useState('')
  const [shopifyForm] = Form.useForm()
  const [load, setLoad] = useState(false)

  const onFinish = async (values: any) => {
    setLoad(true)
    const res = await GetPermissionURL(values.shopify_url)
    if(res.success) {
      setPermissionUrl(res.data)
      window.open(res.data, "_blank")
    } else {
      BadNotif(res)
    }
    setLoad(false)
  }

  return (
      <Modal
        visible={vis}
        onCancel={() => close()}
        footer={null}
        centered
        destroyOnClose
      >
        <Form
            onFinish={onFinish}
          form={shopifyForm}
        >
          <Form.Item
            noStyle
          >
            Store URL
            <Form.Item
              name={"shopify_url"}
              rules={[{
                required: true,
                message: "Please provide your Shopify store URL",
/*                pattern: new RegExp()*/
              }]}
            >
              <Input
                placeholder={"my-store.shopify.com"}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            shouldUpdate
          >
            {() => (
                <Button
                  htmlType={'submit'}
                  type={'primary'}
                  disabled={load}
                  loading={load}
                >
                  Connect to Shopify
                </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
  )
}

export default ShopifyModal