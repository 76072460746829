import React from "react";
import "../Onboarding.css";
import screen1Img from "../../../Common/Images/onboarding/screen1.png";
function Screen1() {
  return (
    <div className="container is-fluid screen1Container">
      <div className="columns is-multiline  is-mobile">
        <div
          className="column is-12-mobile is-12-tablet is-5-desktop imageContainer"
         
        >
          <img loading="eager" src={screen1Img} alt="screen1img" className="" />
        </div>
        <div
          className="column is-12-mobile is-12-tablet is-7-desktop "
         
        >
         <h2 className="is-size-2-desktop  is-size-3-tablet text-white has-text-left screen1Header">
         92% of people said that they trust recommendations from friends and family above all other forms of advertising. 
         </h2>
         <p className="text-white is-size-3-desktop has-text-left screenDescription mt-4">Your Loyal Customers are your biggest influencers!</p>
        
       
        </div>

      </div>
    </div>
  );
}

export default Screen1;
