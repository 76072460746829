import {
  Modal, Typography, Space, Input, Button, Form, Divider, Upload, message, Select
} from "antd";
import {DraggerProps, RcFile} from "antd/es/upload";
import {BadNotif, SuccessNotif} from "../../../../../Common/Utils/SendNotification";
import {useEffect, useState} from "react";
import {UploadFile} from "antd/es/upload/interface";
import {InboxOutlined} from "@ant-design/icons";
import AddUserManualApi from "../../../../../Common/ApiCall/IbServer/AddUserManualApi";
import {ApiConstants} from "../../../../../Common/ApiCall/ApiConstants";
import {DataProps} from "../../../Data";
import GiveawayProduct from "../../../../../Common/Models/GiveawayProduct";
import GetGiveawayProductApi from "../../../../../Common/ApiCall/IbServer/InfluencerPage/GetGiveawayProductApi";
import {ScaleLoader} from "react-spinners";

const {Title} = Typography
const {Dragger} = Upload

interface AddUsersManualProps {
  visible: boolean,
  onClose: Function,
  option: DataProps["totOptions"]
}

export interface fileRow {
  ig_username: string | null,
  email: string,
  description?: string,
  address?: string,
  product_id?: number,
  first_name?: string,
  last_name?: string,
  contact?: string
}

const AddUsersManual = (
  {
    visible,
    onClose,
    option
  }
: AddUsersManualProps) => {
  const [manualForm] = Form.useForm()
  const [inboundManualForm] = Form.useForm()
  const [localFileList, setLocalFileList] = useState<UploadFile[]>([])
  const [accounts, setAccounts] = useState<fileRow[]>([])
  const [checking, setChecking] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [prods, setProds] = useState<GiveawayProduct[]>([])
  const [load, setLoad] = useState(false)

  async function getGiveawayProducts() {
    setLoad(true)
    const res = await GetGiveawayProductApi()
    if(res.success) {
      setProds(res.data.products)
    } else {
      BadNotif(res)
    }
    setLoad(false)
  }

  const draggerProps: DraggerProps = {
    name: 'file',
    accept: '.csv',
    beforeUpload: file => {
      if(file.type !== 'text/csv') {
        BadNotif(null, `${file.name} is not a csv file`)
      }
      console.log("Started Reading File")
      return false
    },
    onChange(info) {
      const {status} = info.file
      if(info.file.type === 'text/csv') {
        setLocalFileList(info.fileList)
      }
      if(status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if(status === 'done') {
        SuccessNotif(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        BadNotif(null, `${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    fileList: localFileList
  }

  useEffect(() => {
    if(localFileList.length > 0) {
      readFile()
          .then(r => {
            setLocalFileList([])
          })
    }
  }, [localFileList])

  const csvToJson = (csv: any) => {
    let [firstLine, ...lines] = csv.split('\n');
    firstLine = firstLine.toUpperCase()
    return lines.map((line: string) =>
        firstLine.split(',').reduce(
            (curr: any, next: any, index: any) => ({
              ...curr,
              [next]: line.split(',')[index],
            }),
            {}
        )
    );
  };

  const readIndividualFile = (file: RcFile | undefined) => {
    if(!file) {
      BadNotif(null, "Cannot Read File")
      return
    }
    let reader = new FileReader()
    reader.onload = e => {
      if(!e) {
        message.error("Cannot Read File")
        return
      }
      const text = e?.target?.result;
      let jsonData = csvToJson(text)
      jsonData = [jsonData.pop(), ...jsonData]
      let data: fileRow[] = []
      jsonData.forEach((element: any, index: number) => {
/*        console.log(element, index)*/
        if(index >= 0) {
          let obj = checkRow(element)
/*          console.log("Response", obj, "for", element)*/
          if(obj.status) {
            data.push(obj.object)
          }
        }
      })
      if(data.length < 1) {
        BadNotif(null, "No Valid User Present in File")
      }
      console.log(`Got ${data.length} Valid Users from File.`)
      setAccounts(data)
      reader.abort()
    }
    reader.readAsText(file)
  }

  function get_username_from_ig_link(link: string) {
    if (!link || link == "") return null;
    let post_pieces = link.split("/p/");
    if (post_pieces.length > 1) return null;
    let pieces = link.split("instagram.com");
    if (pieces.length < 2) return null;
    let main_piece = pieces[1];
    let main_pieces = main_piece.split("/");
    if (main_pieces.length < 2) return null;
    return main_pieces[1].split("?")[0]
  }

  function get_username(obj: string | null) {
    if (!obj || obj === "") return null;
    obj = obj.trim().toLowerCase()
    if(obj.includes("instagram.com")) obj = get_username_from_ig_link(obj)
    return obj
  }

  const checkRow = (element: any) => {
    let status = false;
    const obj: fileRow = {
      ig_username: get_username(element["INSTA_USERNAME"]),
      email: element["EMAIL"]?.trim(),
      description: element["DESCRIPTION"]?.trim()
    }
/*    console.log(obj, obj.email, obj.ig_username)*/
    if(obj.email && obj.ig_username) {
      status = true;
    }
    return {
      status: status,
      object: obj
    }
  }

  const readFile = async () => {
    console.log('read_files', localFileList)
    setChecking(true)
    await localFileList.forEach((file, index) => {
      readIndividualFile(file.originFileObj)
    })
    setChecking(false)
    console.log('read_files_end')
  }

  async function submitUserManual() {
    if(accounts.length < 1) {
      BadNotif(null, "No Valid Users to Continue")
      return
    }
    console.table(accounts)
    setSubmitting(true)
    const res = await AddUserManualApi(accounts, option === 'people' ? "manual" : "influencer form")
    if(res.success) {
      SuccessNotif("Users Added. They will appear in the dashboard shortly")
    } else {
      BadNotif(res)
    }
    setLocalFileList([])
    setSubmitting(false)
    setAccounts([])
  }

  function submitManualEntry(values: any) {
    setAccounts([{
      ig_username: values.ig_username,
      email: values.email,
      address: values.address,
      product_id: values.product_id,
      first_name: values.first_name,
      last_name: values.last_name,
      contact: values.contact
    }])
    manualForm.resetFields()
  }

  useEffect(() => {
    if(accounts.length > 0) submitUserManual()
  }, [accounts])

  useEffect(() => {
    getGiveawayProducts()
  }, [])

  return (
      <Modal
        visible={visible}
        footer={null}
        width={900}
        onCancel={() => onClose()}
        destroyOnClose
        title={"Import Instagram Handles"}
      >
{/*        <button
          className={"button uppercase is-outlined is-info mx-4 mb-2 is-pulled-right"}
        >
          Confused? Watch Tutorial
        </button>*/}
        <div
          className={"min-w-full space-y-0 gap-0"}
        >
          <div
            hidden={option === 'inbound'}
          >
            <Title
              level={4}
            >
              Via CSV
            </Title>
            <Dragger
              {...draggerProps}
            >
              <p className={"ant-upload-drag-icon"}>
                <InboxOutlined />
              </p>
              <p>
                CSV Only, drag and drop here or Browse
              </p>
            </Dragger>
            <Title>
              {checking ? "Extracting Users from File" : ""}
            </Title>
            <Space>
              <Button
                className={"is-fullwidth"}
                size={'large'}
                href={ApiConstants.PROD_SERVER_URL + "/welcome.csv"}
                target={'_blank'}
              >
                Download Example
              </Button>
            </Space>
            <Divider>
              or
            </Divider>
          </div>
          <Space
            direction={'vertical'}
            hidden={option === 'inbound'}
          >
            <Title level={4}>
              Manual Entry
            </Title>
            <Form
              layout={'inline'}
              form={manualForm}
              onFinish={submitManualEntry}
            >
              <Form.Item>
                IG Username
                <Form.Item
                  name={"ig_username"}
                  rules={[{required: true}]}
                >
                  <Input
                    placeholder={"johndoe"}
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item>
                Email
                <Form.Item
                  rules={[{required: true}]}
                  name={"email"}
                >
                  <Input
                    placeholder={"johndoe@gmail.com"}
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item>
                <Button
                  size={'large'}
                  type={'primary'}
                  htmlType={'submit'}
                  disabled={submitting}
                  loading={submitting}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Space>
          <Space
            direction={'vertical'}
            hidden={option !== 'inbound'}
            className={"mt-0 pt-0 min-w-full gap-0"}
          >
            <Title level={4}>
              Manual Entry
            </Title>
            <Form
              form={inboundManualForm}
              onFinish={submitManualEntry}
              hideRequiredMark
            >
              <div
                className={"columns"}
              >
                <div className={"column mb-0"}>
                  <Form.Item
                    noStyle
                  >
                    Name
                    <Form.Item
                      name={"first_name"}
                      className={"mb-0"}
                    >
                      <Input
                        placeholder={"Name"}
                      />
                    </Form.Item>
                  </Form.Item>
                </div>
{/*                <div className={"column mb-0"}>
                  <Form.Item
                    noStyle
                  >
                    Last Name
                    <Form.Item
                      name={"last_name"}
                      className={"mb-0"}
                    >
                      <Input
                        placeholder={"Last Name"}
                      />
                    </Form.Item>
                  </Form.Item>
                </div>*/}
              </div>
              <Form.Item
                noStyle
              >
                Instagram Username
                <Form.Item
                  name={"ig_username"}
                  rules={[{required: true}]}
                >
                  <Input
                    placeholder={"johndoe"}
                  />
                </Form.Item>
              </Form.Item>
              <div className={"columns"}>
                <div className={"column mb-0"}>
                  <Form.Item
                    noStyle
                  >
                    Email
                    <Form.Item
                      rules={[{required: true}]}
                      name={"email"}
                      className={"mb-0"}
                    >
                      <Input
                        placeholder={"johndoe@gmail.com"}
                      />
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className={"column mb-0"}>
                  <Form.Item
                    noStyle
                  >
                    Contact
                    <Form.Item
                      name={"contact"}
                      className={"mb-0"}
                    >
                      <Input
                        placeholder={"Contact"}
                      />
                    </Form.Item>
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                noStyle
              >
                Address
                <Form.Item
                  name={"address"}
                >
                  <Input.TextArea
                    placeholder={"Enter Address here"}
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item
                noStyle
              >
                Product
                <Form.Item
                  className={"text-center"}
                >
                  {load ? (<ScaleLoader />) : (
                    <Form.Item
                      name={"product_id"}
                    >
                      <Select
                        placeholder={"Select A Giveaway Product"}
                      >
                        {prods.map((p) => (
                          <Select.Option
                            value={p.id}
                            key={p.id}
                            title={p.product_name}
                          >
                            {p.product_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button
                    size={'large'}
                    type={'primary'}
                    htmlType={'submit'}
                    disabled={submitting}
                    loading={submitting}
                  >
                    Submit
                  </Button>
                  <Button
                    size={'large'}
                    htmlType={'reset'}
                  >
                    Reset
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Space>
        </div>
      </Modal>
  )
}

export default AddUsersManual