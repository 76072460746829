import {Button, Form, Input, message} from "antd";
import {useEffect, useState} from "react";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";
import ContloIntegration from "../../../../Common/Models/Integrations/ContloIntegration";
import ConnectContlo from "../../../../Common/ApiCall/IbServer/Integrations/ConnectContlo";

type ContloContentProps = {
  details: ContloIntegration | null,
} & CommonContentProps

const MailmodoContent = (
  {
    close,
    details,
    updateUserFunction
  }: ContloContentProps
) => {
  const [load, setLoad] = useState(false)
  const [mailmodoContentForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
  }, [details])

  const integrateMailmodo = async (values: any) => {
    loading()
    const res = await ConnectContlo(values.private_key)
    if(res.success) {
      message.success("Contlo is connected now.")
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
  }

  return(
    <>
      <Form
        hideRequiredMark
        onFinish={integrateMailmodo}
        form={mailmodoContentForm}
        initialValues={details ? {
          public_key: details.public_key,
          private_key: details.private_key
        } : {}}
      >
        <Form.Item
          noStyle
        >
          Private Key
          <Form.Item
            name={"private_key"}
            rules={[{
              required: true
            }]}
          >
            <Input
            />
          </Form.Item>
        </Form.Item>
        {/*<Form.Item*/}
        {/*  noStyle*/}
        {/*>*/}
        {/*  Public Key*/}
        {/*  <Form.Item*/}
        {/*    name={"public_key"}*/}
        {/*    rules={[{*/}
        {/*      required: true*/}
        {/*    }]}*/}
        {/*  >*/}
        {/*    <Input*/}
        {/*    />*/}
        {/*  </Form.Item>*/}
        {/*</Form.Item>*/}
        <Form.Item
          shouldUpdate
        >
          {() => {
            return (
              <Button
                disabled={load}
                loading={load}
                type={'primary'}
                title={"Activate Contlo"}
                htmlType={'submit'}
              >
                {isConnected ? "Update" : "Activate"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}

export default MailmodoContent