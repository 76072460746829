import {useEffect, useState} from "react";
import {message, Popconfirm} from "antd";
import {IntegrationsProps} from "../Integrations";
import CommonIntegration from "../../../Common/Models/CommonIntegration";
import Integration from "../../../Common/Models/Integration";
import logo from "../../../Common/Images/influencerbit-logo-full.png";

type IntegrationCardProps = {
  integration: Integration,
  connectFunction: Function,
  cardDetails: IntegrationsProps['cardDetails'],
  myIntegration: CommonIntegration | null,
  hidden?: boolean,
  disconnect: Function
}

const IntegrationCard = (
  {
    integration,
    connectFunction,
    cardDetails,
    myIntegration,
    hidden = false,
    disconnect
  }: IntegrationCardProps
) => {
  const [dis, setDis] = useState(false)
  const fullDetails = cardDetails === 'full'
  const [isConnected, setIsConnected] = useState(myIntegration?.is_connected)

  useEffect(() => {
    setIsConnected(myIntegration?.is_connected)
  }, [myIntegration])

  const disconnectMyIntegration = async () => {
    if (!myIntegration) return
    setDis(true)
    await disconnect(integration, myIntegration.id)
    setDis(false)
  }

  return (
    <div
      className={`rounded-md min-h-full shadow-xl bg-white border-1 border-solid border-black p-4 text-center min-h-full flex flex-col ${hidden ? "hidden" : ""}`}
    >
      <div className={"uppercase is-size-5-desktop font-bold"}>
        {integration.name}
      </div>
      <div
        className={`uppercase is-size-6-desktop font-bold mt-1 text-[#00AA63] text-center`}
      >
        {isConnected ? "CONNECTED" : <br/>}
      </div>
      <div
        className={"min-w-full"}
      >
        <img
          src={integration.image_url ?? logo}
          alt={integration.name}
          className={"py-5 h-[100px] select-none mx-auto"}
        />
      </div>
      <div className={"grow"}/>
      {
        fullDetails && (
          <p className={"py-2 font-bold"}>
            {integration.description}
          </p>
        )
      }
      <p className={"py-2"}>
        {integration.time_to_take && `⏲ ${integration.time_to_take}`}
      </p>
      {
        isConnected ? (
          <>
            <button
              className={`mt-2 bg-[#006DAA] rounded-md text-center py-2 px-2 items-center text-white border-none uppercase min-w-full font-bold`}
              onClick={() => {
                return integration.component ? (
                  connectFunction(integration)
                ) : message.info("Integration will be available soon")
              }}
            >
              Update / Info
            </button>
            <Popconfirm
              title={"Are you sure you want to remove the Integration?"}
              onConfirm={() => disconnectMyIntegration()}
            >
              <button
                className={`mt-2 bg-[#B00020] rounded-md text-center py-2 px-2 items-center text-white border-none uppercase min-w-full font-bold`}
              >
                {dis ? "Disconnecting..." : "Disconnect"}
              </button>
            </Popconfirm>
          </>
        ) : (
          <>
            {
              integration.tutorial_url && (
                <button
                  className={`mt-2 bg-black rounded-md text-center p-2 items-center text-white border-none uppercase min-w-full font-bold`}
                  onClick={() => window.open(integration.tutorial_url, "_blank")}
                >
                  Guide
                </button>
              )
            }
            <button
              className={`mt-2 bg-[hsl(229deg,53%,53%)] rounded-md text-center p-2 items-center text-white border-none uppercase min-w-full font-bold`}
              onClick={() => {
                return integration.component ? (
                  connectFunction(integration)
                ) : message.info("Integration will be available soon")
              }}
            >
              {"Connect"}
            </button>
          </>
        )
      }
    </div>
  )
}

export default IntegrationCard