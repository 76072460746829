import {Button, Form, Input, message, Select, Typography} from "antd";
import {useEffect, useState} from "react";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";
import ClevertapIntegration from "../../../../Common/Models/ClevertapIntegration";
import ConnectClevertapApi from "../../../../Common/ApiCall/IbServer/ConnectClevertapApi";

type ClevertapContentProps = {
  details: ClevertapIntegration | null,
} & CommonContentProps

const REGIONS = [
  {
    title: "US",
    value: "US"
  },
  {
    title: "EU",
    value: "EU"
  },
  {
    title: "IN",
    value: "IN"
  },
  {
    title: "Singapore",
    value: "SG"
  }
]

const ClevertapContent = (
    {
      close,
      details,
      updateUserFunction
    }: ClevertapContentProps
) => {
  const [load, setLoad] = useState(false)
  const [privyContentForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
  }, [details])

  const integratePrivy = async (values: any) => {
    loading()
    const res = await ConnectClevertapApi(values.account_id, values.account_passcode, values.region)
    if(res.success) {
      message.success("Clevertap is connected now. You can start collecting data.")
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
  }

  return(
      <>
        <Form
            hideRequiredMark
            onFinish={integratePrivy}
            form={privyContentForm}
            initialValues={ details ?? {
            }}
        >
          <Form.Item
              noStyle
          >
            Account Id
            <Form.Item
                name={"account_id"}
                rules={[{required: true}]}
            >
              <Input />
            </Form.Item>
          </Form.Item>
          <Form.Item
              noStyle
          >
            Account passcode
            <Form.Item
                name={"account_passcode"}
                rules={[{required: true}]}
            >
              <Input />
            </Form.Item>
          </Form.Item>
          <Form.Item
            noStyle
          >
            <span>
              Region (Check your Dashboard URL)
            </span>
            <Form.Item
              name={"region"}
            >
              <Select>
                {REGIONS.map((r, i) => (
                  <Select.Option
                    key={i}
                    value={r.value}
                  >
                    {r.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>
          <Form.Item
              shouldUpdate
          >
            {() => {
              return (
                  <Button
                      disabled={load}
                      loading={load}
                      type={'primary'}
                      htmlType={'submit'}
                  >
                    {isConnected ? "Update" : "Activate"}
                  </Button>
              )
            }}
          </Form.Item>
        </Form>
      </>
  )
}

export default ClevertapContent