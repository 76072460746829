import {FadeLoader} from "react-spinners";
import ReactModal from "react-modal";

type LoadModalProps = {
  load: boolean
}

const LoadModal = (
    {
        load
    }: LoadModalProps
) => {

  return (
    <ReactModal
      isOpen={load}
      shouldFocusAfterRender
      ariaHideApp={false}
      className={"react-main-modal z-[10000000]"}
    >
      <div
        className={"hero is-halfheight m-0 is-transparent"}
      >
        <div className={"hero-body"}>
          <div className={"container text-center"}>
            <div className={"title"}>
              <FadeLoader
                color={"white" ?? "rgb(24, 144, 255)"}
              />
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

export default LoadModal