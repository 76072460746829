export const HelpGuideConstants = {
    CONNECT_STORE_TO_SHOPIFY: 'https://restlessmonks.notion.site/Connect-your-Shopify-or-any-other-store-with-Influencerbit-67599a46176449c2abcca0965398fdaf',
    CAPTURE_INSTAGRAM_USERNAMEs: 'https://help.influencerbit.com/en/articles/5633217-collect-instagram-usernames-via-with-your-pop-up-custom-form-order-checkout-page',
    ALLOW_STORY_TRACKING: 'https://help.influencerbit.com/en/articles/5771823-make-stories-work-with-influencerbit\n',
    HOW_TO_RUN_TEST: 'https://help.influencerbit.com/en/articles/5756679-test-if-you-are-getting-instagram-username-in-influencerbit',
    SYNC_DATA_TO_MARKETING_TOOLS: 'https://help.influencerbit.com/en/articles/5759382-sync-social-data-to-your-marketing-tools',
    CAMPAIGN_GUIDES: {
        EDIT_CAMPAIGN: "",
        CAMPAIGN_BUILDER: ""
    },

    SETUP_VIDEO: "https://www.youtube.com/embed/rm-aPFmjpuo",

    CAMPAIGN_SETUP_VIDEOS: {
        QUIZ: "https://youtu.be/tvHqAa0rhQk",
        FRUIT_NINJA: "https://www.youtube.com/embed/_u3ZKgL238o",
        CATCH_OBJECTS: "https://www.youtube.com/embed/LpKbHtwvqxk",
        FINDNAME: "https://www.youtube.com/embed/3f59xIb4yVY",
        CANDYCRUSH: "https://www.youtube.com/embed/IgjfUddDpws",
        LEADERBOARD_CAMPAIGN: "https://www.youtube.com/embed/4gq1pAHG054",
        DICE: "https://www.youtube.com/embed/uVx7hme9NGM",
        BALLOON_POP: "https://www.youtube.com/embed/GM_J_TvMLlY",
        REWARD_FLOWS: "https://www.youtube.com/embed/oAeKQHKSPwU",
        SPIN_WHEEL: "https://www.youtube.com/embed/RYTOWnapF8Q",
        SLOT_MACHINE: "https://www.youtube.com/embed/aNberdG2DnQ",
        TIC_TAC_TOE: "https://www.youtube.com/embed/VuUsM1ye5GA"
    }

}
