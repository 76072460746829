import {useDispatch, useSelector} from "react-redux";
import videoStyles from './HelpVideoPlayer.module.sass'
import ReactModal from "react-modal";
import {CloseOutlined} from "@ant-design/icons";
import {getHelpVideo, removeHelpVideo} from "../app/features/user/userSlice";

const HelpVideoPlayer = () => {
  const media: string = useSelector(getHelpVideo)
  const dispatch = useDispatch()

  if(!media) return null

  return (
    <ReactModal
      isOpen={!!media}
      contentLabel="Media"
      shouldFocusAfterRender
      className={`${videoStyles.helperVideo} bg-white`}
      onRequestClose={() => dispatch(removeHelpVideo(1))}
    >
      <div
        className={"bg-white react-main-modal-child"}
      >
        <div
          className={"float-right py-3"}
        >
          <CloseOutlined
            className={"cursor-pointer bg-white hover:bg-gray-200 rounded-full p-1"}
            onClick={() => dispatch(removeHelpVideo(1))}
          />
        </div>
        <div
          className={`${videoStyles.playerDivComponent} flex justify-center items-center`}
        >
          <div className={""}>
            <iframe
              width="560"
              height="315"
              src={media}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

export default HelpVideoPlayer