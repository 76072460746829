import IgAccount from "../../../../Common/Models/IgAccount";
import {Avatar, Button, Card} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {useState} from "react";

const {Meta} = Card

type IgAccountCardProps = {
  account: IgAccount,
  remove: Function,
  removable: boolean
}

const IgAccountCard = (
    {
        account,
        remove,
        removable = true
    }: IgAccountCardProps
) => {
  const [l, setL] = useState(false)

  async function removeClick(ib_id: number) {
    setL(true)
    await remove(ib_id)
    setL(false)
  }

  return (
      <div className={"my-5"}>
        <div className={"columns is-vcentered my-5 mx-4"}>
          <div className={"column"}>
            <Avatar
                src={account.profile_pic_url}
                size={90}
                shape={'circle'}
            />
          </div>
          <div className={"column"}>
            <h4 className={"is-size-5 text-black"}>
              {account.username}
            </h4>
          </div>
          {
            removable && (
                <div className={"column"}>
                  <button
                      className={"button is-info uppercase bg-verdigris text-white is-outlined"}
                      onClick={() => removeClick(account.id)}
                  >
                    Remove
                  </button>
                </div>
            )
          }
        </div>
      </div>
  )

  return (
      <div className={"my-5"}>
        <Card
            loading={l}
          actions={[
              <Button
                type={'link'}
                onClick={() => removeClick(account.id)}
              >
                Remove Account
                <DeleteOutlined
                    title={"Remove"}
                    key={"remove"}
                />
              </Button>
          ]}
        >
          <Meta
              avatar={
                <Avatar
                    src={account.profile_pic_url}
                    size={64}
                    shape={'circle'}
                />
              }
              title={account.username}
              description={account.full_name}
          />
        </Card>
      </div>
  )
}

export default IgAccountCard