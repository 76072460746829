import { PostRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import { ApiConstants, ServerURL } from "../ApiConstants";

const SocialRegisterApi = async (
    email: string,
    name: string,
    profile_pic: string,
    social: "GOOGLE",
    shopify?: number | null
) => {
  let apiResponse;
  if (ApiConstants.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(ServerURL("/social_register"), {
      email,
      name,
      profile_pic,
      social,
      shopify
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    data: "ok",
    success: true,
    errors: []
  }
}

export default SocialRegisterApi
