import ContentWrapper from "../../../Components/ContentWrapper";
import igImage from '../../../Common/Images/fb-message-setup.png'
import {Button, Divider, Space} from "antd";
import {HelpGuideConstants} from "../../../Common/Constants/HelpGuideLinks";
import {BookOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const ConnectIgMessenger = () => {
  const {t} = useTranslation()

  return(
      <ContentWrapper>
        <div className={"title is-size-4 is-size-3-fullhd"}>
          {t("quickStart.connect-messenger.title")}
          <Button
              className={"is-pulled-right button"}
              size={'large'}
              onClick={() => {window.open(HelpGuideConstants.ALLOW_STORY_TRACKING, "_blank")}}
          >
            <BookOutlined /> Detailed Guide for Tracking Story Mentions
          </Button>
        </div>
        <Divider />
        <h3 className={"mb-6"}>
          Allow Access to Instagram Messenger so as to track Story Mentions of the users. Open your Connected Brand's Instagram on Mobile and perform the steps below:
        </h3>
        <img
          src={igImage}
          alt={"Connect Ig Messenger. Chat to Use for any issues"}
        />
      </ContentWrapper>
  )
}

export default ConnectIgMessenger