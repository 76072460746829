import {Button, Form, Input, message} from "antd"
import {useEffect, useState} from "react"
import {BadNotif} from "../../../../Common/Utils/SendNotification"
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent"
import WigzoIntegration from "../../../../Common/Models/WigzoIntegration"
import ConnectLimechatApi from "../../../../Common/ApiCall/IbServer/Integrations/ConnectLimechatApi";

type LimechatContentProps = {
  details: WigzoIntegration | null,
} & CommonContentProps

const LimechatContent = (
  {
    close,
    details,
    updateUserFunction
  }: LimechatContentProps
) => {
  const [load, setLoad] = useState(false)
  const [limechatForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
  }, [details])

  const integrateLimechat = async (values: any) => {
    loading()
    const res = await ConnectLimechatApi(values.api_token)
    if (res.success) {
      message.success("Limechat is connected now. You can start collecting data.")
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
  }

  return (
    <>
      <Form
        hideRequiredMark
        onFinish={integrateLimechat}
        form={limechatForm}
        initialValues={details ?? {}}
      >
        <Form.Item
          noStyle
        >
          Api Token
          <Form.Item
            name={"api_token"}
            rules={[{
              required: true
            }]}
          >
            <Input/>
          </Form.Item>
        </Form.Item>
        <Form.Item
          shouldUpdate
        >
          {() => {
            return (
              <Button
                disabled={load}
                loading={load}
                type={'primary'}
                htmlType={'submit'}
              >
                {isConnected ? "Update" : "Activate"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}

export default LimechatContent