import React from "react";
import "../Onboarding.css";
import screen3Img from "../../../Common/Images/onboarding/screen3.png";
function Screen3() {
  return (
    <div className="container is-fluid screen1Container">
      <div className="columns is-multiline  is-mobile">
        <div className="column is-12-mobile is-12-tablet is-5-desktop imageContainer ">
          <img loading="eager" src={screen3Img} alt="screen3img" className="image" />
        </div>
        <div className="column is-12-mobile is-12-tablet is-6-desktop is-offset-1-desktop ">
          <h2 className="is-size-2-desktop is-size-3-tablet  text-white has-text-left screen1Header">
            We Automate the full process
          </h2>
          <p className="text-white is-size-3-desktop has-text-left screen3Description ">
          ✅ Get User’s influence statistics <br /> ✅ Send Collaboration mails <br /> ✅ Track Statistics when they post <br /> ✅ Send them Rewards
          </p>
        </div>
      </div>
      
    </div>
  );
}

export default Screen3;
