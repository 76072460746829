import React from 'react';
import ErrorBoundary from "./Common/Components/ErrorBoundary";
import Home from "./Home";
import './Components/all.sass'
import {Toaster} from "sonner";

function App() {

  const WrapperHome = (props: any) => {
    return (
      //@ts-ignore
        <ErrorBoundary>
          <Toaster />
          <Home
              {...props}
          />
        </ErrorBoundary>
    )
  }

  return (
      <WrapperHome />
  )
}

export default App;
