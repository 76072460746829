import {PostRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import { ApiConstants, ServerURL } from "../ApiConstants";
import authHeader from "../auth-header";

const RemoveUsersCustomTag = async (
  influencer_ids: any[],
  id: string | string[]
) => {
  let apiResponse;
  if (ApiConstants.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(ServerURL("/remove_users_tag"), {
      influencer_ids,
      custom_tag: id
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    data: "OK",
    success: true,
    error: null,
  };
}

export default RemoveUsersCustomTag
