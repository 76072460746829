import {SyncLoader} from "react-spinners";

type LoaderProps = {
  fullScreen?: boolean,
  loaderSize?: 'large' | 'small' | 'default',
  iconColor?: string,
  LoaderIcon?: any
}

const Loader = (
  {
    fullScreen,
    loaderSize = 'default',
    iconColor = "rgb(24, 144, 255)",
  }: LoaderProps
) => {

  return (
    <div className={`hero ${fullScreen ? 'is-fullheight' : ''}`}>
      <div className={'hero-body'}>
        <div className={'container text-center'}>
          <div className={'title'}>
            <SyncLoader
              color={iconColor}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loader