import {useDispatch, useSelector} from "react-redux";
import {clearUser, getUser, showHelpVideo,} from "../app/features/user/userSlice";
import React, {lazy, Suspense, useEffect, useState} from "react";
import type {MenuProps} from 'antd';
import {Avatar, BackTop, Dropdown, Layout} from "antd";
import {Link, Route, Switch, useHistory} from "react-router-dom";
import {useLocation} from "react-router";
import {useTranslation} from "react-i18next";
import {openPopupWidget} from "react-calendly";
import {SegmentAnalytics} from "../Components/SegmentAnalytics";
import {SyncLoader} from "react-spinners";

import Constants from "../Common/Constants/Constants";
import {SECTION_DIMENSIONS} from "../Common/Constants/SectionDimensionConstants";

import RemoveCookie from "../Common/ApiCall/CookieMethods";
import AdduserLocation from "../Common/ApiCall/IbServer/AddUserLocation";
import GetUserLocation from "../Common/ApiCall/Events/GetUserLocation";

import UserLocation from "../Common/Models/UserLocation";

import QuickStart from "./QuickStart";
import Data from "./Data";
import MyAccount from "./MyAccount";
import Onboarding from "./Onboarding";
import SocialAccounts from "./Settings/SocialAccounts";
import UsageStats from "./Settings/UsageStats";
import Plans from "./Settings/Plans";
import FaqComponent from "../Common/Components/Faq/FaqComponent";
import LeftMenu from "./LeftMenu";
import SEO from "../Components/SEO";
import IbVideoPlayer from "./Data/TablesData/MediaTablesData/IbVideoPlayer";
import ConnectStore from "./Settings/ConnectStore";
import HelpVideoPlayer from "../Components/HelpVideoPlayer";
import {HelpGuideConstants} from "../Common/Constants/HelpGuideLinks";

const ShopifyForm = lazy(() => import("./ShopifyForm"));

const RejectedApplications = lazy(() => import("./Community/RejectedApplications"));

const Automation = lazy(() => import("./Community/Automation"));

const ShopifyGraphData = lazy(() => import("./ShopifyForm/ShopifyGraphData"));

const InfluencerConnectForm = lazy(() => import("./InfluencerConnectForm"));

const CreateCampaignPromise = import("./CampaignsComponent/CreateCampaign")
const CreateCampaign = lazy(() => CreateCampaignPromise);

const MarketingReportPromise = import("./Settings/MarketingReport")
const MarketingReport = lazy(() => MarketingReportPromise);

const CustomTaggedUsersPromise = import("./Community/CustomTaggedUsers")
const CustomTaggedUsers = lazy(() => CustomTaggedUsersPromise);

const AnalyticsPromise = import("./Data/Analytics")
const Analytics = lazy(() => AnalyticsPromise);

const UsersPromise = import("./Users");
const Users = lazy(() => UsersPromise);

const UserProfilePromise = import("./Users/UserProfile")
const UserProfile = lazy(() => UserProfilePromise)

const MainDashboardPromise = import("./MainDashboard");
const MainDashboard = lazy(() => MainDashboardPromise);

const DesignHomePromise = import("./DesignHome");
const DesignHome = lazy(() => DesignHomePromise);

const CatalogsPromise = import("./Catalogs");
const Catalogs = lazy(() => CatalogsPromise);

const IntegrationsPromise = import("./Integrations");
const Integrations = lazy(() => IntegrationsPromise)

const CampaignHomeV2Promise = import("./CampaignsComponent/CampaignHomeV2")
const CampaignHomeV2 = lazy(() => CampaignHomeV2Promise)

const DetailedCampaignV2Promise = import("./CampaignsComponent/DetailedCampaign/DetailedCampaignV2");
const DetailedCampaignV2 = lazy(() => DetailedCampaignV2Promise)

const CampaignsComponentPromise = import("./CampaignsComponent")
const CampaignsComponent = lazy(() => CampaignsComponentPromise)

const GiftsPromise = import("./Community/Gifts")
const Gifts = lazy(() => GiftsPromise)

const AffiliatesPromise = import("./Community/Affiliates")
const Affiliates = lazy(() => AffiliatesPromise)

const PendingApplicationsPromise = import("./Applications/PendingApplications")
const PendingApplications = lazy(() => PendingApplicationsPromise)

const DetailedInstaInfluencerPromise = import("./DetailedInstaInfluencer")
const DetailedInstaInfluencer = lazy(() => DetailedInstaInfluencerPromise)

const {Header, Content} = Layout;

export const SIDER_WIDTH = `${SECTION_DIMENSIONS.SIDEBAR}`;
export const COLLAPSED_SIDER_WIDTH = `${SECTION_DIMENSIONS.COLLAPSED_SIDEBAR}`;

const Dashboard = () => {
  const user = useSelector(getUser);
  const [coll, setColl] = useState(false);
  const dispatch = useDispatch();
  const search = new URLSearchParams(window.location.search);
  const history = useHistory();
  const loc = useLocation();
  const {t} = useTranslation();

  const bookDemo = () => openPopupWidget({url: Constants.CALENDLY_LINK})

  const openHelpVideo = () => {
    dispatch(showHelpVideo(HelpGuideConstants.SETUP_VIDEO))
  }

  const toggleCollapseSider = () => setColl(!coll)

  useEffect(() => {
    // getLocation();
    checkReturnToParam();
    SegmentAnalytics.identify(user.user.id);
    // console.log(user.user.name, user.user.email, user.user.id);
    // IntercomWidget.identify(user.user.name, user.user.email, user.user.id);
  }, []);

  useEffect(() => {
    SegmentAnalytics.page(user.user.id, loc.pathname);
    // IntercomWidget.update();
  }, [loc]);

  const getLocation = async () => {
    const response = await GetUserLocation();
    if (response.ip) {
      const loc: UserLocation = response.ip;
      AdduserLocation(loc);
    }
  };

  const logout = () => {
    dispatch(clearUser(3));
    RemoveCookie();
  };

  function checkReturnToParam() {
    const return_to = search.get("return_to");
    if (return_to) {
      history.replace({
        pathname: return_to,
      });
    } else {
      if (loc.pathname === "/") {
        if (user.accounts.instagram.length > 0) {
          history.push("/home");
        } else {
          history.push("/home" + window.location.search);
        }
      }
    }
  }

  const profileMenuItems: MenuProps["items"] = [
    {
      key: "profile",
      label: (
        <Link
          to={"/profile"} className={"is-capitalized"}
        >
          {t("dashboard.profile")}
        </Link>
      )
    },
    {
      key: "logout",
      label: (
        <div
          onClick={() => logout()}
          className={"is-capitalized"}
        >
          {t("dashboard.logout")}
        </div>
      )
    }
  ]

  return (
    <Layout
      className={"min-h-screen"}
    >
      <Suspense
        fallback={
          <div className="grid grid-cols-12 gap-1 place-content-center min-h-screen">
            <div className={"col-start-6 col-span-3"}>
              <SyncLoader/>
            </div>
          </div>
        }
      >
        <SEO
          title={"Home | Engagebud"}
        />
        <DetailedInstaInfluencer/>
        <IbVideoPlayer/>
        <HelpVideoPlayer />
        <LeftMenu active={loc.pathname}/>
        <Layout
          className={"min-h-screen"}
        >
          <Header
            className={"bg-antiflashwhite fixed z-[220] top-0 right-0 shadow-[0px_12px_10px_0px_#4C498124]"}
            style={{
              left: `${coll ? COLLAPSED_SIDER_WIDTH : SIDER_WIDTH}px`,
              backgroundColor: "#F2F3F5",
              height: `${SECTION_DIMENSIONS.TOPBAR}px`
            }}
          >
            <div
              className={"flex justify-end items-center w-full py-3"}
            >
              <div
                className={"flex justify-end items-center"}
              >
                <button
                  onClick={() => openHelpVideo()}
                  className={`uppercase cursor-pointer shadow-xl text-sm md:text-md hover:shadow-none inline-flex leading-6 items-center px-1 md:px-5 py-1 mx-1 md:mx-4 border-darkmain border-2 border-solid rounded-md text-darkmain font-semibold`}
                  title={"Checkout our step by step video"}
                >
                  🙋 5 Minute Video Tutorial
                </button>
                {/*<button*/}
                {/*  onClick={() => bookDemo()}*/}
                {/*  className={`uppercase cursor-pointer shadow-xl hover:shadow-none inline-flex leading-6 items-center text-sm md:text-md px-1 md:px-5 py-1 mx-1 md:mx-4 border-darkmain border-2 border-solid rounded-md text-darkmain font-semibold`}*/}
                {/*  title={"We will be more than happy to get on call and set up Engagebud for your business"}*/}
                {/*>*/}
                {/*  Book Free Implementation Call*/}
                {/*</button>*/}
                <button
                  title="Help Guide"
                  className={`hidden cursor-pointer inline-flex leading-6 shadow-xl hover:shadow-none items-center px-5 py-1 mx-4 uppercase border-black border-2 border-solid rounded-md text-black font-semibold`}
                  onClick={() => window.open("https://help.influencerbit.com", "_target")}
                >
                  help guide
                </button>
                {!(user?.user?.product?.plan_name?.toLowerCase() === "enterprise") && (
                  <button
                    onClick={() => history.replace({pathname: "/plans"})}
                    className={`cursor-pointer inline-flex leading-6  shadow-xl hover:shadow-none items-center px-5 py-1 mx-4 uppercase border-darkmain border-2 border-solid rounded-md text-white bg-darkmain font-semibold`}
                    title={"Upgrade"}
                  >
                    🚀 Upgrade
                  </button>
                )}
                <Dropdown
                  menu={{
                    items: profileMenuItems
                  }}
                  className={"cursor-pointer"}
                >
                  <Avatar
                    className={"is-cursor-pointer"}
                    shape={"circle"}
                    src={
                      user.user.profile_pic_url ?? "https://secureimages.allrecipes.com/userphotos/250x250/8531051.jpg"
                    }
                  />
                </Dropdown>
              </div>
            </div>
          </Header>
          <Content
            className={"bg-cultured pt-[64px] min-h-screen"}
            style={{
              marginLeft: `${coll ? COLLAPSED_SIDER_WIDTH : SIDER_WIDTH}px`,
              minHeight: `100vh`,
            }}
          >
            <BackTop
              className={"z-[10]"}
            />

            <Switch>
              <Route
                path={"/quick-start"}
                render={() => {
                  return <QuickStart/>;
                }}
              />

              <Route path={"/home"} render={() => <MainDashboard/>}/>

              <Route path={"/design"} render={() => <DesignHome/>}/>

              <Route path={"/integrations"} render={() => <Integrations/>}/>

              <Route path={"/profile"} render={() => <MyAccount/>}/>

              <Route path={"/onboarding"} render={() => <Onboarding/>}/>

              <Route
                path={"/social_accounts"}
                render={() => <SocialAccounts/>}
              />

              <Route path={"/usage"} render={() => <UsageStats/>}/>

              {/*<Route path={"/my_plan"} render={() => <MyPlan/>}/>*/}

              <Route path={"/my_plan"} render={() => <Plans />} />
              <Route path={"/plans"} render={() => <Plans />} />

              <Route path={"/report"} render={() => <MarketingReport/>}/>

              <Route
                path={"/faq"}
                render={() => (
                  <div className={"bg-titan min-h-full p-4"}>
                    <FaqComponent
                      siteAddress={"ib-portal"}
                      columns={1}
                      grouped={false}
                    />
                  </div>
                )}
              />

              <Route
                path={"/shopify_form"}
                render={() => (
                  <ShopifyForm
                    campaign={false}
                  />
                )}
              />

              <Route
                path={"/shopify_graphs"}
                render={() => (
                  <ShopifyGraphData/>
                )}
              />

              <Route
                path={"/influencer_form"}
                render={() => (
                  <InfluencerConnectForm/>
                )}
              />

              <Route
                path={"/community/gifts"}
                render={() => <Gifts/>}
              />
              <Route
                path={"/community/settings"}
                render={() => <Affiliates/>}
                //render={() => <Redirect to={"/automation/affiliate"} />}
              />
              <Route
                path={"/community/affiliate"}
                render={() => <Affiliates/>}
              />
              <Route
                path={"/gifts"}
                render={() => <Gifts/>}
              />
              <Route
                path={"/community"}
                render={() => <Affiliates/>}
              />

              <Route
                path={"/automation"}
                render={() => <Automation/>}
              />

              <Route path={"/pending_applications"} render={() => <PendingApplications/>}/>

              <Route path={"/graphs"} render={() => <Data option={"graphs"}/>}/>

              <Route path={"/people"} render={() => <Data option={"people"}/>}/>

              <Route path={"/custom_tagged_users"} render={() => <CustomTaggedUsers/>}/>

              <Route path={"/rejected_applications"} render={() => <RejectedApplications/>}/>

              <Route path={"/inbound"} render={() => <Data option={"inbound"}/>}/>

              <Route
                path={"/campaigns/detail/:id"}
                render={() => <DetailedCampaignV2/>}
              />

              <Route
                path={"/campaigns/home/:id"}
                render={() => <CampaignHomeV2/>}
              />

              <Route
                path={"/campaigns/create"}
                render={() => <CreateCampaign/>}
              />

              <Route
                path={"/campaigns/all"}
                render={() => <CampaignsComponent/>}
              />
              <Route
                path={"/campaigns"}
                render={() => <CampaignsComponent/>}
              />
              <Route
                path={"/campaign"}
                render={() => <CampaignsComponent/>}
              />
              <Route
                path={"/users"}
                render={() => <Users/>}
              />
              <Route
                path={"/user/profile/:id"}
                render={() => <UserProfile/>}
              />
              <Route
                path={"/analytics"}
                render={() => <Analytics/>}
              />

              <Route
                path={"/mentions"}
                render={() => <Data option={"media"}/>}
              />

              <Route
                path={"/catalogs"}
                render={() => <Catalogs/>}
              />

              <Route
                path={"/store"}
                render={() => <ConnectStore />}
              />

              <Route render={() => <MainDashboard/>}/>
            </Switch>
          </Content>
        </Layout>
      </Suspense>
    </Layout>
  );
};

export default Dashboard;
