import {Button, Form, Input, message, Select} from "antd";
import {useEffect, useState} from "react";
import {BadNotif, SuccessNotif} from "../../../../Common/Utils/SendNotification";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";
import WebEngageIntegration from "../../../../Common/Models/WebEngageIntegration";
import ConnectWebengage from "../../../../Common/ApiCall/IbServer/Integrations/ConnectWebengage";

type WebEngageContentProps = {
  details: WebEngageIntegration | null,
} & CommonContentProps

const WebEngageContent = (
  {
    close,
    details,
    updateUserFunction
  }: WebEngageContentProps
) => {
  const [load, setLoad] = useState(false)
  const [moContentForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
  }, [details])

  const integrateWebengage = async (values: any) => {
    loading()
    const res = await ConnectWebengage(values.api_key, values.license_code, values.datacenter_global ? "yes" : "no")
    if(res.success) {
      SuccessNotif("WebEngage is connected now.")
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
  }

  return(
    <>
      <Form
        hideRequiredMark
        onFinish={integrateWebengage}
        form={moContentForm}
        initialValues={{
          api_key: details?.api_key,
          license_code: details?.license_code,
          datacenter_global: details?.datacenter_global ? "global" : "india"
        }}
      >
        <Form.Item
          noStyle
        >
          License Code
          <Form.Item
            name={"license_code"}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          API KEY
          <Form.Item
            name={"api_key"}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Data Center
          <Form.Item
            name={"datacenter_global"}
          >
            <Select>
              <Select.Option
                value={"global"}
              >
                Global (Default)
              </Select.Option>
              <Select.Option
                value={"india"}
              >
                India
              </Select.Option>
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item
          shouldUpdate
        >
          {() => {
            return (
              <Button
                disabled={load}
                loading={load}
                type={'primary'}
                title={"Activate WebEngage"}
                htmlType={'submit'}
              >
                {isConnected ? "Update" : "Activate"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}

export default WebEngageContent