import {Progress} from "antd";
import ConnectPlatform from "./ConnectPlatform";
import QuickStartSteps from "./QuickStartSteps";
import {useEffect, useState} from "react";
import ConnectForm from "./ConnectForm";
import RunTest from "./RunTest";
import ConnectMarketingTools from "./ConnectMarketingTools";
import ConnectFB from "../Settings/SocialAccounts/ConnectFB";
import {useHistory} from "react-router-dom";
import {SuccessNotif} from "../../Common/Utils/SendNotification";
import ConnectIgMessenger from "./ConnectIgMessenger";
import ConnectCustomWidget from "./ConnectCustomWidget";

export type StepItem = {
  title: string
}

const steps: StepItem[] = [
  {
    title: "Connect your Store"
  },
  {
    title: "Connect Brand's Instagram"
  },
  {
    title: "Allow Story Tracking"
  },
  {
    title: "Collect Instagram Usernames"
  },
  {
    title: "Collect IG usernames at checkout"
  },
  {
    title: "Run a Successful Test"
  },
  {
    title: "Sync Insights with your marketing tools"
  }
]

const QuickStart = () => {
  const [curr, setCurr] = useState(0)
  const [load, setLoad] = useState(false)
  const search = new URLSearchParams(window.location.search)
  const history = useHistory()

  const next = () => setCurr(curr + 1)
  const prev = () => setCurr(curr - 1)

  const loading = () => setLoad(true)
  const itLoaded = () => setLoad(false)

  const getStepComponent = () => {
    switch (curr) {
      case 0:
        return <ConnectPlatform/>
      case 1:
        return <ConnectFB help/>
      case 2:
        return <ConnectIgMessenger/>
      case 3:
        return <ConnectForm/>
      case 4:
        return <ConnectCustomWidget/>
      case 5:
        return <RunTest/>
      case 6:
        return <ConnectMarketingTools/>
      default:
        return <div>Step Not Found</div>
    }
  }

  const onFinish = () => {
    SuccessNotif("Engagebud is connected now.")
    history.replace("/home")
  }

  const searchAndJump = (q: string, v: number) => {
    if (search.get(q)) {
      setCurr(v)
    }
  }
  const JumpToConnectShopify = () => searchAndJump('domain', 0)
  const JumpToPlatformConnect = () => searchAndJump('connect_platform', 0)
  const JumpToConnectFacebook = () => searchAndJump('connect_facebook', 1)
  const JumpToConnectForm = () => searchAndJump('connect_form', 2)
  const JumpToRunTest = () => searchAndJump('run_test', 3)
  const JumpToSyncTools = () => searchAndJump('sync_tools', 4)

  useEffect(() => {
    JumpToSyncTools();
    JumpToRunTest();
    JumpToConnectForm();
    JumpToConnectFacebook();
    JumpToPlatformConnect();
    JumpToConnectShopify();
  }, [])

  return (
    <div
      className={"tile is-ancestor"}
    >
      <div className={"tile is-vertical is-3 is-parent"}>
        <QuickStartSteps
          steps={steps}
          curr={curr}
          change={(c: number) => setCurr(c)}
        />
      </div>
      <div
        className={"tile is-vertical m-4 is-parent"}
      >
        <div className={"tile is-child is-11"}>
          <div className={"mt-5"}>
            <Progress
              style={{
                background: "#B8C0FF",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)",
                borderRadius: "2000px"
              }}
              className={"px-3"}
              percent={(curr + 1) * 100 / (steps.length + 1)}
              showInfo={false}
            />
          </div>
        </div>
        <div className={"tile is-child is-11"}>
          <div
            style={{
              minHeight: '60vh'
            }}
          >
            {getStepComponent()}
          </div>
        </div>
        <div className={"tile is-child is-11"}>
          <div className={"is-pulled-right buttons"}>
            {curr > 0 && (
              <button
                className={"button is-large bg-verdigris text-white is-outlined font-bold uppercase"}
                onClick={() => prev()}
              >
                Back
              </button>
            )}
            {
              curr < steps.length - 1 && (
                <button
                  className={"button bg-verdigris text-white font-bold is-large uppercase"}
                  onClick={() => next()}
                >
                  Next
                </button>
              )
            }
            {
              curr === steps.length - 1 && (
                <button
                  className={"button is-outlined is-large bg-verdigris text-white"}
                  onClick={() => onFinish()}
                >
                  Finish
                </button>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuickStart