import { getToken } from "./CookieMethods";

export default function authHeader() {
  const token = getToken()
  if(token) {
    return {Authorization: `Bearer ` + token}
  }
  else {
    return {

    } ;
  }
}
