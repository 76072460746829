import {Button, Divider, Form, Input, message, Select, Typography} from "antd";
import {useEffect, useState} from "react";
import {BadNotif, SuccessNotif} from "../../../../Common/Utils/SendNotification";
import {ApiConstants} from "../../../../Common/ApiCall/ApiConstants";
import WisepopsIntegration from "../../../../Common/Models/WisepopsIntegration";
import ConnectWisepopsApi from "../../../../Common/ApiCall/IbServer/ConnectWisepopsApi";

const {Title, Text} = Typography

export type ContentProps = {
  close: Function,
  details: WisepopsIntegration | null,
  updateUserFunction: Function
}

const WisepopsContent = (
    {
      close,
      details,
      updateUserFunction
    }: ContentProps
) => {
  const [load, setLoad] = useState(false)
  const [wisepopsContentForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
    details && wisepopsContentForm.setFieldsValue({
      instausername_field: details?.instausername_field,
      email_field: details?.email_field
    })
  }, [details])

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  const integrateWisepops = async (values: any) => {
    loading()
    const res = await ConnectWisepopsApi(values.email_field, values.instausername_field, values.firstname_field, values.last_name_field)
    if(res.success) {
      updateUserFunction()
      if (isConnected) {
        message.success("Wisepops Integration Details Updated", 10)
      } else {
        message.success("Wisepops is connected now. You can start collecting data.", 10)
      }
    } else {
      BadNotif(res)
    }
    loaded()
  }

  return(
      <>
        <Form
            hideRequiredMark
            onFinish={integrateWisepops}
            form={wisepopsContentForm}
            initialValues={{
              instausername_field: details?.instausername_field ?? "instausername",
              email_field: details?.email_field ?? "email"
            }}
        >
          {
            isConnected && (
                <Form.Item
                    noStyle
                >
                  Webhook URL
                  <Form.Item
                  >
                    <Text
                        copyable
                    >
                      {`${ApiConstants.PROD_SERVER_URL}/hooks/wisepops/${details?.webhook_unique_id}`}
                    </Text>
                  </Form.Item>
                </Form.Item>
            )
          }
          <Form.Item
              noStyle
          >
            Email Property Name
            <Form.Item
                name={"email_field"}
                rules={[{
                  required: true
                }]}
            >
              <Input
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
              noStyle
          >
            Custom Instagram Username Property
            <Form.Item
                name={"instausername_field"}
                rules={[{
                  required: true
                }]}
            >
              <Input
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
              shouldUpdate
          >
            {() => {
              return (
                  <Button
                      disabled={load}
                      loading={load}
                      type={'primary'}
                      title={isConnected ? "Update Wisepops Integration Details" : "Connect to Wisepops"}
                      htmlType={'submit'}
                  >
                    {isConnected ? "Update" : "Activate"}
                  </Button>
              )
            }}
          </Form.Item>
{/*          {
            isConnected && (
                <Form.Item>
                  <Button
                    danger
                    onClick={() => updateUserFunction()}
                  >
                    Disable
                  </Button>
                </Form.Item>
            )
          }*/}
        </Form>
      </>
  )
}

export default WisepopsContent