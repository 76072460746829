import React, { Key, useEffect, useState } from "react";
import GetMediaData from "../../../../Common/ApiCall/IbServer/GetMediaData";
import {
  BadNotif,
  SuccessNotif,
} from "../../../../Common/Utils/SendNotification";
import ContentWrapper from "../../../../Components/ContentWrapper";
import DataTable from "react-data-table-component";
import { BeatLoader } from "react-spinners";
import {
  Avatar, Button, Pagination, Popover, Space, Typography,
} from "antd";
import InstaMedia from "../../../../Common/Models/InstaMedia";
import moment from "moment";
import { MenuItemDetail } from "../TablesData";
import CustomTag from "../../../../Common/Models/CustomTag";
import {
  CloseOutlined, DownloadOutlined, PlusOutlined, ReloadOutlined,
} from "@ant-design/icons";
import AddFilters from "../AddFilters";
import FilterOptions from "./filterOptions";
import RefreshIgMedias from "../../../../Common/ApiCall/IbServer/RefreshIgMedias";
import { SegmentAnalytics } from "../../../../Components/SegmentAnalytics";
import style from "../css/TablesData.module.sass";
import {DataProps} from "../../Data";
import {setIgUser, showMedia} from "../../../../app/features/user/userSlice";
import {useDispatch} from "react-redux";
import FinalGiftRecord from "../../../../Common/Models/Community/FinalGiftRecord";
import {V2ServerURL} from "../../../../Common/ApiCall/ApiConstants";
import authHeader from "../../../../Common/ApiCall/auth-header";
import $ from "jquery";
import {unionArrays, diffArrays} from "../../../../Common/HelperFunctions/Commons";

const { Title } = Typography;

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_PAGE_NUMBER = 1;
const PAGE_SIZE_OPTIONS = ["10", "25", "50", "100", "200"];

type MediaTableProps = {
  table_menu: MenuItemDetail;
  tags: CustomTag[];
  update: Function;
  rtGap?: boolean;
  page: DataProps["totOptions"]
};

type mediaTableFilter = {
  title: string;
  key: string;
  value: string;
  subFilter: string;
  isClosable: boolean;
  value_2?: string;
};

export const getMediaValue = (row: InstaMedia) => {
   return row.is_story ? "story" : (row.media_type.toLowerCase() === "reel" ? "reel" : "post")
}

export const getTagColor = (txt: string) => {
  switch (txt.toLowerCase()) {
    case "reel": return "is-success";
    case "story": return "is-warning";
    case "post": return "is-info";
    default: return "is-primary";
  }
}

// type mediaTable={
//   key:string;
//   name:string;
//   selector:() => {};
//   grow:number;
//   sortable:boolean;
// }
const MediaTablesData = (
  {
    table_menu, tags, update ,
    rtGap = true,
    page
}: MediaTableProps) => {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState<InstaMedia[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const dispatch = useDispatch()
  const [pagination, setPagination] = useState({
    current: DEFAULT_PAGE_NUMBER,
    page_size: DEFAULT_PAGE_SIZE,
  });
  const [extras, setExtras] = useState<{"gifts": FinalGiftRecord[]}>({gifts: []})
  const [sorter, setSorter] = useState<any>({});
  const [filters, setFilters] = useState<mediaTableFilter[]>([]);

  const loading = () => setLoad(true);
  const loaded = () => setLoad(false);

  const customStyles = {
    rows: {
      style: {
        // override the row height
        background: "#111A22",
        color: "#ffffff",
        padding: "15px 0px",
      },
    },
    headCells: {
      style: {
        background: "#111A22",
        color: "#ffffff",
        borderBottom: "none",
      },
    },
  };

  const onRowSelection = {
    onChange: (selectRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(
        `selectedMediaRowKeys:`,
        selectRowKeys,
        `selected Rows`,
        selectedRows
      );
      let curr_indexes = [];
      for (let i of data) {
        curr_indexes.push(i?.id);
      }
      const other_pages_selected = diffArrays(selectedRowKeys, curr_indexes);
      console.log(
        "Selected Media Rows State: ",
        unionArrays(other_pages_selected, selectRowKeys)
      );
      setSelectedRowKeys(unionArrays(other_pages_selected, selectRowKeys));
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
      username: record.username,
      disabled: record.is_tagged,
    }),
    selectedRowKeys,
  };

  const handleTableChange = (
    paginationObj: any,
    filters: {} | undefined,
    sorterFields: any,
    x: any
  ) => {
    console.log(sorterFields, x)
    if (x?.action === "pagination") {
      setPagination({
        current: paginationObj.page,
        page_size: paginationObj.page_size,
      });
    }
    if (x?.action === "sort") {
      if (sorterFields.order) {
        setSorter({
          [sorterFields.field]: sorterFields.order,
        });
      } else {
        setSorter({});
      }
    }
  };

  const isExpired: (row: InstaMedia) => boolean = (row: InstaMedia) => {
    return (row.is_story && (moment().valueOf() > moment(row.created_at).add(1, 'days').valueOf()))
  }

  const columns = [
    {
      key: "handle",
      name: "Influencer",
      selector: (row: any) => {
        return (
          <div className="is-spaced">
            <Avatar
              shape={"circle"}
              src={`https://influencex-profile-pictures.nyc3.digitaloceanspaces.com/ig_users/${row.influencer_fb_id}.jpg`}
              className={"mr-2"}
            />
            <span
              className={"mx-2"}
              //@ts-ignore
              onClick={() => dispatch(setIgUser(row.insta_influencer_id))}
            >
              {row.owner_username}
            </span>
          </div>
        );
      },
      grow: 7,
      allowOverflow: true,
      width: "200px",
    },
    {
      key: "confirmed_on",
      name: "Gift Sent On",
      selector: (row: InstaMedia) => {
        let el = extras.gifts.filter(a => a.insta_influencer_id === row.insta_influencer_id)
        if(el.length> 0 ) return moment( new Date(el[0].updated_at)).format("Do MMM 'YY HH:mm")
        return <p>{"updated_at"}</p>
      },
      omit: page !== 'gift_media'
    },
    {
      key: "media",
      name: "Media",
      selector: (row: any) => {
        const hide = isExpired(row)
        return (
          <Space>
            <div
/*                href={!row.is_video && row.media_url}*/
              //@ts-ignore
                onClick={() => !hide && dispatch(showMedia({video: row.is_video, url: row.media_url}))}
                className={`${!hide ? "cursor-pointer" : ""}`}
            >
              {
                hide ? (
                  <p>
                    EXPIRED
                  </p>
                ) : (row.is_video ? (
                    <video
                      src={row.media_url}
                      onClick={e => e.preventDefault()}
                    />
                ) : (
                    <Avatar
                      size={110}
                      shape={"square"}
                      src={row.media_url}
                    />
                ))
              }
            </div>
          </Space>
        );
      },
      grow: 5,
      width: "150px",
    },
    {
      key: "media_type",
      name: "Media Type",
      selector: (row: any) => {
        const txt = getMediaValue(row)
        return (
            <span className={`tag uppercase font-semibold ${getTagColor(txt)}`}>
            {txt}
          </span>
        )
      },
      width: "150px",
    },
    {
      key: "reach",
      name: "Estimated Reach",
      selector: (row: any) => row.reach,
      sortable: true,
      grow: 6,
      width: "150px",
    },
    {
      key: "engagement",
      name: "Engg. Rate",
      selector: (row: any) => row.is_post ? `${(row.engagement * 100).toFixed(2)} %` : -1,
      sortable: true,
      grow: 4,
      width: "150px",
    },
    {
      key: "likes_count",
      name: "Likes",
      selector: (row: any) => row.likes_count,
      sortable: true,
    },
    {
      key: "comments_count",
      name: "Comments",
      selector: (row: any) => row.comments_count,
      sortable: true,
      grow: 4,
      width: "150px",
    },
    {
      key: "posted_at",
      name: "Posted on",
      selector: (row: InstaMedia) => {
        return (
            moment(row.media_type.toLowerCase()==="reel" ? row.ig_timestamp : row.created_at)/*.tz("UTC")*/.format("Do MMM 'YY HH:mm")
        )
      },
      grow: 4,
      width: "150px",
    },
    {
      key: "influencer_followers",
      name: "Influencer Followers",
      selector: (row: any) => row.followers_count,
      sortable: true,
      grow: 7,
      width: "200px",
    },
/*    {
      key: "media_type",
      name: "Media Type",
      selector: (row: any) => (
        <Text className="text-white">
          {row.media_type ? row.media_type.toUpperCase() : null}
        </Text>
      ),
      width: "150px",
    },*/
    {
      name: "Source",
      selector: (row: InstaMedia) => row.referrer && <span className="tag is-info uppercase">{row.referrer}</span>,
      grow: 2,
    },
    {
      key: "mentioned_username",
      name: "Mentioned Brand",
      selector: (row: any) => row.mentioned_username,
      grow: 3,
      width: "150px",
      wrap: true,
      omit: true
    },
    {
      key: "created_at",
      name: "Captured on",
      selector: (row: any) => moment(row.created_at)/*.tz("UTC")*/.format("Do MMM 'YY HH:mm"),
      grow: 4,
      width: "150px",
    },
  ];

  const handleRowSelected = React.useCallback((state) => {
    let curr_indexes = [], curr_page_selected = [];
    for (let i of state.selectedRows) {
      curr_indexes.push(i?.id);
    }
    curr_page_selected = unionArrays(state.selectedRows, curr_indexes);
    const other_pages_selected = diffArrays(selectedRowKeys, curr_indexes);
    console.log(
      "Selected Rows State: ",
      unionArrays(other_pages_selected, curr_indexes)
    );
    setSelectedRowKeys(unionArrays(other_pages_selected, curr_indexes));
  }, []);

  const newColumnSort = (selectedColumn: any, sortDirection: string) => {
    console.log("selectedCol", selectedColumn, sortDirection);
    if (sortDirection === "asc") {
      setSorter({
        [selectedColumn.key]: "ascend",
      });
    } else if (sortDirection === "desc") {
      setSorter({
        [selectedColumn.key]: "descend",
      });
    } else {
      setSorter({});
    }
  };
  const getMedias = async (params = {}) => {
    loading();
    const medias_response = await GetMediaData(params);
    if (medias_response.success) {
      setData(medias_response.data.values);
      setExtras(medias_response.data.extras)
      setTotalItems(medias_response.data.count);
    } else {
      BadNotif(medias_response);
    }
    loaded();
  };

  useEffect(() => {
    switch (table_menu.key) {
      case "your_mentions":
        setFilters([]);
        break;
      case "top_engaged":
        setFilters([]);
        break;
      default: ('media'=== page || "custom_tag_media" === page) && (
        setFilters([
            {
              title: `Custom Tag - ${table_menu.key}`,
              key: "custom_tags",
              value: table_menu.key,
              subFilter: "equals",
              isClosable: false,
            },
          ]
        )
      ); break;
    }
    setSelectedRowKeys([]);
  }, [table_menu]);

  const refreshMedias = () => {
    console.log(sorter, pagination, filters, page);
    getMedias({
      ...pagination,
      sort: sorter,
      filters: filters,
      page: page
    });
  };

  useEffect(() => {
    refreshMedias();
  }, [sorter, pagination, filters]);

  const addFilters = (z: mediaTableFilter) => {
    setFilters([...filters, z]);
  };

  const removeFilter = (z: mediaTableFilter) => {
    setFilters(
      filters.filter(function (item) {
        return item !== z;
      })
    );
  };

  const refreshRows = async () => {
    SegmentAnalytics.track("Refresh Media Details");
    if (selectedRowKeys.length === 0) {
      BadNotif(null, "No Media Selected to Refresh");
      return;
    }
    loading();
    const r = await RefreshIgMedias(selectedRowKeys);
    if (r.success) {
      SuccessNotif(r.data);
    } else {
      BadNotif(r);
    }
    refreshMedias();
    loaded();
  };

  function removeEmpty(obj: any) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null || {})
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }
  const downloadData = () => {
    let url = new URL(V2ServerURL("/download/medias"))
    let values: any = {
      page: page,
      sort: sorter,
      filters: filters,
    }
    if(filters.length > 0) values["filters"] = filters
    values["auth"] = authHeader().Authorization
    url.search = new URLSearchParams($.param(removeEmpty(values))).toString()
    window.open(url, "_blank")
  }

  const filtersContent = (
    <AddFilters
      options={FilterOptions}
      addFilters={addFilters}
      removeFilter={removeFilter}
    />
  );
  const disableRows = (row: any) => {
    return row.is_tagged || row.is_story;
  };

  const tableTitle = () => (
    <>
      <div
        className={`w-full space-y-3`}
      >
        <div className={`${style.peoplesDataTitleBox}`}>
          <Title
            level={2}
            className={`capitalized text-[#111A22]`}
          >
            <span className={`${style.peoplesDataTitleSpan}`}>
              {table_menu.title}
            </span>
          </Title>
          <button
            className={`${style.tableTitleButton} ${rtGap ? style.mr8 : ""} is-pulled-right uppercase button text-white`}
            onClick={() => refreshMedias()}
          >
            Check for new media
          </button>
        </div>

        {/*        <Text>
          {table_menu.description}
        </Text>*/}
        <Space
          size={5}
          style={{ minWidth: "100%" }}
          className={`${style.peoplesDataSearchBox} `}
        >
          {/*          <Tooltip
            title={}
          >
            <Button
              icon={<InfoOutlined />}
              shape={'circle'}
            />
          </Tooltip>*/}
          <Popover
            content={filtersContent}
            trigger={"click"}
            destroyTooltipOnHide
          >
            <Button
              icon={<PlusOutlined />}
              size={"large"}
              className={`${style.peoplesDataAddFilterBtn}`}
            >
              Add Filter
            </Button>
          </Popover>
        </Space>
        <div style={{ backgroundColor: "#111A22" }}>
          <div className={"columns p-3 is-multiline is-narrow"}>
            {filters.map((f) => (
              <div className={"column  is-narrow"}>
                <span
                  className={`tag is-medium ${style.filterContainer}`}
                  key={f.title}
                >
                  <Space>
                    {f.title}{" "}
                    {f.isClosable && (
                      <CloseOutlined onClick={() => removeFilter(f)} />
                    )}
                  </Space>
                </span>
              </div>
            ))}
          </div>
          <div
            className={`min-w-full inline bg-[#202F3C]`}
          >
            <Pagination
              className={` p-3 text-white `}
              total={totalItems}
              showSizeChanger
              showTotal={() => `Total ${totalItems} Media`}
              current={pagination.current}
              defaultCurrent={DEFAULT_PAGE_NUMBER}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              hideOnSinglePage={false}
              pageSize={pagination.page_size}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              onChange={(page, pageSize) =>
                handleTableChange(
                  { page: page, page_size: pageSize },
                  {},
                  {},
                  { action: "pagination" }
                )
              }
            />
            <div
              className={"p-4"}
              style={{
                display: "inline",
              }}
            >
              {/*            <Popconfirm
                title={"Are you sure you want to archive selected users?"}
            >
              <Button
                  icon={<DeleteOutlined />}
                  title={"Archive"}
                  type={'link'}
              />
            </Popconfirm>*/}
              <Button
                className={`${style.peoplesDataFuncBtns} ml-2 uppercase mb-2`}
                icon={<ReloadOutlined />}
                type={"link"}
                title={"Refresh"}
                onClick={() => refreshRows()}
              >
                {" "}
                REFRESH Medias
              </Button>
              <Button
                className={`${style.peoplesDataFuncBtns} ml-2 uppercase mb-2`}
                icon={<DownloadOutlined />}
                type={'link'}
                title={"Download CSV"}
                onClick={() => downloadData()}
              >
                Download
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const k_sort = Object.keys(sorter)
  const sorterParams = k_sort.length > 0 ? {
    defaultSortFieldId: k_sort[0],
    defaultSortAsc: sorter[k_sort[0]] !== "ascend"
  } : {}

  return (
    <ContentWrapper>
      {tableTitle()}
      <div className="">
        {!load ? (
          <DataTable<InstaMedia>
            className={`${style.peopleTable}`}
            //@ts-ignore
            columns={columns}
            progressPending={load}
            data={data}
            customStyles={customStyles}
            selectableRows={true}
            responsive
            onSelectedRowsChange={handleRowSelected}
            sortServer={true}
            onSort={(selectedColumn, sortDirection) =>
              newColumnSort(selectedColumn, sortDirection)
            }
            clearSelectedRows={toggleCleared}
            {...sorterParams}
            //@ts-ignore
            selectableRowDisabled={(row) => disableRows(row)}
          />
        ) : (
          <div className="text-center mt-6">
            <BeatLoader />
          </div>
        )}
      </div>
    </ContentWrapper>
  );
};

export default MediaTablesData;
