import {Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {TooltipPlacement} from "antd/es/tooltip";

type ToolTipComponentProps = {
  comp: string | undefined,
  placement?: TooltipPlacement
}

const ToolTipComponent = (
  {
    comp,
    placement = "top"
  } : ToolTipComponentProps
) => {
  if(!comp) return null
  return(
    <Tooltip
      title={comp}
      placement={placement}
      zIndex={100000}
    >
      <InfoCircleOutlined
        className={"cursor-pointer inline-block w-min pl-1"}
      />
    </Tooltip>
  )
}

export default ToolTipComponent