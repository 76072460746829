import {useSelector} from "react-redux";
import {getUser} from "../../../app/features/user/userSlice";
import {useHistory} from "react-router-dom";
import {Progress} from "antd";

const UsageBarMenu = () => {
  const user = useSelector(getUser)
  const history = useHistory()

  const openPlansPage = () => {
    history.replace("/plans")
  }

  function formatNumber(num: number) {
    if(!num) return "1K"
    if (num < 1000) {
      return num.toString(); // return the number as-is if it's less than 1000
    } else if (num < 1000000) {
      return (num / 1000).toFixed(1) + 'K'; // convert to thousands and add 'K'
    } else {
      return (num / 1000000).toFixed(1) + 'M'; // convert to millions and add 'M'
    }
  }

  const getProgressPercent = () => {
    return user?.user?.limits?.impressions_used * 100 / (user?.user?.limits?.impressions_limit || 1000)
  }

  const getProgressStatus = () => {
    let perc = getProgressPercent()
    switch (true) {
      case perc < 50: return "#52c41a"
      case perc < 80: return "yellow"
      default: return "red"
    }
  }

  const infoText = "Total Game Impressions for all campaigns this month."

  return (
    <div
      className={`bg-white p-2 rounded-lg`}
      onClick={() => openPlansPage()}
      title={infoText}
    >
      <span
        className={"text-black font-bold uppercase"}
      >
        Impression LIMIT
      </span>
      <div>
        <div
          className={"flex justify-between"}
        >
          <span>
            {user?.user?.limits?.impressions_used}
          </span>
          <span
            className={"font-bold"}
          >
            {formatNumber(user?.user?.limits?.impressions_limit)}
          </span>
        </div>
        <Progress
          percent={getProgressPercent()}
          size={"small"}
          strokeColor={getProgressStatus()}
          showInfo={false}
        />
      </div>
      <div
        className={"bg-pitch text-white cursor-pointer rounded-md text-center font-bold py-1 px-2"}
        onClick={()=>openPlansPage()}
      >
        Upgrade Plan
      </div>
    </div>
  )
}

export default UsageBarMenu