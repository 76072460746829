const CAMPAIGN_TYPES = {
  MENTION_TYPE: "mention",
  BARTER_TYPE: "barter",
  FRUIT_NINJA_TYPE: "ninja",
  BALLOON_POP_TYPE: "balloon_pop",
  SLOT_MACHINE_TYPE: "rollie",
  QUIZ_TYPE: "quiz",
  CANDY_CRUSH_TYPE: "candy_crush",
  SPIN_WHEEL_TYPE: "wheel",
  SURVEY_TYPE: "survey",
  RACING_TYPE: "racing",
  TICTACTOE_TYPE: "tic_tac_toe",
  DICEQUIZ_TYPE: "dice_quiz",
  SCRATCH_TYPE: "scratch",
  FINDNAME_TYPE: "findname",
  LAUNCH_TYPE: "launch",
  PUZZLE_TYPE: "puzzle",
  POLL_TYPE: "poll",
  SEVEN_UPDOWN_TYPE: "seven_updown",
  OGRE_TYPE: "ogre",

  GAMES_TYPE: "game"
}

export default CAMPAIGN_TYPES