import React from "react";
import "../Onboarding.css";
import screen4Img from "../../../Common/Images/onboarding/screen4.png";
function Screen4() {
  return (
    <div className="container is-fluid screen1Container">
      <div className="columns is-multiline is-vcentered  is-mobile">
      <div className="column is-12-mobile is-12-tablet is-6-desktop is-offset-1-desktop imageContainer is-hidden-tablet">
          <img loading="eager" src={screen4Img} alt="screen4img" className="image" />
        </div>
        <div className="column is-12-mobile is-12-tablet is-5-desktop ">
          <h2 className="is-size-2-desktop  is-size-3-tablet text-white has-text-left screen2Header">
          Seamless integration with Shopify, Klaviyo, Mailchimp, Gorgias, Yotpo and 15+ integrations!!
          </h2>
        </div>
        <div className="column is-12-mobile is-12-tablet is-6-desktop is-offset-1-desktop imageContainer is-hidden-mobile">
          <img loading="eager" src={screen4Img} alt="screen4img" className="image" />
        </div>
      </div>
      
    </div>
  );
}

export default Screen4;
