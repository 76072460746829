import Cookies from "universal-cookie"
import { ApiConstants } from "./ApiConstants"

const COOKIE_PATH = ApiConstants.AUTH_COOKIE_PATH
const COOKIE_DOMAIN = (process.env.NODE_ENV === "development"
    ? ApiConstants.DEV_DOMAIN : ApiConstants.PROD_DOMAIN)
const COOKIE_NAME = ApiConstants.AUTH_COOKIE_NAME

const cookies = new Cookies()

function getToken(COOKIE_TO_FETCH = COOKIE_NAME) {
  return cookies.get(COOKIE_TO_FETCH)
}

function setToken(token: string, COOKIE_NAME_TO_STORE = COOKIE_NAME) {
  cookies.set(COOKIE_NAME_TO_STORE, token, {
    path: COOKIE_PATH,
    domain: COOKIE_DOMAIN
  })
}

export default function RemoveCookie() {
  cookies.remove(COOKIE_NAME, {
    path: COOKIE_PATH,
    domain: COOKIE_DOMAIN
  })
  // window.location.reload()
  return true
}

export {
  getToken, setToken
}