import {Button, Form, Input, message, Select, Typography} from "antd";
import {useEffect, useState} from "react";
import GetKlaviyoLists from "../../../../Common/ApiCall/IbServer/GetKlaviyoLists";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import ConnectKlaviyoApi from "../../../../Common/ApiCall/IbServer/ConnectKlaviyoApi";
import KlaviyoIntegration from "../../../../Common/Models/KlaviyoIntegration";

type KlaviyoListItem = {
  list_id: string,
  list_name: string
}

export type CommonContentProps = {
  close: Function,
  updateUserFunction: Function
}

type KlaviyoContentProps = CommonContentProps & {
  details: KlaviyoIntegration | null
}

const KlaviyoContent = (
  {
    close,
    details,
    updateUserFunction
  }: KlaviyoContentProps
) => {
  const [lists, setLists] = useState<KlaviyoListItem[]>([])
  const [load, setLoad] = useState(false)
  const [klaviyoConnectForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
    details && klaviyoConnectForm.setFieldsValue({
      private_key: details?.private_key,
      public_key: details?.public_key
    })
  }, [details])

  const getKlaviyoListsForUser = (values: any) => {
    if (values.list_id) {
      integrateKlaviyo(values);
      return;
    }
    loading()
    GetKlaviyoLists(values.private_key)
      .then(r => {
        if (r.success) {
          setLists(r.data.lists)
        } else {
          BadNotif(r)
        }
        loaded()
      })
  }

  const integrateKlaviyo = async (values: any) => {
    loading()
    const res = await ConnectKlaviyoApi(values.private_key, values.public_key, values.list_id)
    if (res.success) {
      updateUserFunction()
      message.success(res.data, 10)
    } else {
      BadNotif(res)
    }
    loaded()
    close()
  }

  return (
    <>
      <Form
        hideRequiredMark
        onFinish={getKlaviyoListsForUser}
        form={klaviyoConnectForm}
        initialValues={isConnected ? {
          private_key: details?.private_key,
          public_key: details?.public_key
        } : {}}
      >
        <Form.Item
          noStyle
        >
          Klaviyo Private Key
          <Form.Item
            name={"private_key"}
            rules={[{
              required: true
            }]}
          >
            <Input/>
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Klaviyo Public Key
          <Form.Item
            name={"public_key"}
            rules={[{
              required: true
            }]}
          >
            <Input/>
          </Form.Item>
        </Form.Item>
        {
          lists.length > 0 ? (
            <Form.Item
              noStyle
            >
              <div>
                Klaviyo List
                <Button
                  style={{
                    float: 'right'
                  }}
                  type={'link'}
                  onClick={() => getKlaviyoListsForUser({private_key: klaviyoConnectForm.getFieldValue("private_key")})}
                >
                  Refresh Lists
                </Button>
              </div>
              <Form.Item
                name={"list_id"}
                style={{
                  width: '100%'
                }}
                rules={[{
                  required: true,
                  message: "Select a  Klaviyo List to Continue"
                }]}
              >
                <Select
                  placeholder={"Select a List to Continue"}
                >
                  {lists.map((l) => (
                    <Select.Option
                      value={l.list_id}
                      title={l.list_name}
                      key={l.list_name}
                    >
                      {l.list_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
          ) : null
        }
        <Form.Item
          shouldUpdate
        >
          {() => {
            return (
              <Button
                disabled={load}
                loading={load}
                type={'primary'}
                title={"Refresh your klaviyo lists"}
                htmlType={'submit'}
              >
                {isConnected ? "Update" : "Integrate"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}

export default KlaviyoContent