import {Button, Form, Input, message} from "antd";
import {useEffect, useState} from "react";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import {CommonContentProps} from "../KlaviyoContent/KlaviyoContent";
import ZapierIntegration from "../../../../Common/Models/ZapierIntegration";
import ConnectZapierApi from "../../../../Common/ApiCall/IbServer/ConnectZapierApi";

type ZapierContentProps = {
  details: ZapierIntegration | null,
} & CommonContentProps

const ZapierContent = (
  {
    close,
    details,
    updateUserFunction
  }: ZapierContentProps
) => {
  const [load, setLoad] = useState(false)
  const [zapierContentForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
    zapierContentForm.setFieldsValue({
      url_field: details?.webhook_url
    })
  }, [details])

  const integrateZapier = async (values: any) => {
    loading()
    const res = await ConnectZapierApi(values.url_field)
    if (res.success) {
      message.success("Zapier is connected now.")
      updateUserFunction()
    } else {
      BadNotif(res)
    }
    loaded()
  }

  return (
    <>
      <Form
        hideRequiredMark
        onFinish={integrateZapier}
        form={zapierContentForm}
        initialValues={isConnected ? {
          url_field: details?.webhook_url
        } : {}}
      >
        <Form.Item
          noStyle
        >
          Webhook URL
          <Form.Item
            name={"url_field"}
            rules={[{
              required: true
            }]}
          >
            <Input/>
          </Form.Item>
        </Form.Item>
        <Form.Item
          shouldUpdate
        >
          {() => {
            return (
              <Button
                disabled={load}
                loading={load}
                type={'primary'}
                htmlType={'submit'}
              >
                {isConnected ? "Update" : "Activate"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}

export default ZapierContent