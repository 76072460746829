const CampaignPositionConstants = {
  TITLES: {
    CAMPAIGN_POSITION: 'Campaign Position',
  }
}

export type DestinationItem = {
  title: string,
  type: "new" | "gamezone" | "widget" | "thanks",
  campaigns?: string[],
  info?: string,
  image?: string
}

export default CampaignPositionConstants