import {Alert, Button, Card, Divider, message, Popconfirm, Space, Typography} from "antd";
import {ApiConstants} from "../../../Common/ApiCall/ApiConstants";
import {useEffect, useState} from "react";
import OtherPlatformsModal from "./OtherPlatformsModal";
import ConnectUserShopify from "../../../Common/ApiCall/IbServer/ConnectUserShopify";
import {BadNotif, SuccessNotif} from "../../../Common/Utils/SendNotification";
import {useDispatch, useSelector} from "react-redux";
import {clearUser, getUser, getUserAsync, setUser} from "../../../app/features/user/userSlice";
import {useHistory} from "react-router-dom";
import {useAppDispatch} from "../../../app/store";
import shopifyLogo from '../../../Common/Images/shopify-logo.png'
import {BookOutlined, PlusOutlined} from "@ant-design/icons";
import platformStyles from './Platform.module.sass'
import ShopifyModal from "./ShopifyModal";
import DisconnectShop from "../../../Common/ApiCall/IbServer/Shopify/DisconnectShop";
import {HelpGuideConstants} from "../../../Common/Constants/HelpGuideLinks";
import GetLatestPermissions from "../../../Common/ApiCall/IbServer/Shopify/GetLatestPermissions";

const {Text} = Typography

const CARD_MIN_HEIGHT = 190

type platForm = {
  title: string,
  link?: string
}

const platformsToSelect: platForm[] = [
  {
    title: 'Shopify',
    link: ApiConstants.PROD_SERVER_URL + "/influencerbit_shopify"
  },
  {
    title: 'Others'
  }
]

const ConnectPlatform = () => {
  const [othersModal, setOthersModal] = useState(false)
  const [load, setLoad] = useState(false)
  const user = useSelector(getUser)
  const history = useHistory()
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const [shopifyVis, setShopifyVis] = useState(false)
  const [rem, setRem] = useState(false)
  const [updatePermiss, setUpdatePermis] = useState(false)

  const loading = () => setLoad(true)
  const itLoaded = () => setLoad(false)

  const openModal = () => setOthersModal(true)
  const closeModal = () => setOthersModal(false)

  async function disconnectShop() {
    message.warn("Coming Soon")
  }

  const RemoveShop = async () => {
    setRem(true)
    const res = await DisconnectShop()
    if (res.success) {
      SuccessNotif("Shopify Disconnected.")
    } else {
      BadNotif(res)
    }
    const {payload} = await appDispatch(getUserAsync());
    if (payload.success) {
      dispatch(setUser(payload.data))
    } else {
      dispatch(clearUser(2))
    }
    setRem(false)
  }

  async function updateShopifyPermission() {
    setUpdatePermis(true)
    const r = await GetLatestPermissions()
    setUpdatePermis(false)
    if (r.success) {
      if (r.data) {
        window.open(r.data, "_blank")
      }
    } else {
      BadNotif(r)
    }
  }

  async function checkShopDomainInQuery() {
    const search = new URLSearchParams(window.location.search)
    const shopify_domain = search.get('name')
    if (shopify_domain) {
      loading()
      const connect_shopify_res = await ConnectUserShopify(shopify_domain);
      if (connect_shopify_res.success) {
        SuccessNotif("Shopify Connected")
        history.replace({
          search: ''
        })
        const {payload} = await appDispatch(getUserAsync());
        if (payload.success) {
          dispatch(setUser(payload.data))
        } else {
          dispatch(clearUser(2))
        }
      } else {
        BadNotif(connect_shopify_res)
      }
      itLoaded()
    }
  }

  useEffect(() => {
    checkShopDomainInQuery()
  }, [])

  const openModalOthers = (title: string) => {
    if (title.toUpperCase() === "Others".toUpperCase()) {
      openModal()
    }
  }

  return (
    <div
      className={""}
    >
      <ShopifyModal
        vis={shopifyVis}
        close={() => setShopifyVis(false)}
      />
      <div className={"title"}>
        Connect your Store
        <Button
          className={"is-pulled-right button"}
          size={'large'}
          onClick={() => {
            window.open(HelpGuideConstants.CONNECT_STORE_TO_SHOPIFY, "_blank")
          }}
        >
          <BookOutlined/> How to Connect your Store?
        </Button>
      </div>
      <Divider/>
      <Text>
        <div>
          Are you on Shopify or on any other platform. Don't worry, We work with all platforms! ✌️
        </div>
      </Text>
      <br/>
      <Space align={'start'}>
        {platformsToSelect.map((p) => (
          p.title.toUpperCase() === "shopify".toUpperCase() ? (
            load ? "Connecting Shopify..." : (
              user.shopify?.domain ? (
                <div className={"mx-4 text-center"}>
                  <Card
                    key={p.title}
                    className={`${platformStyles.connected} ${platformStyles.platformCard} mx-3 text-center cursor-pointer`}
                    style={{
                      minWidth: '220px',
                      minHeight: `${CARD_MIN_HEIGHT}px`
                    }}
                    onClick={() => window.open("https://" + user.shopify.domain, "_blank")}
                  >
                    <img
                      style={{maxWidth: '190px'}}
                      src={shopifyLogo}
                      alt={"shopify logo"}
                      className={"mx-4"}
                    />
                    <br/>
                    <span className={"uppercase font-bold"}>
                                {user?.shopify?.name}
                              </span>
                  </Card>
                  <br/>
                  <Popconfirm
                    title={"Are you sure you want to remove Shopify integration?"}
                    onConfirm={() => RemoveShop()}
                  >
                    <button
                      className={`button py-5 is-fullwidth ml-3 is-danger uppercase ${platformStyles.disconnect}`}
                      disabled={rem}
                    >
                      {rem ? "Disconnecting..." : "Disconnect"}
                    </button>
                  </Popconfirm>
                  {user.shopify.remaining.length > 0 && (
                    <Alert
                      className={"mt-5 has-text-left"}
                      message={`NOTICE`}
                      description={"Engagebud has a new update. Update app to use new features."}
                      type={'warning'}
                      action={[
                        <Button
                          type={'primary'}
                          loading={updatePermiss}
                          disabled={updatePermiss}
                          onClick={() => updateShopifyPermission()}
                        >
                          Update
                        </Button>
                      ]}
                    />
                  )}
                </div>
              ) : (
                <Card
                  onClick={() => {
                    // setShopifyVis(true)
                    //window.open(p.link, "_blank")
                    //SuccessNotif("Install Influencerbit from Shopify Store.")
                    /*                                  window.open("https://apps.shopify.com/services/preview/9e6e5b9ec514746d0da275077aaf94e2?locale_code=en", '_self')*/
                    window.open("https://apps.shopify.com/influencerbit", '_self')
                  }}
                  key={p.title}
                  className={`${platformStyles.platformCard} mx-3 text-center cursor-pointer`}
                  style={{
                    minWidth: '220px',
                    minHeight: `${CARD_MIN_HEIGHT}px`
                  }}
                >
                  <img
                    style={{maxWidth: '190px'}}
                    src={shopifyLogo}
                    className={"mx-4"}
                    alt={"Shopify"}
                  />
                </Card>
              )
            )
          ) : (
            <Card
              className={`${platformStyles.platformCard} mx-3 text-center cursor-pointer`}
              style={{
                minWidth: '220px',
                minHeight: `${CARD_MIN_HEIGHT}px`
              }}
              key={p.title}
              onClick={() => openModalOthers(p.title)}
            >
              <h4 className={"is-size-2 my-5 mx-5 font-bold is-italic"}>
                <PlusOutlined/> Others
              </h4>
            </Card>
          )
        ))}
      </Space>
      <OtherPlatformsModal
        visible={othersModal}
        close={closeModal}
      />
    </div>
  )
}

export default ConnectPlatform