import {Button, Form, Input, message, Select} from "antd";
import {useEffect, useState} from "react";
import {BadNotif} from "../../../../Common/Utils/SendNotification";
import SibIntegration from "../../../../Common/Models/SibIntegration";
import ConnectSendInBlue from "../../../../Common/ApiCall/IbServer/Integrations/ConnectSendInBlue";
import GetSibLists from "../../../../Common/ApiCall/IbServer/Integrations/GetSibLists";

type SibListItem = {
  id: string,
  name: string,
  folderId: number,
  uniqueSubscribers: number,
  totalBlacklisted: number,
  totalSubscribers: number
}

export type CommonContentProps = {
  close: Function,
  updateUserFunction: Function
}

type SibContentProps = CommonContentProps & {
  details: SibIntegration | null
}

const SendinblueContent = (
  {
    close,
    details,
    updateUserFunction
  }: SibContentProps
) => {
  const [lists, setLists] = useState<SibListItem[]>([])
  const [load, setLoad] = useState(false)
  const [sibConnectForm] = Form.useForm()
  const [isConnected, setIsConnected] = useState(details?.is_connected)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  useEffect(() => {
    setIsConnected(details?.is_connected ?? false)
    details && sibConnectForm.setFieldsValue({
      api_key: details?.api_key,
      ma_key: details?.ma_key
    })
  }, [details])

  const getSibListsForUser = (values: any) => {
    if (values.list_id) {
      integrateSib(values);
      return;
    }
    loading()
    GetSibLists(values.api_key)
      .then(r => {
        if (r.success) {
          setLists(r.data.lists)
        } else {
          BadNotif(r)
        }
        loaded()
      })
  }

  const integrateSib = async (values: any) => {
    loading()
    const res = await ConnectSendInBlue(values.api_key, values.list_id, values.ma_key)
    if (res.success) {
      updateUserFunction()
      loaded()
      close()
      message.success(res.data, 10)
    } else {
      loaded()
      BadNotif(res)
    }
  }

  return (
    <>
      <Form
        hideRequiredMark
        onFinish={getSibListsForUser}
        form={sibConnectForm}
        initialValues={isConnected ? {
          api_key: details?.api_key,
          ma_key: details?.ma_key
        } : {}}
      >
        <Form.Item
          noStyle
        >
          Sendinblue API KEY
          <Form.Item
            name={"api_key"}
            rules={[{
              required: true
            }]}
          >
            <Input/>
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Sendinblue Tracking KEY
          <Form.Item
            name={"ma_key"}
            rules={[{
              required: true
            }]}
          >
            <Input/>
          </Form.Item>
        </Form.Item>
        {
          lists.length > 0 ? (
            <Form.Item
              noStyle
            >
              <div>
                Sendinblue List
                <Button
                  style={{
                    float: 'right'
                  }}
                  type={'link'}
                  onClick={() => getSibListsForUser({api_key: sibConnectForm.getFieldValue("api_key")})}
                >
                  Refresh Lists
                </Button>
              </div>
              <Form.Item
                name={"list_id"}
                style={{
                  width: '100%'
                }}
                rules={[{
                  required: true,
                  message: "Select a  Sendinblue List to Continue"
                }]}
              >
                <Select
                  placeholder={"Select a List to Continue"}
                >
                  {lists.map((l) => (
                    <Select.Option
                      value={l.id}
                      title={l.name}
                      key={l.name}
                    >
                      {l.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
          ) : null
        }
        <Form.Item
          shouldUpdate
        >
          {() => {
            return (
              <Button
                disabled={load}
                loading={load}
                type={'primary'}
                title={"Refresh your Sendinblue lists"}
                htmlType={'submit'}
              >
                {isConnected ? "Update" : "Integrate"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    </>
  )
}

export default SendinblueContent