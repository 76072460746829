import {FormEvent, useEffect, useState} from "react";
import {BadNotif, SuccessNotif} from "../Common/Utils/SendNotification";
import {message, Typography} from "antd";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useAppDispatch} from "../app/store";
import logo from "../Common/Images/eb.png";
import RegisterApi from "../Common/ApiCall/IbServer/RegisterApi";
import SocialRegisterApi from "../Common/ApiCall/IbServer/SocialRegisterApi";
import SocialLoginApi from "../Common/ApiCall/IbServer/SocialLoginApi";
import {setToken} from "../Common/ApiCall/CookieMethods";
import {clearUser, getUserAsync, setUser} from "../app/features/user/userSlice";
import GoogleLogin from "react-google-login";
import LoginApi from "../Common/ApiCall/IbServer/LoginApi";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";

const APP_NAME = process.env.REACT_APP_APP_NAME

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
if (!GOOGLE_CLIENT_ID) {
  console.log("Google Client Id Not Present.")
}

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [load, setLoad] = useState(false)
  const appDispatch = useAppDispatch()
  const dispatch = useDispatch()
  const history = useHistory()
  const [auth, setAuth] = useState(false)
  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  const responseGoogleLoginSuccess = (res: any) => {
    console.log('[Login Success] currentUser: ', res.profileObj)
    socialRegister(res.profileObj.email, res.profileObj.name, res.profileObj.imageUrl, "GOOGLE")
  }
  const responseGoogleLoginFailure = (v: any) => {
    console.log("Google Login Failed")
    console.log(v)
  }

  const getShopifyParam = () => !!(new URLSearchParams(window.location.search).get("shopify"))

  useEffect(() => {
  }, [])

  const socialRegister = async (email: string, name: string, profile_pic: string, social: "GOOGLE") => {
    loading()
    const res: any = await SocialRegisterApi(email, name, profile_pic, social)
    if (res.success) {
      SuccessNotif("Registered Successfully. Logging you in.")
      const res2 = await SocialLoginApi(email, "GOOGLE")
      if (res2.success) {
        setToken(res2.data.token)
        const {payload} = await appDispatch(getUserAsync());
        loaded()
        if (payload.success) {
          dispatch(setUser(payload.data))
          history.replace({pathname: '/'})
          loaded()
        } else {
          dispatch(clearUser(1))
          loaded()
        }
      } else {
        BadNotif(res2)
        loaded()
      }
    } else {
      BadNotif(res)
      loaded()
    }
  }

  const userRegister = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    loading()
    if (password.length < 8) {
      message.error("Password should be minimum 8 characters");
      loaded();
      return;
    }
    // if(password !== confirm_password) {
    //   message.error("Passwords doesn't match.");return;loaded();
    // }
    const res = await RegisterApi(email, password)
    if (res.success) {
      SuccessNotif("Registration Successful.")
      const res2 = await LoginApi(email, password)
      if (res2.success) {
        setToken(res2.data.token)
        const {payload} = await appDispatch(getUserAsync());
        loaded()
        if (payload.success) {
          dispatch(setUser(payload.data))
          history.replace({pathname: '/'})
          loaded()
        } else {
          dispatch(clearUser(1))
          loaded()
        }
      } else {
        BadNotif(res2)
        loaded()
      }
    } else {
      BadNotif(res)
    }
    loaded()
  }

  return (
    <div className="container is-fluid loginContainer">
      <div className="columns is-vcentered is-multiline is-mobile ">
        <div className="column is-5-desktop is-12-tablet is-12-mobile is-offset-1-desktop">
          <div className="flex justify-center items-center">
            <img
              src={logo}
              alt="influencerbit-logo"
              className="image authImage"
            />
          </div>
        </div>
        <div className="column is-4-desktop is-pulled-right is-12-tablet is-12-mobile  is-offset-1-desktop">
          <div className="authContainer">
            <h3 className="text-black is-size-3 text-left authHeader">
              Sign up
            </h3>
            <div className="formContainer mt-4">
              <form action="" onSubmit={userRegister}>
                <label htmlFor="email" className="text-black authLabel">
                  Email
                </label>
                <input
                  className="input authInput mt-2"
                  type="email"
                  name="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <br/>
                <br/>
                <label htmlFor="password" className="text-black authLabel">
                  Password
                </label>
                <p className="control has-icons-right ">
                  <input
                    className="input authInput mt-2 passInput"
                    type={auth ? "text" : "password"}
                    name="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="is-small is-right iconBox mt-2">
                    <i className={"pr-3"}>
                      {
                        auth ? (
                          <EyeOutlined
                            onClick={() => setAuth(!auth)}
                          />
                        ) : <EyeInvisibleOutlined
                          onClick={() => setAuth(!auth)}
                        />
                      }
                    </i>
                    {/*                    <i className={`fas ${auth?"fa-eye":"fa-eye-slash"} mr-2`} onClick={()=>setAuth(!auth)}></i>*/}
                  </div>
                </p>

                <div className="text-center">
                  <button
                    className="bg-main text-white px-3 py-2 rounded-md hover:bg-white hover:text-main hover:border-main border font-bold is-size-6-desktop mt-4"
                  >
                    {load ? "Wait..." : "CREATE ACCOUNT"}
                  </button>
                </div>
                <div className={"text-center pt-3"}>
                  <label className={"is-size-7"}>
                    By Registering, You agree to the <a href={"https://terms.influencerbit.com/"} target={"_blank"}>Terms
                    of Service</a> and <a href={"https://privacy.influencerbit.com/"} target={"_blank"}>Privacy
                    Policy</a>
                  </label>
                </div>
                {GOOGLE_CLIENT_ID && (
                  <>
                    <p className="mt-4 text-black orText text-center">
                      or
                    </p>
                    <div className="mt-3 text-center">
                      <GoogleLogin
                        clientId={GOOGLE_CLIENT_ID}
                        onSuccess={responseGoogleLoginSuccess}
                        onFailure={responseGoogleLoginFailure}
                        theme={"dark"}
                        fetchBasicProfile
                        cookiePolicy={"single_host_origin"}
                        buttonText={"Continue with Google"}
                      />
                    </div>
                  </>
                )}

                <p className="forgotText text-center mt-4">
                  <a onClick={() => history.replace({pathname: "/login"})} className="forgotText">
                    Already Have An Account? Log in
                  </a>
                </p>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Register