import { GetRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import { ApiConstants, ServerURL } from "../ApiConstants";
import authHeader from "../auth-header";

const GetPlans = async (coupon?: string) => {
  let apiResponse;
  if (ApiConstants.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(ServerURL("/plans"), {coupon}, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {"data":{"plans":[{"plan_name":"Freemium","simple_integrations":true,"ig_business_accounts_limit":1,"tracked_profiles":10,"profile_refresh_limit":50,"stripe_product_id":null,"monthly":{"days":30,"days_description":"1 Month","id":2,"is_monthly":true,"price_in_cents":0.0,"stripe_price_id":null},"yearly":{"days":365,"days_description":"1 Year","id":3,"is_monthly":false,"price_in_cents":0.0,"stripe_price_id":null}},{"plan_name":"Starter","simple_integrations":true,"ig_business_accounts_limit":2,"tracked_profiles":400,"profile_refresh_limit":500,"stripe_product_id":"prod_KHomlVSLYPGpi2","monthly":{"days":30,"days_description":"1 Month","id":4,"is_monthly":true,"price_in_cents":5000.0,"stripe_price_id":"price_1JdF8VSEMH6DgjZiSb6RqMGz"},"yearly":{"days":365,"days_description":"1 Year","id":5,"is_monthly":false,"price_in_cents":50000.0,"stripe_price_id":"price_1JdF8VSEMH6DgjZi965YE6Wi"}},{"plan_name":"Basics","simple_integrations":true,"ig_business_accounts_limit":3,"tracked_profiles":4000,"profile_refresh_limit":5000,"stripe_product_id":"prod_KHonUFr8OIB3Ao","monthly":{"days":30,"days_description":"1 Month","id":6,"is_monthly":true,"price_in_cents":25000.0,"stripe_price_id":"price_1JdF9mSEMH6DgjZiigHGfCfl"},"yearly":{"days":365,"days_description":"1 Year","id":7,"is_monthly":false,"price_in_cents":250000.0,"stripe_price_id":"price_1JdF9mSEMH6DgjZicAvMtVKy"}},{"plan_name":"Business","simple_integrations":true,"ig_business_accounts_limit":5,"tracked_profiles":11000,"profile_refresh_limit":15000,"stripe_product_id":"prod_KHooPZMJbktXgM","monthly":{"days":30,"days_description":"1 Month","id":8,"is_monthly":true,"price_in_cents":80000.0,"stripe_price_id":"price_1JdFAuSEMH6DgjZiZwL6cexs"},"yearly":{"days":365,"days_description":"1 Year","id":9,"is_monthly":false,"price_in_cents":800000.0,"stripe_price_id":"price_1JdFAtSEMH6DgjZiOTjbi8UE"}}]},"success":true,"error":null}
}

export default GetPlans
