import ContentWrapper from "../../Components/ContentWrapper";
import {useEffect, useState} from "react";
import GraphData from "./GraphData";
import TablesData from "./TablesData";
import GetCustomTags from "../../Common/ApiCall/IbServer/GetCustomTags";
import LoadModal from "../../Components/LoadModal";
import GetDataCounts from "../../Common/ApiCall/IbServer/GetDataCounts";
import {BadNotif} from "../../Common/Utils/SendNotification";

export type DataProps = {
  peopleOptions?: "people" | "inbound" | "rejected" | "finalized" | "custom_tag" | "defaulters",
  mediaOptions?: "media" | "affiliate_media" | "gift_media" | "custom_tag_media",
  totOptions?: DataProps["peopleOptions"] | DataProps["mediaOptions"],
  customOptions?: "graphs",
  option: DataProps["totOptions"] | DataProps["customOptions"],
  showCustomTags?: boolean,
  basic?: boolean,
  collap?: boolean,
  hideSider?: boolean
}

const Data = (
  {
    option,
    showCustomTags = false,
    basic = true,
    collap = false,
    hideSider = false
  }: DataProps
) => {
  const [customTags, setCustomTags] = useState([])
  const [updatingTags, setUpdateTags] = useState(false)
  const [updateCounts, setUpdateCounts] = useState(false)
  const [counts, setCounts] = useState<any>({})

  const update = () => {
    getTags()
    getCounts()
  }

  const getTags = async () =>  {
    setUpdateTags(true)
    const r = await GetCustomTags()
    if(r.success) setCustomTags(r.data.tags)
    setUpdateTags(false)
  }

  const getCounts = async () => {
    setUpdateCounts(true)
    const res = await GetDataCounts()
    if(res.success) setCounts(res.data)
    else BadNotif(res)
    setUpdateCounts(false)
  }

  useEffect(() => {
    update()
  }, [])

  return(
      <ContentWrapper
        addToClassName={"px-4 py-5 is-heightfull"}
      >
        <LoadModal
            load={updatingTags}
        />
        {
          option === 'graphs' ? (
            <GraphData
              tags={customTags}
            />
          ) : (
            <TablesData
              tags={customTags}
              update={update}
              updateTags={getTags}
              option={option}
              counts={counts}
              key={option}
              showCustomTags={showCustomTags ?? false}
              basic={basic ?? true}
              collap={collap ?? false}
              hideSider={hideSider ?? false}
            />
          )
        }
      </ContentWrapper>
  )
}

export default Data