const CreateCampaignV2Constants = {
  first_screen: {
    title: "Pick a Campaign",
    messages: {
      SELECT_CAMPAIGN: "Select a Campaign Type to Continue",
      ENTER_CAMPAIGN_NAME: "Enter Campaign Name to Continue",
      ENTER_DESTINATION: "Enter Destination to Continue"
    },
    options: [
      {
        title: "Pick a Campaign",
        key: "campaign_type",
      },
      {
        title: "Campaign Basics"
      },
      {
        title: "Destination"
      }
    ]
  }
}

export default CreateCampaignV2Constants