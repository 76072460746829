import Constants from "../Constants/Constants";

const unionArrays = (a: any[], b: any[]) => a.concat(b.filter((item) => a.indexOf(item) < 0));
const intersectionArrays = (a: any[], b: any[]) => a.filter((x) => b.includes(x));
const diffArrays = (a: any[], b: any[]) => a.filter((x) => !b.includes(x));

function getCurrency(curr: string) {
  switch (curr) {
    case "USD":
      return Constants.currencySymbols.usd;
  }
  return Constants.currencySymbols.inr
}

const roundTo2 = (num: any) => (num * 100 / (100)).toFixed(2)

function rgbToHsl(color: string) {
  if (!color) return;
  color = color.substring(1);
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);
  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;
  if (max == min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    if (h) h /= 6;
  }
  return [h, s, l];
}

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

function removeEmpty(obj: any) {
  return Object.entries(obj)
    .filter(([_, v]) => v != null || {})
    .reduce((acc, [k, v]) => ({...acc, [k]: v}), {});
}

/**
 * Returns image dimensions for specified URL.
 */
const getImageDimensions = (url: string): Promise<{ width: number, height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({
      width: img.width,
      height: img.height,
    });
    img.onerror = (error) => reject(error);
    img.src = url;
  });
};

function titleCase(str?: string) {
  if(!str) return str
  let splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export {
  unionArrays, diffArrays, intersectionArrays, removeEmpty, getImageDimensions,
  classNames, rgbToHsl, roundTo2, getCurrency, titleCase
}
